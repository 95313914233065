import { Component } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { TripService } from './services/trip.service';
import { UsersService } from './services/user.service';
import { VisibleDataService } from './services/visible-data.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  authSubscription: Subscription;
  visibleDataSubscription: Subscription;

  constructor(
    private visibleDataService: VisibleDataService,
    private authService: AuthService,
    private userService: UsersService,
  ) {
    this.authSubscription = this.authService.user$.pipe(map((user) => {
      if (user) {
        const visibleUsers$ = this.userService.getVisibleUsers(user.id);
        
        const visibleData$ = combineLatest([
          visibleUsers$,
        ]).pipe(map(([users]) => {
          this.visibleDataService.visibleUsers$.next(users);
        }));

        this.visibleDataSubscription = visibleData$.subscribe();
      } else {
        // If not logged in, clear visible data
        this.visibleDataService.clearAllData();
      }
    })).subscribe();
  }

  ngOnDestroy() {
    this.authSubscription?.unsubscribe();
    this.visibleDataSubscription?.unsubscribe();
  }
}
