import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-photo-dialog',
  templateUrl: './profile-photo-dialog.component.html',
  styleUrls: [ './profile-photo-dialog.component.scss' ],
})
export class ProfilePhotoDialogComponent {
  constructor(public authService: AuthService, public ref: NbDialogRef<ProfilePhotoDialogComponent>) {}
}
