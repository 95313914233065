import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxiosNestService } from './axios-nest.service';
import * as _ from 'lodash';
import { WallComment, IWallComment } from '../models/wall-comment';

const wallSubCollectionName = 'wall';
@Injectable({
  providedIn: 'root',
})
export class WallService {
  constructor(protected afs: AngularFirestore, private axiosNest: AxiosNestService) {}

  getComments(collectionName: string, collectionId: string): Observable<WallComment[]> {
    return this.afs
      .collection<IWallComment>(`${collectionName}/${collectionId}/${wallSubCollectionName}`)
      .valueChanges()
      .pipe(
        map((comments) => {
          return comments.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
            .map((comment) => new WallComment(comment));
        })
      );
  }

  async addComment(collectionName: string, collectionId: string, comment: {text: string, userId: string}): Promise<boolean> {
    const response = await this.axiosNest
      .post('wall/save', {
        'collectionName': collectionName,
        'collectionId': collectionId,
        'comment': comment,
      });

    if (response.status === 200) {
      return true;
    }

    return false;
  }

  async deleteComment(collectionName: string, collectionId: string, commentId: string): Promise<boolean> {
    const response = await  this.axiosNest
      .post('wall/delete', {
        'collectionName': collectionName,
        'collectionId': collectionId,
        'id': commentId,
      });

    if (response.status === 200) {
      return true;
    }

    return false;
  }


  getLikes(collectionName: string, collectionId: string): Observable<string[]> {
    return this.afs
      .collection(collectionName)
      .doc(collectionId)
      .snapshotChanges()
      .pipe(map((doc) => {
        const data = doc.payload.data() as any;
        const likes = (data['likedUserIds'] ?? []) as string[];
        return likes;
      }));
  }

  async upsertLikes(collectionName: string, collectionId: string, likedUserIds: string[]): Promise<boolean> {
    await this.afs.collection(collectionName)
      .doc(collectionId)
      .update({
        'likedUserIds': likedUserIds
      });

    return true;
  }
}
