import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
  static validateAfterToday(control: AbstractControl): ValidationErrors | null {
    if (control.value < new Date()) {
      return {
        dates: 'Date must be after today',
      };
    }
    return {};
  }
}
