import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-text-rich',
  templateUrl: './text-rich.component.html',
  styleUrls: ['./text-rich.component.scss']
})
export class TextRichComponent implements OnInit {
  @Input() name?: string;
  @Input() value$: BehaviorSubject<string>;
  @Input() placeholder: string = 'Enter text here...';
  @Input() minHeight: string = '150px';
  @Input() disabled = false;
  @Input() showPreview = false;

  htmlContent: string;

  editorConfig: AngularEditorConfig = {
    editable: !this.disabled,
    minHeight: this.minHeight,
    placeholder: this.placeholder,
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'heading',
        'fontName',
      ],
      [
        'customClasses',
        'insertImage',
        'insertVideo',
        'removeFormat',
        'toggleEditorMode',
      ]
    ]
  };

  constructor() { }

  ngOnInit(): void {
    this.htmlContent = this.value$?.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    if (changes.value$) {
      // console.log('text rich value$ changed');

      // Refresh components
      this.ngOnInit();
    }
  }

  onValueChange(event: string): void {
    this.value$?.next(event);
  }

}
