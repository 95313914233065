import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Article } from '../../../models/article';
import { YearbooksService } from '../../../services/yearbook.service';

@Component({
  selector: 'app-article-create-dialog',
  templateUrl: './article-create-dialog.component.html',
  styleUrls: ['./article-create-dialog.component.scss'],
})
export class ArticleCreateDialogComponent {
  form = this.fb.group({
    title: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private yearbooksService: YearbooksService,
    protected ref: NbDialogRef<ArticleCreateDialogComponent>,
  ) {}

  async onSubmit(): Promise<void> {
    try {
      if (this.form.valid) {
        const article = new Article({ ...this.form.value });
        await this.yearbooksService.createArticle(article);
        this.toastrService.success('Your article was successfully created!', 'Created!');
        this.ref.close();
      }
    } catch (e: any) {
      this.toastrService.danger(e.toString(), 'Error');
    }
  }
}
