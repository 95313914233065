import { BaseResource, BaseSerializer } from '../services/base.service';
import { DYAsset, DYAssetSerializer, IDYAsset } from './dy-asset';

export enum DYPageLayoutType {
  cover = 'cover',
  basic = 'basic',
  externalData = 'externalData',
  leftImages = 'leftImages',
  mosaic = 'mosaic',
  profiles = 'profiles',
}

interface IDYPageLayout {
  name: string;
  assets: IDYAsset[];
}

export class DYPageLayout extends BaseResource implements IDYPageLayout {
  name: string;
  assets: DYAsset[];
  id: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(obj: IDYPageLayout) {
    super(obj);
    this.name = obj.name || '';
    this.assets = (obj.assets || []).map((asset) => DYAssetSerializer.fromJson(asset));
  }
}

export class DYPageLayoutSerializer extends BaseSerializer<DYPageLayout> {
  static fromJson(json: any): DYPageLayout {
    return new DYPageLayout(json);
  }

  static toJson(resource: DYPageLayout): any {
    console.log({
      ...resource,
      assets: (resource.assets || []).map((asset) => DYAssetSerializer.toJson(asset)),
    });
    return {
      ...resource,
      assets: (resource.assets || []).map((asset) => DYAssetSerializer.toJson(asset)),
    };
  }
}
