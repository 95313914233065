import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-photo-uploader',
  templateUrl: './profile-photo-uploader.component.html',
  styleUrls: [ './profile-photo-uploader.component.scss' ],
})
export class ProfilePhotoUploaderComponent {
  uploadFolder = 'Users';

  constructor(public authService: AuthService) {}

  getFilename(userId: string): string {
    return `${userId}PreCropped.jpg`;
  }
}
