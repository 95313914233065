import * as moment from 'moment';
import { BaseResource, BaseSerializer } from '../services/base.service';

export enum LinkType {
  telegram = 'telegram',
  whatsapp = 'whatsapp',
  facebook = 'facebook',
  info = 'info',
  resource = 'resource',
  other = 'other',
}

export interface ILink {
  approved: boolean;
  categories: string[];
  subCategories: any;
  deleted: boolean;
  description: string;
  is_info: boolean;
  owner: string;
  tags: string[];
  source?: any;
  title: string;
  type?: LinkType;
  url: string;
  groupChatId?: number;
  botSettings?: any;
  created: string;
  updated: string;
  bot?: string;
  messageLastSent?: Date | firebase.default.firestore.Timestamp;
  memberCountHistoryMap?: any;
  messageCountHistoryMap?: any;
}

export class Link extends BaseResource {
  approved: boolean;
  botSettings: any;
  categories: string[];
  deleted: boolean;
  description: string;
  groupChatId?: number;
  isInfo: boolean;
  owner: string;
  source?: any;
  subCategories: any;
  tags: string[];
  title: string;
  type: LinkType;
  url: string;
  id: string;
  created: string;
  updated: string;
  bot?: string;
  messageLastSent?: Date;
  memberCountHistoryMap?: {
    [date: string]: number;
  };
  messageCountHistoryMap?: {
    [date: string]: number;
  };

  get isBotDisabled() {
    if (this.bot && this.botSettings) {
      return this.botSettings['disabled'] ?? false;
    }

    return undefined;
  }

  get messageLastSentAgo() {
    if (this.messageLastSent) {
      return moment(this.messageLastSent).fromNow();
    }
    return '';
  }

  get lastMemberCount() {
    if (this.memberCountHistoryMap && Object.keys(this.memberCountHistoryMap).length > 0) {
      return this.memberCountHistoryMap[Object.keys(this.memberCountHistoryMap)[Object.keys(this.memberCountHistoryMap).length - 1]];
    }
    return undefined;
  }

  messageCountDaysBefore(days: number) {
    if (this.messageCountHistoryMap) {
      let messageCount = 0;
      Object.keys(this.messageCountHistoryMap).filter((dateString) => {
        return moment(dateString).isAfter(moment().add(days * -1, 'day'));
      }).forEach((dateString) => {
        messageCount += this.messageCountHistoryMap![dateString];
      });

      return messageCount;
    }
    return undefined;
  }

  constructor(obj: ILink) {
    super(obj);
    this.approved = obj.approved || false;
    this.botSettings = obj.botSettings;
    this.categories = obj.categories || [];
    this.deleted = obj.deleted || false;
    this.description = obj.description;
    this.groupChatId = Number(obj.groupChatId) || undefined;
    this.isInfo = obj.is_info || false;
    this.owner = obj.owner;
    this.source = obj.source || {};
    this.subCategories = obj.subCategories || {};
    this.tags = obj.tags || [];
    this.title = obj.title;
    this.type = obj.type || LinkType.info;
    this.url = obj.url;
    this.createdAt = super.timestampToDate(obj.created);
    this.updatedAt = super.timestampToDate(obj.updated);
    this.bot = obj.bot;
    this.messageLastSent = obj.messageLastSent ? super.timestampToDate(obj.messageLastSent) : undefined;
    this.memberCountHistoryMap = obj.memberCountHistoryMap;
    this.messageCountHistoryMap = obj.messageCountHistoryMap;
  }
}

export class LinkSerializer extends BaseSerializer<Link> {
  fromJson(json: any): Link {
    return new Link(json);
  }

  toJson(resource: Link): any {
    return {
      ...resource,
      is_info: resource.isInfo,
    } as ILink;
  }
}

export const regexUrl = new RegExp(
  '^' +
    // protocol identifier
    '(?:(?:https?|http)://)?' +
    // user:pass authentication
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host name
    '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
    // domain name
    '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
    // TLD identifier
    '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
    ')' +
    // port number
    '(?::\\d{2,5})?' +
    // resource path
    '(?:/\\S*)?' +
    '$',
  'i',
);
