import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { IArticleDataImage } from 'src/app/models/article-data';

@Component({
  selector: 'app-mosaic-manage',
  templateUrl: './mosaic-manage.component.html',
  styleUrls: ['./mosaic-manage.component.scss'],
})
export class MosaicManageComponent implements OnInit {
  @Input() uploadFolder: string;
  @Output() onDelete: EventEmitter<IArticleDataImage> = new EventEmitter<IArticleDataImage>();
  @Output() onDrop: EventEmitter<IArticleDataImage[]> = new EventEmitter<IArticleDataImage[]>();
  slots: any[] = [];
  minSlotsLength = 0;
  maxSlotsLength = 10;

  _images: IArticleDataImage[];
  get images(): IArticleDataImage[] {
    return this._images;
  }
  @Input()
  set images(value: IArticleDataImage[]) {
    value.sort((a, b) => (a.position || 0) - (b.position || 0));
    this._images = value;
    this.setupSlots();
  }

  constructor(private toastrService: NbToastrService) {}

  ngOnInit(): void {}

  addSlot(): void {
    this.slots.push([{} as IArticleDataImage]);
  }

  removeSlot(): void {
    if (this.slots[this.slots.length - 1][0].downloadURL) {
      this.toastrService.warning("You can't shrink the mosaic when there is an image on the last position.", 'Warning');
    } else {
      this.slots.pop();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Swap elements
      transferArrayItem(event.previousContainer.data, event.container.data, 0, 1);
      transferArrayItem(event.container.data, event.previousContainer.data, 0, 0);
    }
    this.onDrop.emit(this.slots.reduce((acc: IArticleDataImage[], cur) => [...acc, ...cur], []));
  }

  handleOnDelete($event: IArticleDataImage) {
    this.onDelete.emit($event);
  }

  private setupSlots(): void {
    if (this.images) {
      this.slots = this.images.map((img) => [img]);
    }
  }

  // private hasChanges(arr1: IArticleDataImage[], arr2: IArticleDataImage[]): boolean {}
}
