import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DYExternalData } from 'src/app/models/dy-external-data';
import { Yearbook } from 'src/app/models/yearbook';
import { YearbooksService } from 'src/app/services/yearbook.service';
import { ArticleData } from '../../../models/article-data';

@Component({
  selector: 'app-page-template-external-data',
  templateUrl: './page-template-external-data.component.html',
  styleUrls: ['./page-template-external-data.component.scss'],
})
export class PageTemplateExternalDataComponent implements OnInit {
  @Input() yearbook: Yearbook;
  @Input() content: ArticleData[];
  articleData?: ArticleData;

  externalData$: Observable<DYExternalData[]>;

  constructor(private yearbooksService: YearbooksService) {}

  ngOnInit(): void {
    if (!this.yearbook) {
      throw Error('Yearbook must not be null');
    }
    // this.externalData$ = this.yearbooksService.getExternalData(this.yearbook.id, [ this.article.externalContentTag ]);
  }
}
