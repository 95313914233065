import { Component, Input, OnInit } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent implements OnInit {
  @Input() name?: string;
  @Input() value$: BehaviorSubject<string>;
  @Input() placeholder: string;
  @Input() fieldSize: NbComponentSize = 'medium';
  @Input() icon?: string;
  @Input() required?: boolean;
  @Input() pattern?: string;
  @Input() disabled = false;

  constructor() {
    if (!this.value$) {
      this.value$ = new BehaviorSubject('');
    }
  }

  ngOnInit(): void {
    this.placeholder = this.placeholder ?? '';
  }

  onValueChange(event: any): void {
    this.value$.next(event.value);
  }

  clearText(): void {
    this.value$.next('');
  }

}
