<nb-card class="linkCard">

  <nb-card-header>
    <div class="avatar-wrapper">
      <nb-user [picture]="availableIcons.includes(type) ? 'assets/' + type + '.png' : 'assets/defaultLink.png'" onlyPicture="true"></nb-user>
      <div id="titleLink">{{ link.title }} - Editing</div>
    </div>
    <button nbTooltip="Close Dialog" [nbTooltipTrigger]="trigger" (click)="close()" ghost status="danger" nbButton>
      <nb-icon icon="close-circle-outline"></nb-icon>
    </button>
  </nb-card-header>

  <nb-card-body class="editBody">
    <form style="max-width: 450px" class="form" [formGroup]="form">
      <input style="max-width: 450px" [fieldSize]="inputSize" formControlName="title" type="text" placeholder="Title" nbInput />
      <input style="max-width: 450px" [fieldSize]="inputSize" formControlName="description" type="text" placeholder="Description" nbInput />
      <nb-select style="max-width: 450px" [size]="inputSize" formControlName="category" (selectedChange)="onCategoryChange($event)" *ngIf="availableCategories" placeholder="Category">
        <nb-option *ngFor="let cat of availableCategories" [value]="cat.id">{{cat.name}}</nb-option>
      </nb-select>

      <nb-select style="max-width: 450px" formControlName="subCategory" *ngIf="availableSubCats" placeholder="Sub Category" [selected]="this.form.value.subCategory">
        <nb-option value="">None</nb-option>
        <nb-option *ngFor="let subCat of availableSubCats" [value]="subCat">{{subCat}}</nb-option>
      </nb-select>

      <nb-select style="max-width: 450px" formControlName="type" *ngIf="availableTypes" placeholder="Type">
        <nb-option *ngFor="let type of availableTypes" [value]="type">{{type}}</nb-option>
      </nb-select>

      <nb-tag-list (tagRemove)="onTagRemove($event)">
        <nb-tag *ngFor="let tag of tags" [text]="tag" removable></nb-tag>
        <input #tagInput nbTagInput type="text" (tagAdd)="onTagAdd($event)" (input)="onTagType()" [separatorKeys]=[13,32] placeholder="Hashtags" [nbAutocomplete]="autoTag">
        <nb-autocomplete #autoTag (selectedChange)="onSelectionChange($event)">
          <nb-option *ngFor="let tag of filteredOptions$ | async" [value]="tag">{{ tag }}</nb-option>
        </nb-autocomplete>
      </nb-tag-list>

      <input style="max-width: 450px" [fieldSize]="inputSize" formControlName="url" type="text" placeholder="Url" nbInput />
      <button [disabled]="!form.valid || isLoading || !changes" [nbSpinner]="isLoading" type="button" (click)="onLinkSubmit(form)" nbButton [size]="tagButtonSize" status="danger">
        Save
      </button>
    </form>
  </nb-card-body>
</nb-card>