<div *ngIf="uploadFolder">
	<div class="header">
		<div>Manage Photos</div>
		<nb-button-group class="button-group" status="primary">
			<button nbButton [disabled]="slots.length === minSlotsLength" (click)="removeSlot()">-</button>
			<button nbButton [disabled]="slots.length === maxSlotsLength" (click)="addSlot()">+</button>
		</nb-button-group>
	</div>

	<div cdkDropListGroup class="mosaic-wrapper">
		<div [class]="'layout-'+(slots.length)">
			<div *ngFor="let slot of slots; let i = index;" class="mosaic-list" [class]="'item'+(i+1)" cdkDropList [cdkDropListData]="slot" (cdkDropListDropped)="drop($event)">
				<app-mosaic-item class="mosaic-item" *ngFor="let item of slot" cdkDrag [image]="item" [uploadFolder]="uploadFolder" (onDelete)="handleOnDelete($event)"></app-mosaic-item>
			</div>
		</div>
	</div>
</div>