import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-form-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  textName = 'Text Name';
  textValue$ = new BehaviorSubject<string>('initial');
  textPlaceholder = 'text placeholder';
  
  textAreaRows = '6';
  textAreaName = 'Text Area Name';
  textAreaValue$ = new BehaviorSubject<string>('');
  textAreaPlaceholder = 'text area placeholder';

  dateName = 'Date Name';
  dateValue$ = new BehaviorSubject<Date|null>(new Date());
  datePlaceholder = 'date placeholder';

  dateNoYearName = 'Date No Year Name';
  dateNoYearValue$ = new BehaviorSubject<string|null>('2012');

  imageUploadName = 'Image Upload Name';
  imageInitialValue = 'https://picsum.photos/200/300.jpg'
  imageUploadValue$ = new BehaviorSubject<string|null>(null);

  selectName = 'Select Name';
  selectOptions = [
    {
      key: 'option one',
      value: '1'
    },
    {
      key: 'option two',
      value: '2'
    },
    {
      key: 'option three',
      value: '3'
    },
  ];
  selectValue$ = new BehaviorSubject<string>('3');
  selectPlaceholder = 'Choose';

  tagName = 'Tag Name';
  tagValue$ = new BehaviorSubject<string[]>(['hello']);

  toggleLeftLabel = 'Off Label';
  toggleRightLabel = 'On Label';
  toggleValue$ = new BehaviorSubject<boolean>(true);

  textRichName = 'Text Rich Name';
  textRichValue$ = new BehaviorSubject<string>('initial');
  textRichPlaceholder = 'text placeholder';

  constructor() {
    combineLatest([
      this.textValue$,
      this.textAreaValue$,
      this.dateValue$,
      this.dateNoYearValue$,
      this.imageUploadValue$,
      this.selectValue$,
      this.tagValue$,
      this.toggleValue$,
      this.textRichValue$
    ]).pipe(
      switchMap(async ([textValue, textAreaValue, dateValue, dateNoYearValue, imageUploadValue, selectValue, tagValue, toggleValue, textRichValue]) => {
        console.log({
          textValue,
          textAreaValue,
          dateValue,
          dateNoYearValue,
          imageUploadValue,
          selectValue,
          tagValue,
          toggleValue,
          textRichValue
        });
      })
    ).subscribe();

  }

  ngOnInit(): void {
  }

}
