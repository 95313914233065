import {
  NbTagComponent,
  NbToastrService,
  NbComponentSize,
  NbDialogRef,
  NbTrigger,
  NbTagInputAddEvent,
} from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { map, pairwise, startWith } from 'rxjs/operators';
import { Link, regexUrl } from 'src/app/models/link';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { LinkStoreService } from 'src/app/services/link-store.service';

@Component({
  selector: 'app-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrls: [ './edit-link.component.scss' ],
})
export class EditLinkComponent implements OnInit, OnDestroy {
  @Input() link: Link;
  @Input() availableSubCats: any[] = [];
  @Input() availableIcons: string[] = [];
  @Input() tagButtonSize: NbComponentSize;
  @Input() trigger: NbTrigger;

  type: string = '';
  availableTypes: string[] = [];
  availableCategories: any[] = [];
  availableHashtags: string[] = [];
  form: FormGroup;
  initialForm: any;
  isLoading = false;
  tags: string[] = [];
  inputSize: NbComponentSize;
  selectedCategory: any[];
  changes = false;
  @ViewChild('tagInput') tagInput: ElementRef;

  hashtagAutocomplete$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  filteredOptions$: Observable<string[]>;

  sub: Subscription;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private toastrService: NbToastrService,
    private linkStoreService: LinkStoreService,
    protected dialogRef: NbDialogRef<any>,
  ) {
    this.form = new FormGroup({
      title: new FormControl('', [ Validators.required ]),
      description: new FormControl(''),
      type: new FormControl('', [ Validators.required ]),
      url: new FormControl('', [ Validators.required, Validators.pattern(regexUrl) ]),
      category: new FormControl('', [ Validators.required ]),
      subCategory: new FormControl(''),
    });
    this.inputSize = window.screen.width > 600 ? 'medium' : 'small';
  }

  ngOnInit(): void {
    if (!this.link) {
      throw new Error('link must not be null');
    }

    this.type = this.link.type;
    this.availableHashtags = [ ...this.linkStoreService.hashtags ];
    this.availableCategories = [ ...this.linkStoreService.categories$.value ];
    this.availableTypes = [ ...this.linkStoreService.availableTypes ];
    this.tags = [ ...this.link.tags ];

    // Init autocomplete
    this.getAutocomplete();
    this.filteredOptions$ = of(this.hashtagAutocomplete$.value);

    this.form.patchValue({
      title: this.link.title,
      description: this.link.description,
      type: this.link.type,
      url: this.link.url,
      category: this.link.categories[0],
      subCategory: Object.values(this.link.subCategories)[0],
      hashtags: this.tags,
    });

    this.initialForm = { ...this.form.value };
    this.initialForm.tags = [ ...this.link.tags ];

    this.sub = this.form.valueChanges.pipe(startWith(null), pairwise()).subscribe(([ prev, next ]: [any, any]) => {
      for (let key in next) {
        if (this.initialForm[key] !== next[key]) {
          this.changes = true;
          break;
        } else {
          this.changes = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onTagRemove(tagToRemove: NbTagComponent): void {
    this.tags = this.tags.filter((tag) => {
      return tag !== tagToRemove.text;
    });
    this.form.value.tags = this.tags;
    this.changes = this.tags.toString() !== this.initialForm['tags'].toString() ? true : false;
    this.getAutocomplete();
  }

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    // Lowercase, trim and remove leading "#" characters
    const inputValue = value.toLowerCase().trim().replace(/^#+/, '');

    if (inputValue) {
      if (inputValue.includes(' ')) {
        this.toastrService.warning("Tag can't have spaces", 'Invalid tag');
        return;
      }

      if (this.tags.includes(inputValue)) {
        this.toastrService.warning('Tag already exists', 'Duplicated tag');
        return;
      }

      this.tags.push(inputValue);
      this.form.value.tags = this.tags;
      input.nativeElement.value = '';
      this.hashtagAutocomplete$.next(
        this.availableHashtags.filter((tag) => tag.toLowerCase().indexOf(inputValue) === 0 && !this.tags.includes(tag)),
      );
      this.changes = this.tags.toString() !== this.initialForm['tags'].toString() ? true : false;
    }
  }

  onCategoryChange(event: string) {
    this.selectedCategory = this.availableCategories.filter((cat) => cat.id === event);
    this.form.value.subCategory = '';
    this.availableSubCats = this.selectedCategory[0].subCategories || [];
  }

  onSelectionChange(value: string) {
    this.tags.push(value);
    this.form.value.tags = this.tags;
    this.tagInput.nativeElement.value = '';
    this.changes = this.tags.toString() !== this.initialForm['tags'].toString() ? true : false;
    this.getAutocomplete();
  }

  async onLinkSubmit(form: FormGroup): Promise<void> {
    this.isLoading = true;
    form.value.id = this.link.id;
    const newSubCat = form.value.subCategory;
    form.value.subCategory = { [this.link.categories[0]]: newSubCat };
    form.value.subCategories = form.value.subCategory;
    delete form.value.subCategory;
    if (!form.value.tags) {
      form.value.tags = this.link.tags;
    }
    try {
      await this.linkStoreService.upsertLink(form.value).toPromise();
      this.toastrService.success('Data sent!', 'Success');
      let linkCopy: any = { ...this.link };
      for (let key in form.value) {
        linkCopy[key] = form.value[key];
      }
      this.link = linkCopy;
    } catch (err) {
      this.toastrService.danger(err.message, 'Error');
    } finally {
      this.isLoading = false;
      this.changeDetector.detectChanges();
      this.dialogRef.close(this.link);
    }
  }

  close(): void {
    if (this.changes && confirm('Are you sure? You will not keep your changes.') === true) {
      this.dialogRef.close();
    } else if (!this.changes) {
      this.dialogRef.close();
    } else {
      return;
    }
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.hashtagAutocomplete$.value.filter((optionValue) => optionValue.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  getAutocomplete() {
    this.hashtagAutocomplete$.next(this.availableHashtags.filter((tag) => !this.tags.includes(tag)));
  }

  onTagType() {
    this.filteredOptions$ = this.getFilteredOptions(this.tagInput.nativeElement.value);
  }
}
