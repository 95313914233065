<nb-card>
    <nb-card-body [nbSpinner]="isDeletingComment">
        <div class="flex-row">
            <div>
                <app-user-avatar [userId]="comment.userId"></app-user-avatar>
            </div>
            <div class="flex-one padding-left-8">
                <div>
                    <b><app-user-name [userId]="comment.userId"></app-user-name></b>
                </div>
                <div>
                    {{comment.text}}
                </div>
            </div>
            <div class="padding-left-8">
                {{comment.createdAtFromNow()}}
            </div>
            <div *ngIf="isMyComment && !isDeletingComment" class="padding-left-8">
                <nb-icon class="cursor-pointer" icon="trash-2-outline" (click)="onDeleteConfirmationButtonClicked(deleteConfirmationDialog)"></nb-icon>
            </div>
        </div>
    </nb-card-body>
</nb-card>

<ng-template #deleteConfirmationDialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Delete comment?
        </nb-card-header>
        <nb-card-body>
            <div class="flex-row">
                <div>
                    <app-user-avatar [userId]="comment.userId"></app-user-avatar>
                </div>
                <div class="flex-one padding-left-8">
                    <div>
                        <b><app-user-name [userId]="comment.userId"></app-user-name></b>
                    </div>
                    <div>
                        {{comment.text}}
                    </div>
                </div>
                <div class="padding-left-8">
                    {{comment.createdAtFromNow()}}
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton status="primary" (click)="onDeleteButtonClicked()">Delete</button>
            <button class="margin-left-8" nbButton (click)="ref.close()">Close Dialog</button>
        </nb-card-footer>
    </nb-card>
</ng-template>