import { Component, Input, OnInit } from '@angular/core';
import { NbComponentSize, NbTrigger } from '@nebular/theme';
import { ExportToCsv } from 'export-to-csv';
import { LinkStoreService } from 'src/app/services/link-store.service';

@Component({
  selector: 'app-links-download-csv-button',
  templateUrl: './links-download-csv-button.component.html',
  styleUrls: [ './links-download-csv-button.component.scss' ],
})
export class LinksDownloadCsvButtonComponent implements OnInit {
  @Input() size: NbComponentSize;
  @Input() trigger: NbTrigger;
  constructor(private linkStoreService: LinkStoreService) {}

  ngOnInit(): void {}

  downloadCsv() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'Links',
      filename: 'links',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    let dataToDownload: any[] = [];

    let data: any[] = [];
    this.linkStoreService.links.forEach((obj: any) => {
      const link = { ...obj };
      // Reorder object to see TITLE first
      const description = link.description;
      const subCategories = link.subCategories;
      const tags = link.tags;
      delete link.description;
      delete link.subCategories;
      delete link.tags;
      link.description = description;
      link.subCategories = subCategories;
      link.tags = tags.toString().replace(',', ', ');

      delete link.botSettings;
      delete link.approved;
      delete link.deleted;
      delete link.groupChatId;
      delete link.id;
      delete link.createdAt;
      delete link.updatedAt;
      delete link.isInfo;
      delete link.source;
      delete link.owner;
      this.linkStoreService.categories.map((cat) => {
        if (cat.id === link.categories[0]) {
          link.category = cat.name;
        }
      });
      delete link.categories;
      link.subCategories = Object.values(link.subCategories).toString();
      link.tags = link.tags.toString();
      data.push(link);
    });

    dataToDownload = data;

    csvExporter.generateCsv(dataToDownload);
  }
}
