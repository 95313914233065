import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() rightLabel?: string;
  @Input() leftLabel?: string;
  @Input() value$: BehaviorSubject<boolean>;
  @Input() disabled = false;

  initialValue = false;

  constructor() {
  }

  ngOnInit(): void {
    if (this.value$)
      this.initialValue = this.value$.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value$) {
      const value$ = changes.value$.currentValue;
      if (value$) {
        this.initialValue = value$.value;
      }
    }
  }

  onValueChange(event: boolean): void {
    this.value$.next(event);
  }

}
