import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleData } from 'src/app/models/article-data';
import { Yearbook } from 'src/app/models/yearbook';
import { YearbooksService } from 'src/app/services/yearbook.service';

@Component({
  selector: 'app-page-template-mosaics',
  templateUrl: './page-template-mosaics.component.html',
  styleUrls: ['./page-template-mosaics.component.scss'],
})
export class PageTemplateMosaicsComponent implements OnInit {
  @Input() yearbook: Yearbook;
  @Input() articleData: ArticleData;
  images$: Observable<ArticleData['images']>;
  imageChunks$: Observable<ArticleData['images'][]>;

  constructor(private yearbooksService: YearbooksService) {}

  ngOnInit(): void {
    if (!this.yearbook) {
      throw Error('Yearbook must not be null');
    }
    if (!this.articleData) {
      throw Error('ArticleData must not be null');
    }

    // if (this.article.externalContentTag) {
    //   this.images$ = this.yearbooksService.getExternalData(this.yearbook.id, [ this.article.externalContentTag ]).pipe(
    //     map((assets) =>
    //       assets
    //         .map((asset, i) => {
    //           return {
    //             position: i,
    //             downloadURL: asset.photoUrl || '',
    //             path: '',
    //             filename: '',
    //           };
    //         })
    //         .filter((articleDataImage) => !!articleDataImage.downloadURL),
    //     ),
    //   );
    // } else {
    //   this.images$ = of(this.article.images);
    // }

    this.imageChunks$ = this.images$.pipe(
      map((imageObjects) => {
        const chunks = [];

        // Split into smaller chunks if size > 10
        if (imageObjects.length <= 10) {
          return [[...imageObjects]];
        } else {
          while (imageObjects.length) {
            chunks.push(imageObjects.splice(0, Math.floor(Math.random() * 10) + 1));
          }
          return chunks;
        }
      }),
    );
  }
}
