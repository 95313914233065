import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apps-router-page',
  templateUrl: './apps-router-page.component.html',
  styleUrls: ['./apps-router-page.component.scss'],
})
export class AppsRouterPageComponent implements OnInit {
  userEmail: string;
  superUser: boolean;
  constructor(private auth: AuthService) {
    this.userEmail = this.auth.user?.email as string;
    this.superUser = this.auth.user?.superUser as boolean ;
  }
  ngOnInit(): void {}
}
