import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { WallComment } from 'src/app/models/wall-comment';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { WallService } from 'src/app/services/wall.service';

@Component({
  selector: 'app-comment-wall',
  templateUrl: './comment-wall.component.html',
  styleUrls: ['./comment-wall.component.scss'],
})
export class CommentWallComponent implements OnInit {
  @Input() collectionName: string;
  @Input() collectionId: string;
  @Input() inputDisabled: boolean = false;
  @Input() inputDisabledMessage?: string;

  authUser: User | null;
  comments$: Observable<WallComment[]>;
  
  newCommentText$: BehaviorSubject<string> = new BehaviorSubject('');
  isAddingNewComment = false;

  noFirstName: boolean;
  noFirstNameWarning: boolean;
  newFirstName$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private wallService: WallService,
    private auth: AuthService,
    private profileService: ProfileService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this.authUser = await this.auth.user$.pipe(take(1)).toPromise();
    this.auth.user$.subscribe((user) => {
      this.authUser = user;
      this.noFirstName = !user?.firstName || user.firstName == '';
    })

    this.comments$ = this.wallService.getComments(this.collectionName, this.collectionId);
  }

  onNewCommentTextChange(event: any): void {
    this.newCommentText$.next(event.value);
  }

  onNewFirstNameChanged(event: any): void {
    this.newFirstName$.next(event.value);
    if (event.value != '') {
      this.noFirstNameWarning = false;
    }
  }

  async onNewCommentSubmit(): Promise<void> {
    if (this.noFirstName && this.newFirstName$.value == '') {
      this.noFirstNameWarning = true;
      return;
    } else {
      this.noFirstNameWarning = false;
    }

    if (this.authUser && this.newCommentText$.value != '' && !this.isAddingNewComment) {
      this.isAddingNewComment = true;
      
      const newFirstName = this.newFirstName$.value;
      if (newFirstName != '') {
        // Update firstName
        const profileData = {
          'firstName': newFirstName
        };
        await this.profileService.update(profileData);

        this.noFirstName = false;
        this.noFirstNameWarning = false;
        this.newFirstName$.next('');
      }

      const commentText = this.newCommentText$.value;
      this.newCommentText$.next('');

      const comment = {
        'text': commentText,
        'userId': this.authUser.id,
      }
      await this.wallService.addComment(this.collectionName, this.collectionId, comment);

      this.isAddingNewComment = false;
    }
  }

  async goToLoginPage() {
    const previousUrl = window.location.pathname + window.location.search;

    // Remove empty params
    const obj: any = {
      previousUrl,
    };
    const params = Object.keys(obj)
      .filter((k) => obj[k] != null)
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});

    // Route back to login
    await this.router.navigate(['/auth/login'], { queryParams: params, queryParamsHandling: 'merge' });
  }
}
