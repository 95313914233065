import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Link } from 'src/app/models/link';

@Component({
  selector: 'app-links-hashtag-filter',
  templateUrl: './links-hashtag-filter.component.html',
  styleUrls: [ './links-hashtag-filter.component.scss' ],
})
export class LinksHashtagFilterComponent implements OnInit {
  @Input() links$: Observable<Link[]>;
  @Input() maximumLinkCount$: Observable<number>;
  @Input() size: NbComponentSize;
  @Input() hashtagFiltering: string[] = [];

  @Output() onTagChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  hashtags$: Observable<string[]>;
  hashtagsWithCount$: Observable<{ tag: string; count: number }[]>;

  constructor() {}

  ngOnInit(): void {
    console.log('LinksHashtagFilterComponent init');
    if (!this.links$) {
      throw Error('links$ must not be null');
    }

    if (!this.maximumLinkCount$) {
      throw Error('maximumLinkCount$ must not be null');
    }

    this.hashtagsWithCount$ = combineLatest([ this.links$, this.maximumLinkCount$ ]).pipe(
      map(([ links, maximumLinkCount ]) => {
        let tags: string[] = [];

        links.forEach((link) => {
          link.tags.map((tag) => {
            if (!tags.includes(tag) && tag.length > 0 && !tag.includes(' ')) {
              tags.push(tag);
            }
          });
        });

        tags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));

        const hashtagsWithCount = tags.map((tag) => {
          return {
            tag,
            count: links.reduce((acc: number, cur: Link) => {
              return cur.tags.includes(tag) ? acc + 1 : acc;
            }, 0),
          };
        });
        return [ { tag: 'All', count: maximumLinkCount }, ...hashtagsWithCount ];
      }),
    );
  }

  filterTag(tag: string): void {
    const tags: string[] = [ ...this.hashtagFiltering ];

    if (tag === 'All') {
      this.clearTags();
      return;
    }

    const tagIndex = tags.indexOf(tag);

    // Toggle tag
    if (tagIndex >= 0) {
      this.onTagChanged.emit([ ...tags.slice(0, tagIndex), ...tags.slice(tagIndex + 1) ]);
    } else {
      this.onTagChanged.emit([ ...tags, tag ]);
    }
  }

  clearTags(): void {
    this.onTagChanged.emit([]);
  }

  isSelected(tag: string): boolean {
    return this.hashtagFiltering.includes(tag) ? true : false;
  }
}
