import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { VisibleDataService } from 'src/app/services/visible-data.service';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  @Input() userId: string;
  
  user$: Observable<User | undefined>;

  constructor(
    private visibleDataService: VisibleDataService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.user$ = this.visibleDataService.visibleUsers$.pipe(map((users) => {
      return (users ?? []).find((user) => user.id === this.userId);
    }));
  }
}
