import { Component, Input, OnInit } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {
  @Input() name?: string;
  @Input() value$: BehaviorSubject<string>;
  @Input() fieldSize: NbComponentSize = 'medium';
  @Input() disabled = false;

  @Input() prioritizedCountryCodes?: string[];
  @Input() placeholder?: string;
  @Input() mandatory?: boolean = false;

  countryCode$: BehaviorSubject<string>;
  phoneNumber$: BehaviorSubject<string>;

  constructor(private dataService: DataService) {
    if (!this.value$) {
      this.value$ = new BehaviorSubject<string>('');
      this.phoneNumber$ = new BehaviorSubject<string>('');
    } else {
      this.phoneNumber$ = new BehaviorSubject<string>(this.value$.value);
    }
    this.countryCode$ = new BehaviorSubject<string>('');
  }

  ngOnInit(): void {
  }

  _onChange() {
    this.value$.next(`${this.countryCode$.value}${this.phoneNumber$.value}`);
  }

  onPhoneNumberChange(event: any) {
    this.phoneNumber$.next(event);
    this._onChange();
  }

  onCountryCodeChange(event: any) {
    this.countryCode$.next(event);
    this._onChange();
  }

}
