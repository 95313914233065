import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DYPageLayout, DYPageLayoutType } from 'src/app/models/dy-page-layout';
import { YearbooksService } from 'src/app/services/yearbook.service';

@Component({
  selector: 'app-page-template-selector',
  templateUrl: './page-template-selector.component.html',
  styleUrls: [ './page-template-selector.component.scss' ],
})
export class PageTemplateSelectorComponent implements OnInit {
  @Input() pageLayout: DYPageLayoutType;
  @Output() onPageSelected = new EventEmitter<DYPageLayout>();

  pageLayouts$: Observable<DYPageLayout[]>;
  selectedLayout: DYPageLayout;

  constructor(private yearbooksService: YearbooksService) {}

  ngOnInit(): void {
    this.pageLayouts$ = this.yearbooksService.getPageLayouts().pipe(
      tap((pageLayouts) => {
        if (pageLayouts.length) {
          if (this.pageLayout) {
            this.selectedLayout = pageLayouts.find((layout) => layout.id === this.pageLayout) as DYPageLayout;
            this.onPageSelected.emit(this.selectedLayout);
          } else {
            this.selectedLayout = pageLayouts[0];
            this.onPageSelected.emit(this.selectedLayout);
          }
        }
      }),
    );
  }

  emit(event: any) {
    this.onPageSelected.emit(event);
  }
}
