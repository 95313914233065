import { EditLinkComponent } from './edit-link/edit-link.component';
import { NbComponentSize, NbDialogService, NbTrigger } from '@nebular/theme';
import { LinkService } from './../../../services/link.service';
import { Component, Input, ChangeDetectorRef, OnInit, EventEmitter, Output } from '@angular/core';
import { Link } from 'src/app/models/link';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { LinkCategory } from 'src/app/models/link-category';
import { LinkStoreService } from 'src/app/services/link-store.service';
@Component({
  selector: 'app-link-card',
  templateUrl: './link-card.component.html',
  styleUrls: [ './link-card.component.scss' ],
})
export class LinkCardComponent implements OnInit {
  @Input() link: Link;
  @Input() category: LinkCategory;
  @Input() hashtagFiltering: string[] = [];
  @Input() trigger: NbTrigger;
  @Output() hashtagFilteringChange = new EventEmitter();

  get isAdmin(): boolean {
    if (!this.authService.user) {
      return false;
    }
    if (this.category.name === 'All') {
      const linkCategoryId = this.link.categories[0];
      const categoryIndex = this.linkStoreService.categories.findIndex((c) => c.id === linkCategoryId);
      if (categoryIndex < 0) {
        return false;
      } else {
        return this.linkStoreService.categories[categoryIndex].admins.includes((this.authService.user as User).id);
      }
    } else {
      return this.category.admins.includes((this.authService.user as User).id);
    }
  }

  approved: boolean;
  availableIcons = [
    'custom',
    'email',
    'facebook',
    'facetime',
    'gmail',
    'insead',
    'instagram',
    'linkedin',
    'logo-with-text',
    'mobile',
    'skype',
    'telegram',
    'twitter',
    'whatsapp',
  ];
  editingLink = false;
  errorMessage: string;
  expandDescription = false;
  isLoading = false;
  showCharactersDescription = 50;
  tagButtonSize: NbComponentSize;
  tagErrors = {};

  constructor(
    private linkService: LinkService,
    private linkStoreService: LinkStoreService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private dialogService: NbDialogService,
  ) {
    this.tagButtonSize = window.screen.width > 600 ? 'small' : 'tiny';
  }

  ngOnInit(): void {
    if (!this.link) {
      throw new Error('link must not be null');
    }

    if (!this.category) {
      throw new Error('category must not be null');
    }
  }

  async shouldApproveLink(linkId: string, approve: boolean): Promise<void> {
    this.isLoading = true;
    try {
      await this.linkService
        .shouldApproveLink({
          chatGroupId: linkId,
          shouldApprove: approve,
        })
        .toPromise();
      this.approved = approve ? true : false;
    } catch (err) {
      this.errorMessage = err.message;
    } finally {
      this.isLoading = false;
      this.changeDetector.detectChanges();
    }
  }

  editLink(): void {
    this.dialogService
      .open(EditLinkComponent, {
        context: {
          link: this.link,
          availableSubCats: this.category.subCategories,
          availableIcons: this.availableIcons,
          tagButtonSize: this.tagButtonSize,
          trigger: this.trigger,
        },
        closeOnBackdropClick: false,
      })
      .onClose.subscribe((link) => (this.link = link ? link : this.link));
  }

  messageLink(groupId: number, messageId: number): string {
    const url = 'https://t.me/c/' + Math.abs(Number(groupId)) + '/' + messageId;
    return url;
  }

  tagClicked(tag: string): void {
    const tags: string[] = [ ...this.hashtagFiltering ];

    const tagIndex = tags.indexOf(tag);

    // Toggle tag
    if (tagIndex >= 0) {
      this.hashtagFilteringChange.emit([ ...tags.slice(0, tagIndex), ...tags.slice(tagIndex + 1) ]);
    } else {
      this.hashtagFilteringChange.emit([ ...tags, tag ]);
    }
  }
}
