import { ImageCropperModule } from 'ngx-image-cropper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NbSidebarModule,
  NbLayoutModule,
  NbButtonModule,
  NbToastrModule,
  NbDatepickerModule,
  NbMenuModule,
  NbIconModule,
  NbTooltipModule,
} from '@nebular/theme';
import { ThemeModule } from './@theme/theme.module';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { environment } from '../environments/environment';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { FloatingButtonComponent } from './general/component/floating-button/floating-button.component';

@NgModule({
  declarations: [FloatingButtonComponent, AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    // Firebase
    AngularFireModule.initializeApp(environment.firebaseConfig, {
      ignoreUndefinedProperties: true,
    }),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,

    NbSidebarModule.forRoot(),
    NbLayoutModule,
    NbButtonModule,
    ThemeModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDateFnsDateModule.forRoot({ format: 'dd/MM' }),
    NbEvaIconsModule,
    NbIconModule,
    NbTooltipModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    ImageCropperModule,
  ],
  providers: [ScreenTrackingService, UserTrackingService, PerformanceMonitoringService, PendingChangesGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
