import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-date-no-year-picker',
  templateUrl: './date-no-year-picker.component.html',
  styleUrls: ['./date-no-year-picker.component.scss']
})
export class DateNoYearPickerComponent implements OnInit {
  @Input() name?: string;
  @Input() value$: BehaviorSubject<string|null>;
  @Input() disabled = false;
  @Input() mandatory: boolean = false;

  selectedDay: string = '';
  selectedMonth: string = '';

  totalDay = 31;
  
  private _monthWith31Days = [1, 3, 5, 7, 8, 10, 12];
  private _monthWith30Days = [4, 6, 9, 11];

  moment = moment;

  createArrays(length: number) {
    return new Array(length);
  }

  constructor() {
    if (!this.value$) {
      this.value$ = new BehaviorSubject<string|null>(null);
    }
  }

  ngOnInit(): void {
    if (this.value$.value && this.value$.value !== '') {
      const splittedValue = this.value$.value.match(/.{1,2}/g);
      if (splittedValue) {
        this.selectedDay = splittedValue[0];
        this.selectedMonth = splittedValue[1];
        this.resetTotalDay();
      }
    }
  }

  resetTotalDay() {
    if (this.selectedMonth != '') {
      const month = Number.parseInt(this.selectedMonth);
      if (this._monthWith31Days.includes(month)) {
        this.totalDay = 31;
      } else if (this._monthWith30Days.includes(month)) {
        this.totalDay = 30;
      } else {
        this.totalDay = 28
      }
      
      if (this.selectedDay != '') {
        const day = Number.parseInt(this.selectedDay);
        if (day > this.totalDay) {
          this.selectedDay = this.totalDay.toString().padStart(2, '0');
          this.onDayChange(this.selectedDay);
        }
      }
    }
  }

  onDayChange(event: string): void {
    this._onValueChange(event, this.selectedMonth);
  }

  onMonthChange(event: string): void {
    this._onValueChange(this.selectedDay, event);
    this.resetTotalDay();
  }

  private _onValueChange(day: string, month: string): void {
    if (day && month && day !== '' && month !== '') {
      this.value$.next(`${day}${month}`);
    } else {
      this.value$.next(null);
    }
  }

}
