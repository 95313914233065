import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() name?: string;
  @Input() value$: BehaviorSubject<Date|null>;
  @Input() placeholder: string;

  constructor() {
    if (!this.value$) {
      this.value$ = new BehaviorSubject<Date|null>(null);
    }
  }

  ngOnInit(): void {
  }

  onValueChange(event: any): void {
    this.value$.next(event);
  }

  clear(): void {
    this.value$.next(null);
  }

}
