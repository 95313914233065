import { Link } from 'src/app/models/link';
import { NbToastrService, NbDialogRef, NbTrigger } from '@nebular/theme';
import { Component, Input, OnInit } from '@angular/core';
import { LinkStoreService } from 'src/app/services/link-store.service';

@Component({
  selector: 'app-reorder-sub-cats',
  templateUrl: './reorder-sub-cats.component.html',
  styleUrls: [ './reorder-sub-cats.component.scss' ],
})
export class ReorderSubCatsComponent implements OnInit {
  @Input() category: any;
  @Input() links: Link[] = [];
  @Input() trigger: NbTrigger;
  isLoading = false;
  initialSubCat: any;
  newSubCat = false;
  newSubCatValue: string = '';

  get isSameSubCategories(): boolean {
    const a = this.initialSubCat;
    const b = this.category.subCategories;
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  constructor(
    private linkStoreService: LinkStoreService,
    private toastrService: NbToastrService,
    protected dialogRef: NbDialogRef<any>,
  ) {}

  ngOnInit(): void {
    this.initialSubCat = [ ...this.category.subCategories ];
  }

  reorder(subCat: string, direction: string) {
    let oldIndex = this.category.subCategories.indexOf(subCat);
    let newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1;
    if (newIndex === -1) {
      newIndex = this.category.subCategories.length - 1;
    } else if (newIndex === this.category.subCategories.length) {
      newIndex = 0;
    }
    this.category.subCategories.splice(newIndex, 0, this.category.subCategories.splice(oldIndex, 1)[0]);
  }

  submitReorder() {
    this.isLoading = true;
    this.linkStoreService
      .updateSubCategory(this.category.subCategories, this.category.id)
      .then(() => this.toastrService.success('Data sent!', 'Success'))
      .catch((err) => this.toastrService.danger(err, 'Error'))
      .finally(() => {
        this.isLoading = false;
        this.dialogRef.close();
      });
  }

  linksInSubCat(subCat: string, catId: string) {
    let links = 0;
    for (let i = 0; i < this.links.length; i++) {
      if (Object.values(this.links[i].subCategories).includes(subCat) && this.links[i].categories.includes(catId)) {
        links++;
      }
    }
    return links;
  }

  close() {
    if (this.isSameSubCategories) {
      this.dialogRef.close(ReorderSubCatsComponent);
    } else {
      if (confirm('Are you sure? You will not keep your changes.') === true) {
        this.dialogRef.close(ReorderSubCatsComponent);
      }
    }
  }

  newSubCatChange(event: any) {
    this.newSubCatValue = event.target.value;
  }

  saveNewSubCat() {
    this.category.subCategories.push(this.newSubCatValue);
    this.isLoading = true;
    this.linkStoreService
      .updateSubCategory(this.category.subCategories, this.category.id)
      .then(() => this.toastrService.success('Data sent!', 'Success'))
      .catch((err) => this.toastrService.danger(err, 'Error'))
      .finally(() => {
        this.isLoading = false;
        this.newSubCat = false;
        this.dialogRef.close();
      });
  }

  deleteSubCat(subCat: string) {
    if (confirm('Are you sure you want to delete this Sub Category?') === true) {
      this.category.subCategories = this.category.subCategories.filter((subCategory: string) => {
        return subCategory !== subCat;
      });
      this.isLoading = true;
      this.linkStoreService
        .updateSubCategory(this.category.subCategories, this.category.id)
        .then(() => this.toastrService.success('Data sent!', 'Success'))
        .catch((err) => this.toastrService.danger(err, 'Error'))
        .finally(() => {
          this.isLoading = false;
          this.newSubCat = false;
          this.dialogRef.close();
        });
    }
  }
}
