<nb-card id="card">
  <nb-card-body>
    <div class="icon-circle bg-success">
      <nb-icon icon="link-outline" status="control"></nb-icon>
    </div>
    <div class="cardChild">{{ description || "Description" }}</div>
    <button class="cardChild" appearance="ghost" status="danger" nbButton (click)="cancel()">
      Ok
    </button>
  </nb-card-body>
</nb-card>
