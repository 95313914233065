import { Component, Input, OnInit } from '@angular/core';
import { NbTagComponent, NbTagInputAddEvent } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  @Input() name?: string;
  @Input() value$: BehaviorSubject<string[]>;
  
  valueSet: Set<string> = new Set([]);

  constructor() {
  }

  ngOnInit(): void {
    if (!this.value$) {
      this.value$ = new BehaviorSubject<string[]>([]);
    } else {
      this.valueSet = new Set(this.value$.value);
    }
  }
  
  onTagRemove(tagToRemove: NbTagComponent): void {
    this.valueSet.delete(tagToRemove.text);
    this.onValueChange(this.valueSet);
  }

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    if (value) {
      this.valueSet.add(value);
      this.onValueChange(this.valueSet);
    }
    input.nativeElement.value = '';
  }

  onValueChange(valueSet: Set<string>): void {
    this.value$.next([...valueSet]);
  }

}
