import { Component, Input, ViewChild } from '@angular/core';
import { NbPopoverDirective, NbToastrService } from '@nebular/theme';
import { User } from 'src/app/models/user';
import { UserRole, UsersService } from 'src/app/services/user.service';
import { YearbooksService } from 'src/app/services/yearbook.service';

@Component({
  selector: 'app-user-approval-button',
  templateUrl: './user-approval-button.component.html',
  styleUrls: [ './user-approval-button.component.scss' ],
})
export class UserApprovalButtonComponent {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @Input() user: User;
  isLoading = false;
  roles = UserRole;

  constructor(
    private usersService: UsersService,
    private yearbooksService: YearbooksService,
    private toastrService: NbToastrService,
  ) {}

  async approve(role: UserRole): Promise<any> {
    try {
      this.isLoading = true;
      this.popover.hide();
      await this.usersService.approveUser(this.user, role, this.yearbooksService.resource.userGroupId);
      this.toastrService.success(`${this.user.email} was approved as ${role}`, 'Approved!');
    } catch (err) {
      this.toastrService.danger(err, 'Error');
    } finally {
      this.isLoading = false;
    }
  }

  async reject(): Promise<any> {
    try {
      this.isLoading = true;
      this.popover.hide();
      await this.usersService.rejectUser(this.user, this.yearbooksService.resource.userGroupId);
      this.toastrService.success(`${this.user.email} was rejected`, 'Rejected!');
    } catch (err) {
      this.toastrService.danger(err, 'Error');
    } finally {
      this.isLoading = false;
    }
  }
}
