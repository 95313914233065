import { Component } from '@angular/core';
import { NbDialogService, NbTagComponent, NbTagInputAddEvent } from '@nebular/theme';
import { Observable, Subscription } from 'rxjs';
import { ArticleData } from '../../../models/article-data';
import { YearbooksService } from '../../../services/yearbook.service';
import { ArticleDataCreateDialogComponent } from '../article-data-create-dialog/article-data-create-dialog.component';

@Component({
  selector: 'app-articles-data-manager',
  templateUrl: './articles-data-manager.component.html',
  styleUrls: ['./articles-data-manager.component.scss'],
})
export class ArticlesDataManagerComponent {
  tags: Set<string> = new Set();
  sub?: Subscription;
  articleDataResults$: Observable<ArticleData[]>;

  constructor(private yearbooksService: YearbooksService, private dialogService: NbDialogService) {}

  openCreateModal(): void {
    this.dialogService.open(ArticleDataCreateDialogComponent);
  }

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    if (value) {
      this.tags.add(value);
    }
    input.nativeElement.value = '';
    this.fetchContent();
  }

  onTagRemove(tagToRemove: NbTagComponent): void {
    this.tags.delete(tagToRemove.text);
    this.fetchContent();
  }

  async fetchContent() {
    this.sub?.unsubscribe();
    if ([...this.tags].length == 0) {
      // this.articleDataResults$ = [];
      return;
    }
    const yearbookId = this.yearbooksService.resource.id;
    this.articleDataResults$ = this.yearbooksService.getArticlesData(yearbookId, [...this.tags]);
  }
}
