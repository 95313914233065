<div *ngIf="yearbook">
	<nb-card class="list-card">
		<nb-card-header>
			Articles
			<div>
				<button nbButton ghost>
					<nb-icon icon="plus-outline" status="primary" (click)="openCreateModal()"></nb-icon>
				</button>
			</div>
		</nb-card-header>
		<nb-card-body>
			<nb-list cdkDropList (cdkDropListDropped)="drop($event)">
				<nb-list-item *ngFor="let article of yearbook.articles" cdkDrag>
					{{article.title}}
					<button nbButton ghost>
						<nb-icon icon="edit-outline" status="primary" (click)="edit(article.id)"></nb-icon>
					</button>
				</nb-list-item>
			</nb-list>
		</nb-card-body>
		<nb-card-footer class="text-muted">
			Drag articles to reorder
		</nb-card-footer>
	</nb-card>
</div>