import { Component, Input, OnInit } from '@angular/core';
import { ArticleData } from 'src/app/models/article-data';
import { Yearbook } from 'src/app/models/yearbook';
import { YearbooksService } from 'src/app/services/yearbook.service';

@Component({
  selector: 'app-page-template-profiles',
  templateUrl: './page-template-profiles.component.html',
  styleUrls: ['./page-template-profiles.component.scss'],
})
export class PageTemplateProfilesComponent implements OnInit {
  @Input() yearbook: Yearbook;
  @Input() articleData: ArticleData;
  profileArticles: any[] = [];

  constructor(private yearbooksService: YearbooksService) {}

  ngOnInit(): void {
    if (!this.yearbook) {
      throw Error('Yearbook must not be null');
    }
    this.yearbooksService.getProfiles(this.yearbook.id).then(
      (profiles) =>
        (this.profileArticles = profiles.map((p) => {
          const user = this.yearbook.users.find((u) => u.id === p.id);
          return { ...p, userName: user && user.name };
        })),
    );
  }
}
