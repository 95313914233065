<div [ngSwitch]="article.pageLayout">
	<div *ngSwitchCase="pageLayoutType.cover">
		<app-page-template-cover [content]="article.content"></app-page-template-cover>
	</div>

	<div *ngSwitchCase="pageLayoutType.leftImages">
		<app-page-template-left-images [content]="article.content"></app-page-template-left-images>
	</div>

	<div *ngSwitchCase="pageLayoutType.mosaic">
		<!-- <app-page-template-mosaics [article]="article" [yearbook]="yearbook"></app-page-template-mosaics> -->
	</div>

	<div *ngSwitchCase="pageLayoutType.profiles">
		<!-- <app-page-template-profiles [article]="article" [yearbook]="yearbook"></app-page-template-profiles> -->
	</div>

	<div *ngSwitchCase="pageLayoutType.externalData">
		<app-page-template-external-data [content]="article.content" [yearbook]="yearbook"></app-page-template-external-data>
	</div>

	<div *ngSwitchCase="pageLayoutType.basic">
		<app-page-template-default [content]="article.content"></app-page-template-default>
	</div>
</div>