
<div><b>Comments</b></div>

<nb-card *ngIf="this.authUser">
  <nb-card-body *ngIf="!inputDisabled">
    <div>
      <div class="textbox-wrapper" *ngIf="noFirstName">
        <div class="textbox-label">First name: </div>
        <input nbInput fullWidth [value]="newFirstName$.value" (input)="onNewFirstNameChanged($event.target)" [status]="noFirstNameWarning ? 'danger' : 'basic'" placeholder="First name is required to comment" required>
      </div>
      <div class="textbox-wrapper">
        <input nbInput fullWidth [value]="newCommentText$.value" (input)="onNewCommentTextChange($event.target)" (keydown.enter)="onNewCommentSubmit()" placeholder="Say something!" required />
        <button nbButton type="button" (click)="onNewCommentSubmit()" status="primary">Send</button>
      </div>
    </div>
  </nb-card-body>
  <nb-card-body *ngIf="inputDisabled && inputDisabledMessage">
    <div class="disabled-input-message">
      {{inputDisabledMessage}}
    </div>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="!this.authUser">
  <nb-card-body class="unauthorized-wrapper">
    <button nbButton status="primary" (click)="goToLoginPage()">
      Login or verify your email
    </button>
  </nb-card-body>
</nb-card>

<nb-card *ngIf="isAddingNewComment" [nbSpinner]="true">
	<nb-card-body></nb-card-body>
</nb-card>
<div *ngIf="comments$ | async as comments">
    <div *ngFor="let comment of comments">
        <app-comment-wall-item [comment]="comment" [collectionName]="collectionName" [collectionId]="collectionId"></app-comment-wall-item>
    </div>
    <div class="not-found" *ngIf="comments.length == 0"></div>
</div>