<div class="page-box">
	<div class="content-box content-horizontal">
		<div *ngIf="images && images.length" class="image-col">
			<img *ngFor="let image of images" [src]="image.downloadURL" class="wrapped-img">
		</div>
		<div class="text-col">
			<h2 class="title">{{articleData?.title}}</h2>
			<div class="description" [innerHTML]="articleData?.description"></div>
		</div>
	</div>
	<app-mosaic [images]="articleData?.images || []" class="mosaic"></app-mosaic>
</div>