<nb-card class="linkCard">
  <nb-card-header>
    <div class="iconLink">
      <div class="avatar-wrapper">
        <a [href]="link.url" target="_blank">
          <nb-user [picture]="availableIcons.includes(link.type) ? 'assets/' + link.type + '.png' : 'assets/defaultLink.png'" onlyPicture="true"></nb-user>
        </a>
        <a id="titleLink" [href]="link.url" target="_blank">{{ link.title }}</a>
        <div class="source" *ngIf="link.source && link.source['tg.groupId']">
          <a [href]=" messageLink(link.source['tg.groupId'], link.source['tg.messageId'])" id="source" target="_blank" >(source)</a>
        </div>
      </div>
    </div>
    <div class="description" *ngIf="link.description">
      <div class="descriptionText">
        {{ expandDescription ? link.description : link.description.slice(0, showCharactersDescription) }}
      </div>
      <div *ngIf="link.description.length > showCharactersDescription">
        <button (click)="expandDescription = !expandDescription" nbButton ghost>
          <nb-icon [icon]=" expandDescription ? 'rewind-left-outline' : 'rewind-right-outline' " ></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body class="cardBody">
    <nb-tag-list>
      <nb-tag
        *ngFor="let tag of link.tags"
        [ngStyle]="(tag.includes(' ') || tag.length <= 0) ? { display: 'none' } : null"
        [status]="hashtagFiltering.includes(tag) ? 'danger' : 'warning'"
        appearance="outline"
        [text]="tag"
        [size]="tagButtonSize"
        (click)="tagClicked(tag)"
      ></nb-tag>
    </nb-tag-list>
    <div>
      <button
        nbTooltip="Edit Link"
        [nbTooltipTrigger]="trigger"
        *ngIf="isAdmin && approved !== false"
        [size]="tagButtonSize"
        [nbSpinner]="isLoading"
        class="adminButton"
        (click)="editLink()"
        nbButton
        status="warning"
      >
        <nb-icon status="control" icon="edit-2-outline"></nb-icon>
      </button>
      <button
        class="adminButton"
        [disabled]="isLoading"
        [nbSpinner]="isLoading"
        nbButton
        [size]="tagButtonSize"
        status="success"
        (click)="shouldApproveLink(link.id, true)"
        *ngIf="
          isAdmin &&
          !link.approved &&
          approved === undefined &&
          errorMessage === undefined
        "
      >
        <nb-icon status="control" icon="checkmark-outline"></nb-icon>
      </button>
      <button
        class="adminButton"
        [disabled]="isLoading"
        [nbSpinner]="isLoading"
        nbButton
        [size]="tagButtonSize"
        status="danger"
        (click)="shouldApproveLink(link.id, false)"
        *ngIf="
          isAdmin &&
          !link.approved &&
          approved === undefined &&
          errorMessage === undefined
        "
      >
        <nb-icon status="control" icon="close-outline"></nb-icon>
      </button>
    </div>
    <div *ngIf="approved === true">
      <nb-icon icon="checkmark-outline" status="success"></nb-icon>
      Link Successfully Approved
    </div>
    <div *ngIf="approved === false">
      <nb-icon icon="close-outline" status="danger"></nb-icon>
      Link Successfully Rejected
    </div>
    <div *ngIf="errorMessage">
      <nb-icon icon="alert-triangle-outline" status="danger"></nb-icon>
      {{ errorMessage }}
    </div>
  </nb-card-body>
</nb-card>
