import { IProfileDoc } from './form-builder.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AxiosNestService } from './axios-nest.service';

interface IGroup {
  id: string;
  name: string;
  school: string;
  slug: string;
  type: string;
  bot?: string;
  telegramGroupIds?: {
    deadline: number;
    event: number;
    main: number;
    noticeBoard: number;
    profile: number;
  };
  users?: any;
}
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private afs: AngularFirestore, private axiosNest: AxiosNestService) {}

  showDate(key: string, value: any) {
    if (key === 'birthday' && value.length === 4) {
      let month: string;
      switch (value.slice(2, 4)) {
        case '01':
          month = 'January';
          break;
        case '02':
          month = 'February';
          break;
        case '03':
          month = 'March';
          break;
        case '04':
          month = 'April';
          break;
        case '05':
          month = 'May';
          break;
        case '06':
          month = 'June';
          break;
        case '07':
          month = 'July';
          break;
        case '08':
          month = 'August';
          break;
        case '09':
          month = 'September';
          break;
        case '10':
          month = 'October';
          break;
        case '11':
          month = 'November';
          break;
        case '12':
          month = 'December';
          break;
        default:
          month = 'ERROR';
      }
      return value.slice(0, 2) + ' ' + month;
    } else {
      return value;
    }
  }

  isUrl(value: string) {
    if (typeof value === 'string') {
      const url = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
      return !!value.match(url);
    } else {
      return false;
    }
  }

  getSchoolForm(schoolId: string) {
    return this.afs
      .collection('/schools')
      .doc(schoolId)
      .collection('forms')
      .doc<IProfileDoc>('profile')
      .valueChanges();
  }

  async update(profileData: {[id: string]: any}, schoolId?: string): Promise<boolean> {
    const params = profileData;

    if (schoolId)
      params['schoolId'] = schoolId;

    try {
      const result = await this.axiosNest.post('profileUpdate', params);

      const statusCode = result.status;
      if(statusCode == 200 || statusCode == 201) {
        return true;
      }
    } catch {}

    return false;
  }
}
