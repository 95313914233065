import { BaseResource, BaseSerializer } from '../services/base.service';
import { Comment } from './comment';
import { DYPageLayoutType } from './dy-page-layout';

export interface IArticleData {
  id?: string;
  title?: string;
  description?: string;
  isFinished?: boolean;
  isUserProfile?: boolean;
  order?: number;
  pageLayout?: DYPageLayoutType;
  images?: {
    [filename: string]: IArticleDataImage;
  };
  visibleTo?: string;
  lastComments?: Comment[];
  tags?: string[];
}

export interface IArticleDataImage {
  position: number;
  downloadURL: string;
  path: string;
  filename: string;
}

export class ArticleData extends BaseResource {
  id: string;
  title: string;
  description: string;
  isFinished: boolean;
  isUserProfile: boolean;
  order: number;
  pageLayout: DYPageLayoutType;
  images: IArticleDataImage[];
  visibleTo: string;
  contactInfo?: { [key: string]: string };
  lastComments?: Comment[];
  tags: string[];

  constructor(obj: IArticleData) {
    super(obj);
    this.title = obj.title || 'Title';
    this.isFinished = !!obj.isFinished || false;
    this.description = obj.description ?? '';
    this.isUserProfile = obj.isUserProfile || false;
    this.order = obj.order || 100;
    this.pageLayout = obj.pageLayout || DYPageLayoutType.basic;
    this.images = (obj.images && this.imagesAsArray(obj.images)) || [];
    this.visibleTo = obj.visibleTo || 'everyone';
    this.lastComments = obj.lastComments ?? [];
    this.tags = obj.tags ?? [];
  }

  imagesAsArray(imagesObj: { [filename: string]: IArticleDataImage }): {
    position: number;
    downloadURL: string;
    path: string;
    filename: string;
  }[] {
    const arr = Object.values(imagesObj);
    arr.sort((a, b) => Number(a.position) - Number(b.position));
    return arr;
  }

  imagesAsObject(imagesArr: IArticleDataImage[]): {
    [filename: string]: IArticleDataImage;
  } {
    const obj: any = {};
    imagesArr.filter((img) => !!img.downloadURL).forEach((v, i) => (obj[v.filename] = { ...v, position: i }));
    return obj;
  }
}

export class ArticleDataSerializer extends BaseSerializer<ArticleData> {
  fromJson(json: any): ArticleData {
    return new ArticleData(json);
  }

  toJson(resource: ArticleData): any {
    return {
      ...resource,
      images: resource.imagesAsObject(resource.images),
    };
  }
}
