import { BaseResource, BaseSerializer } from '../services/base.service';
import * as moment from 'moment';

export interface IWallComment {
  id: string;
  text: string;
  userId: string;
  createdAt: any; //firebase.default.firestore.Timestamp;
  updatedAt: any; //firebase.default.firestore.Timestamp;
}

export class WallComment extends BaseResource implements IWallComment {
  id: string;
  text: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(obj: IWallComment) {
    super(obj);
    this.text = obj.text ?? '';
    this.userId = obj.userId || '';
  }

  createdAtFromNow() {
    return moment(this.createdAt).fromNow();
  }
}

export class WallCommentSerializer extends BaseSerializer<WallComment> {
  fromJson(json: any): WallComment {
    return new WallComment(json);
  }

  toJson(resource: WallComment): any {
    return {
      ...resource,
    };
  }
}
