import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-group-requests',
  templateUrl: './user-group-requests.component.html',
  styleUrls: [ './user-group-requests.component.scss' ],
})
export class UserGroupRequestsComponent {
  groupsStatus$: Observable<{ name: string; status: string }[]>;
  constructor(private userService: UsersService) {
    this.groupsStatus$ = this.userService.getUserGroups();
  }

  getCssClassName(status: string): string {
    switch (status) {
      case 'approved':
        return 'text-success';
      case 'pending':
        return '';
      case 'rejected':
        return 'text-danger';
      default:
        return '';
    }
  }
}
