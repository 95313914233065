<nb-layout>
  <nb-layout-header>
    <app-header title="Tumla Apps"></app-header>
  </nb-layout-header>

  <nb-layout-column>
    <nb-card status="danger">
      <nb-card-header> Use Our App!</nb-card-header>
      <nb-card-body>
        <b>Our web version is in beta mode. If you are here for the yearbook, please use our app instead.</b><br />
        <br />
        If you ended up here through a magic link in the app, please request a new one and tap on the link on the
        smartphone you requested it.
      </nb-card-body>
      <nb-card-footer>
        <a
          href="https://play.google.com/store/apps/details?id=com.tumla.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          style="
            display: inline-block;
            overflow: hidden;
            border-top-left-radius: 13px;
            border-top-right-radius: 13px;
            border-bottom-right-radius: 13px;
            border-bottom-left-radius: 13px;
            height: 83px;
          "
          ><img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            style="
              border-top-left-radius: 13px;
              border-top-right-radius: 13px;
              border-bottom-right-radius: 13px;
              border-bottom-left-radius: 13px;
              height: 83px;
            "
        /></a>
        <a
          href="https://apps.apple.com/us/app/tumla/id1517629936?itsct=apps_box_badge&amp;itscg=30200"
          style="
            display: inline-block;
            overflow: hidden;
            border-top-left-radius: 13px;
            border-top-right-radius: 13px;
            border-bottom-right-radius: 13px;
            border-bottom-left-radius: 13px;
            width: 250px;
            height: 83px;
          "
          ><img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1636156800&h=7e3303962dcf0c50153cab8d824e53e1"
            alt="Download on the App Store"
            style="
              border-top-left-radius: 13px;
              border-top-right-radius: 13px;
              border-bottom-right-radius: 13px;
              border-bottom-left-radius: 13px;
              width: 250px;
              height: 83px;
            "
        /></a>
      </nb-card-footer>
    </nb-card>
    <nb-card accent="primary">
      <nb-card-header>
        Links Directory
        <button nbButton [routerLink]="'/links'">
          <nb-icon icon="chevron-right"></nb-icon>
        </button>
      </nb-card-header>
      <nb-card-body>
        A Link Directory is a list of links extracted from the social platforms that uses the Tumla Bot. Originally,
        they were only chat groups, but now they represent links to all kinds of interesting links and resources users
        suggest.
      </nb-card-body>
    </nb-card>
    <nb-card accent="success">
      <nb-card-header>
        Digital Yearbooks
        <button nbButton [routerLink]="'/yearbooks'">
          <nb-icon icon="chevron-right"></nb-icon>
        </button>
      </nb-card-header>
      <nb-card-body>
        A Digital Yearbook is a digital and interactive representation of a yearbook.<br />
        <br />
        Our goal with this app is to connect users and make them feel that warm feeling in the heart while looking at
        those fond memories 💚
      </nb-card-body>
    </nb-card>
    <nb-card accent="info">
      <nb-card-header>
        People's Profiles
        <button nbButton [routerLink]="'/profile/classes/select'">
          <nb-icon icon="chevron-right"></nb-icon>
        </button>
      </nb-card-header>
      <nb-card-body>
        Now you can see other people's profile in a better looking way. Telegram is great and everything but it's not so
        good in showing data like this.<br />
      </nb-card-body>
    </nb-card>
    <nb-card accent="primary" *ngIf="userEmail && superUser">
      <nb-card-header>
        Form Builder
        <button nbButton [routerLink]="'/form-builder'">
          <nb-icon icon="chevron-right"></nb-icon>
        </button>
      </nb-card-header>
      <nb-card-body> A tool where you can edit which things people can see and edit in their profiles ✍️ </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>
