<nb-card>
	<nb-card-header>New Article</nb-card-header>
	<nb-card-body class="dialog-card">
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<div class="form-group row">
				<div class="col-12">
					<input formControlName="title" type="text" nbInput fullWidth id="inputTitle" placeholder="Title">
				</div>
			</div>
			<button nbButton type="submit" status="primary" [disabled]="form.invalid">Create</button>
		</form>
	</nb-card-body>
</nb-card>