import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralStorageService {
  showScrollToTopButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  setShowScrollToTop(newValue: boolean) {
    this.showScrollToTopButton$.next(newValue);
  }
}
