import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap, startWith } from 'rxjs/operators';
import { Article, ArticleSerializer } from '../models/article';
import { ArticleData, IArticleData, ArticleDataSerializer } from '../models/article-data';
import { Comment, CommentSerializer } from '../models/comment';
import { DYExternalData, DYExternalDataSerializer } from '../models/dy-external-data';
import { DYPageLayout, DYPageLayoutSerializer } from '../models/dy-page-layout';
import { User, UserSerializer } from '../models/user';
import { Yearbook, YearbookSerializer } from '../models/yearbook';
import { AuthService, CLASS_ORGANIZER } from './auth.service';
import { AxiosNestService } from './axios-nest.service';
import { BaseService } from './base.service';

const YEARBOOKS_PATH = '/DY_yearbooks';
const AGGREGATION_ID = '--aggregation';

@Injectable({
  providedIn: 'root',
})
export class YearbooksService extends BaseService<Yearbook> {
  private user$: Observable<User | null>;

  constructor(public client: AngularFirestore, private authService: AuthService, private axiosNest: AxiosNestService) {
    super(YEARBOOKS_PATH, client, new YearbookSerializer());
    this.user$ = this.authService.user$;
  }

  list(): Observable<Yearbook[]> {
    return this.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of([]);
        } else {
          return super
            .list()
            .pipe(
              map((yearbooks) =>
                yearbooks.filter((yearbook) => user.userGroupsStatus[yearbook.userGroupId] === 'approved'),
              ),
            );
        }
      }),
    );
  }

  update(yearbook: Yearbook): Promise<any> {
    if (!yearbook.id) {
      // Create a new yearbook
      return this.axiosNest.post('createYearbook', new YearbookSerializer().toJson(yearbook));
    } else {
      // Update existing yearbook
      return super.update(yearbook);
    }
  }

  getUnapprovedUsers(userGroupId: string): Observable<User[]> {
    return this.afs
      .collection('users', (ref) => ref.where(`userGroupsStatus.${userGroupId}`, '==', 'pending'))
      .snapshotChanges()
      .pipe(
        map((snap) => snap.map((doc) => new UserSerializer().fromJson(doc.payload.doc.data()) as User)),
        tap((unapprovedUsers) => (this.resource.unapprovedUsers = unapprovedUsers)),
      );
  }

  getApprovedUsers(userGroupId: string): Observable<User[]> {
    return this.afs
      .collection('users', (ref) => ref.where(`userGroupsStatus.${userGroupId}`, '==', 'approved'))
      .snapshotChanges()
      .pipe(
        map((snap) => snap.map((doc) => new UserSerializer().fromJson(doc.payload.doc.data()) as User)),
        tap(async (approvedUsers) => {
          this.resource.users = approvedUsers;

          this.resource.users = await this.getUsers(this.resource.userGroupId).toPromise();

          // Reorder users for profiles
          const users = this.resource.users;
          users.sort((u1, u2) => {
            if (u1.name.toLowerCase() < u2.name.toLowerCase()) {
              return -1;
            }
            if (u1.name.toLowerCase() > u2.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          const currentUser = this.authService.user;
          const currentUserIndex = users.findIndex((u) => u.id === (currentUser as User).id);
          this.resource.users =
            currentUserIndex === -1
              ? users
              : [users[currentUserIndex], ...users.slice(0, currentUserIndex), ...users.slice(currentUserIndex + 1)];
        }),
        shareReplay(1),
      );
  }

  getUsers(userGroupId: string): Observable<User[]> {
    return this.afs
      .collection('users', (ref) => ref.where(`userGroupsStatus.${userGroupId}`, '==', 'approved'))
      .get()
      .pipe(map((snap) => snap.docs.map((doc) => new UserSerializer().fromJson(doc.data()) as User)));
  }

  getArticles(): Observable<Article[]> {
    const yearbookId = this.resource.id;
    if (!yearbookId) {
      of([]);
    }
    return this.afs
      .collection(`${YEARBOOKS_PATH}/${yearbookId}/articles`, (ref) => ref.orderBy('order'))
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map(
            (doc) =>
              new ArticleSerializer().fromJson({
                ...(doc.payload.doc.data() as any),
                id: doc.payload.doc.id,
              }) as Article,
          ),
        ),
        // Get content snaps
        switchMap((articleObjs) => {
          const articlesDataPerArticle$ = combineLatest(
            articleObjs.map((article) => {
              return combineLatest(
                article.targetTags?.map((tag) => {
                  let articlesDataRef;
                  const tagStr = tag;

                  // Convert from yearbook included images instead
                  const categoryPattern = 'categoryId-';
                  if (tagStr.includes(categoryPattern)) {
                    articlesDataRef = this.afs.collection('photos', (ref) =>
                      ref
                        .where('categoryId', '==', tagStr.split(categoryPattern)[1])
                        .where('addToYearbook', '==', true),
                    );
                  }

                  // Default behavior
                  articlesDataRef = this.afs
                    .collection('DY_yearbooks')
                    .doc(yearbookId)
                    .collection('articlesData', (ref) => ref.where('tags', 'array-contains', tagStr));

                  const articlesDataStream = articlesDataRef.get();

                  return articlesDataStream.pipe(
                    map((snap) => {
                      if (snap.empty) return [];
                      return snap.docs.map((contentDoc) => {
                        return new ArticleData({ ...(contentDoc.data() as IArticleData), id: contentDoc.id });
                      });
                    }),
                    startWith([]),
                  );
                }) ?? [],
              ).pipe(
                map((articlesDatasArrays) => {
                  return articlesDatasArrays.reduce((acc, cur) => [...acc, ...cur], []);
                }),
                startWith([]),
              );
            }),
          );

          return combineLatest([of(articleObjs), articlesDataPerArticle$]).pipe(
            map(([articles, articlesDataPerArticle]) => {
              const articlesCopy = articles.map((article, idx) => {
                const newArticle = new Article(article);
                newArticle.content = articlesDataPerArticle[idx];
                return newArticle;
              });
              articlesCopy.sort((a, b) => {
                if (a > b) return 1;
                if (a < b) return -1;
                return 0;
              });
              return articlesCopy;
            }),
          );
        }),
        tap((articles) => {
          this.resource.articles = articles;
        }),
        shareReplay(1),
      );
  }

  getArticle(articleId: string): Observable<Article> {
    return this.afs
      .collection(YEARBOOKS_PATH)
      .doc(this.resource.id)
      .collection('articles')
      .doc(articleId)
      .snapshotChanges()
      .pipe(
        map(
          (doc) =>
            new ArticleSerializer().fromJson({
              ...(doc.payload.data() as any),
              id: doc.payload.id,
            }) as Article,
        ),
      );
  }

  saveArticle(article: Article): Promise<void> {
    const yearbookId = this.resource.id;
    if (!yearbookId || !article) {
      return Promise.reject('Either yearbook or article was null');
    }
    return this.afs
      .collection(YEARBOOKS_PATH)
      .doc(yearbookId)
      .collection('articles')
      .doc(article.id)
      .set(new ArticleSerializer().toJson(article), { merge: true });
  }

  saveArticleData(articleData: ArticleData): Promise<void> {
    const yearbookId = this.resource.id;
    if (!yearbookId || !articleData) {
      return Promise.reject('Either yearbook or articleData was null');
    }
    return this.afs
      .collection(YEARBOOKS_PATH)
      .doc(yearbookId)
      .collection('articlesData')
      .doc(articleData.id)
      .set(new ArticleDataSerializer().toJson(articleData), { merge: true });
  }

  createArticleData(articleData: ArticleData): Promise<any> {
    const yearbookId = this.resource.id;
    if (!yearbookId || !articleData) {
      return Promise.reject('Either yearbook or articleData was null');
    }
    return this.afs
      .collection(YEARBOOKS_PATH)
      .doc(yearbookId)
      .collection('articlesData')
      .add(new ArticleDataSerializer().toJson(articleData));
  }

  async deleteArticle(article: Article): Promise<void> {
    const yearbookId = this.resource.id;
    if (!yearbookId) {
      return;
    }
    return this.afs.collection(`${YEARBOOKS_PATH}/${yearbookId}/articles`).doc(article.id).delete();
  }

  async deleteArticleData(articleData: ArticleData): Promise<void> {
    await this.axiosNest.post('deleteYearbookArticleData', {
      yearbookId: this.resource.id,
      articleDataId: articleData.id,
      groupId: this.resource.userGroupId,
    });
  }

  async getProfiles(yearbookId: string): Promise<ArticleData[]> {
    if (!yearbookId) {
      return [];
    }
    const snap = await this.afs
      .collection(`${YEARBOOKS_PATH}/${yearbookId}/articlesData`)
      .ref.where('isUserProfile', '==', true)
      .get();
    return snap.docs.map(
      (doc) =>
        new ArticleDataSerializer().fromJson({
          ...(doc.data() as any),
          id: doc.id,
          isUserProfile: true,
        }) as ArticleData,
    );
  }

  getArticlesData(yearbookId: string, tags: string[]): Observable<ArticleData[]> {
    if (!yearbookId) {
      return of([]);
    }
    return this.afs
      .collection(`${YEARBOOKS_PATH}/${yearbookId}/articlesData`, (ref) =>
        ref.where('tags', 'array-contains-any', tags),
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map(
            (change) => new ArticleData({ ...(change.payload.doc.data() as any), id: change.payload.doc.id }),
          ),
        ),
      );
  }

  getExternalData(yearbookId: string, tags: string[]): Observable<DYExternalData[]> {
    if (!yearbookId) {
      return of([]);
    }
    return this.afs
      .collection(`${YEARBOOKS_PATH}/${yearbookId}/externalData`, (ref) =>
        ref.where('tags', 'array-contains-any', tags),
      )
      .get()
      .pipe(
        map((snap) => {
          const externalData = snap.docs
            .filter((doc) => doc.id !== AGGREGATION_ID)
            .map(
              (doc) =>
                DYExternalDataSerializer.fromJson({
                  ...(doc.data() as any),
                  id: doc.id,
                }) as DYExternalData,
            );
          externalData.sort((a, b) => {
            if ((a.title || '') < (b.title || '')) return -1;
            if ((a.title || '') > (b.title || '')) return 1;
            return 0;
          });
          return externalData;
        }),
      );
  }

  async getCoverImage(yearbookId: string): Promise<string> {
    if (!yearbookId) {
      return '';
    }
    return this.get(yearbookId)
      .pipe(
        take(1),
        map((yearbook) => yearbook.photo && yearbook.photo.imageUrl),
      )
      .toPromise();
  }

  getProfile(profileId: string): Observable<ArticleData> {
    const yearbookId = this.resource.id;
    if (!yearbookId || !profileId) {
      throw new Error('getProfile() must have yearbookId and profileId');
    }
    return this.afs
      .collection(YEARBOOKS_PATH)
      .doc(yearbookId)
      .collection('articlesData')
      .doc(profileId)
      .snapshotChanges()
      .pipe(
        map(
          (snap) =>
            new ArticleDataSerializer().fromJson(
              {
                ...snap.payload.data(),
                id: snap.payload.id,
                isUserProfile: true,
              } || {},
            ) as ArticleData,
        ),
      );
  }

  saveProfile(profile: ArticleData): Promise<void> {
    const yearbookId = this.resource.id;
    if (!yearbookId || !profile) {
      return Promise.reject('Either yearbook or profile was null');
    }
    profile.isUserProfile = true;
    return this.afs
      .collection(YEARBOOKS_PATH)
      .doc(yearbookId)
      .collection('articlesData')
      .doc(profile.id)
      .set(new ArticleDataSerializer().toJson(profile), { merge: true });
  }

  saveArticlesOrder(): Promise<void> {
    // Reassign order based on position in array
    for (let i = 0; i < this.resource.articles.length; i++) {
      this.resource.articles[i].order = i;
    }

    // Save articles as batch
    const batch = this.afs.firestore.batch();
    for (const article of this.resource.articles) {
      batch.set(
        this.afs.doc(`${YEARBOOKS_PATH}/${this.resource.id}/articles/${article.id}`).ref,
        new ArticleSerializer().toJson(article),
        { mergeFields: ['order'] },
      );
    }

    return batch.commit();
  }

  createArticle(item: Article): Promise<any> {
    const id = item.id || this.afs.createId();

    return this.afs
      .collection(YEARBOOKS_PATH)
      .doc(this.resource.id)
      .collection('articles')
      .doc(id)
      .set({
        ...new ArticleSerializer().toJson(item),
        id,
      });
  }

  async lockYearbook(): Promise<any> {
    const articlesData = await this.afs
      .collection(`/DY_yearbooks/${this.resource.id}/articlesData`)
      .get()
      .pipe(
        map((snap) =>
          snap.docs.map(
            (doc) =>
              new ArticleDataSerializer().fromJson({
                ...(doc.data() as any),
                id: doc.id,
              }) as ArticleData,
          ),
        ),
      )
      .toPromise();
    const promises = [];

    // Lock yearbook
    this.resource.isFinished = true;
    promises.push(this.update(this.resource));

    // Lock articlesData
    for (const articleData of articlesData) {
      promises.push(
        this.afs
          .collection(`/DY_yearbooks/${this.resource.id}/articlesData`)
          .doc(articleData.id)
          .update({ isFinished: true }),
      );
    }

    return Promise.all(promises);
  }

  isUserAuthorized(user: User, yearbookId: string): Observable<boolean> {
    if (!user || !yearbookId) {
      return of(false);
    }

    if (!this.resource || this.resource.id !== yearbookId || !this.resource.users) {
      return this.get(yearbookId).pipe(
        switchMap(async (yearbook) => {
          if (!(yearbook && yearbook.userGroupId)) {
            return false;
          }

          return user.userGroupsStatus[yearbook.userGroupId] === 'approved';
        }),
      );
    } else {
      return of(true);
    }
  }

  async requestAccessToYearbook(user: User, yearbook: Yearbook): Promise<any> {
    if (!(yearbook && yearbook.userGroupId)) {
      return Promise.reject('Yearbook does not belong to a group');
    }
    if (user.userGroupsStatus[yearbook.userGroupId]) {
      return Promise.reject('You already requested access to this yearbook');
    }

    const groupSnap = await this.afs.collection('user_groups').doc(yearbook.userGroupId).get().toPromise();
    const groupData: any = groupSnap.data();
    return this.axiosNest.post('signUpUser', {
      operationMode: 'requestAccessToGroup',
      email: user.email,
      classId: yearbook.userGroupId,
      schoolId: groupData.school,
    });
  }

  getYBUserGroup(userGroupId: string): Observable<any> {
    return this.afs.collection('user_groups').doc(userGroupId).valueChanges();
  }

  isUserAdmin(): Observable<boolean> {
    return of(
      (this.authService.user as User).classStudentPermission[this.resource.userGroupId] === CLASS_ORGANIZER,
    ).pipe(
      tap(async (isAdmin) => {
        this.resource.unapprovedUsers = [];
        if (isAdmin) {
          await this.getUnapprovedUsers(this.resource.userGroupId).toPromise();
        }
      }),
    );
  }

  // Wall

  getWall(yearbookId: string, articleDataId: string): Observable<Comment[]> {
    if (!yearbookId || !articleDataId) {
      throw new Error('getWall() must have yearbookId and profileId');
    }
    return this.afs
      .collection('/DY_yearbooks')
      .doc(yearbookId)
      .collection('articlesData')
      .doc(articleDataId)
      .collection('wall', (ref) => ref.orderBy('createdAt', 'desc'))
      .get()
      .pipe(
        map((collectionSnap) =>
          collectionSnap.docs.map(
            (snap) => new CommentSerializer().fromJson({ ...snap.data(), id: snap.id } || {}) as Comment,
          ),
        ),
      );
  }

  async createComment(yearbookId: string, articleDataId: string, comment: Comment): Promise<any> {
    if (!yearbookId || !articleDataId) {
      return null;
    }
    const doc = this.afs
      .collection('/DY_yearbooks')
      .doc(yearbookId)
      .collection('articlesData')
      .doc(articleDataId)
      .collection('wall')
      .doc();
    const id = doc.ref.id;
    comment.id = id;
    return doc.set(new CommentSerializer().toJson(comment));
  }

  async deleteComment(yearbookId: string, articleDataId: string, comment: Comment): Promise<void> {
    if (!yearbookId || !articleDataId) {
      return;
    }
    return this.afs
      .collection('/DY_yearbooks')
      .doc(yearbookId)
      .collection('articlesData')
      .doc(articleDataId)
      .collection('wall')
      .doc(comment.id)
      .delete();
  }

  // Page layouts

  getPageLayouts(): Observable<DYPageLayout[]> {
    const layoutNames = ['basic', 'cover', 'mosaic', 'profiles'];
    const layouts = layoutNames.map((name) => {
      const layout = new DYPageLayout({ name: name, assets: [] });
      layout.id = name;
      return layout;
    });
    return of(layouts);
  }

  getExternalDataTags(yearbookId: string): Observable<string[]> {
    if (!yearbookId) {
      return of([]);
    }
    return this.afs
      .doc(`${YEARBOOKS_PATH}/${yearbookId}/externalData/--aggregation`)
      .get()
      .pipe(
        map((snap) => {
          if (snap.exists) {
            const tags = (snap.data() as any).tags || [];
            tags.sort((a: string, b: string) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            });
            return tags;
          } else {
            return [];
          }
        }),
      );
  }
}
