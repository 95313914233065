<div class="wall-section">
	<div *ngIf="showSeeAllButton; else expandedView">
		<form [formGroup]="form" (keyup.enter)="onSubmit()">
			<input class="text-input" formControlName="content" type="text" nbInput fullWidth [placeholder]=" placeholderText || 'Say something to the class'" required/>
		</form>
		<p *ngFor="let comment of comments || []" class="wall-comment">
			<span class="username">{{comment.userName}}: </span>
			{{comment.content}}
		</p>
		<a class="text-primary" (click)="seeAll()">See all</a>
	</div>
	<ng-template #expandedView>
		
		<form [formGroup]="form" (keyup.enter)="onSubmit()">
			<input class="text-input" formControlName="content" type="text" nbInput fullWidth [placeholder]=" placeholderText || 'Say something to the class'" required/>
		</form>

		<div *ngFor="let comment of comments || []" class="extended-comment">
			<div (click)="goToProfile(comment.userId)">
				<nb-user name="{{comment.userName}}" picture="{{comment.userThumb || 'assets/profile-placeholder.svg'}}" size="large" onlyPicture></nb-user>
			</div>
			<div>
				<p><span class="username">{{comment.userName}}</span><span class="text-muted"> {{moment(comment.createdAt).fromNow()}}</span></p>
				<p>
					{{comment.content}}
					<button *ngIf="!showSeeAllButton && showDeleteButton(comment)" (click)="onDelete(comment)" nbButton ghost status="danger">
						<nb-icon icon="trash-2-outline"></nb-icon>
					</button>
				</p>
			</div>
		</div>
	</ng-template>
</div>