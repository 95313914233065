import { Component, Input } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ProfilePhotoDialogComponent } from '../profile-photo-dialog/profile-photo-dialog.component';

@Component({
  selector: 'app-profile-title',
  templateUrl: './profile-title.component.html',
  styleUrls: [ './profile-title.component.scss' ],
})
export class ProfileTitleComponent {
  @Input() profileUser: Observable<User>;
  @Input() title: string;

  constructor(private dialogService: NbDialogService, private authService: AuthService) {}

  maybeOpenProfileImagePopup(profileUser: User): void {
    if ((this.authService.user as User).id === profileUser.id) {
      this.dialogService.open(ProfilePhotoDialogComponent, {
        context: {},
      });
    }
  }
}
