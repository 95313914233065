import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IArticleDataImage } from 'src/app/models/article-data';

@Component({
  selector: 'app-mosaic-item',
  templateUrl: './mosaic-item.component.html',
  styleUrls: ['./mosaic-item.component.scss'],
})
export class MosaicItemComponent implements OnInit {
  @Input() uploadFolder: string;
  @Input() image: IArticleDataImage;
  @Output() onDelete: EventEmitter<IArticleDataImage> = new EventEmitter<IArticleDataImage>();

  constructor() {}

  ngOnInit(): void {
    if (!this.uploadFolder) {
      throw new Error('uploadFolder must not be null');
    }

    if (!this.image) {
      throw new Error('image must not be null');
    }
  }

  delete() {
    this.onDelete.emit(this.image);
  }
}
