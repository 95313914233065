<div class="wrapper-target">
	<div *ngIf="canUpload" appDropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering" class="file-target">
		<label class="file-label">
			<input class="file-input" type="file" (change)="onDrop($event)" hidden>
			<span class="add-element" [style.opacity]="transparent ? 0 : 1"></span>
			</label>
	</div>
	
	<div *ngFor="let file of files" class="progress-target">
		<app-upload-task [file]="file" [paramDir]="paramDir" [paramName]="paramName" (finished)="onFinish($event)" style="display: block;" [rounded]="rounded"></app-upload-task>
	</div>
</div>