import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
export interface IBaseService<T> {
  get(id: string): Observable<T>;
  list(): Observable<T[]>;
  add(item: T): Promise<any>;
  update(item: T): Promise<void>;
  delete(id: string): Promise<void>;
}

export interface IBaseResource {
  id: string;
  createdAt?: Date | firebase.default.firestore.Timestamp;
  updatedAt?: Date | firebase.default.firestore.Timestamp;
}

export interface ISerializer {
  toJson(resource: BaseResource): any;
  fromJson(json: any): BaseResource;
}

export class BaseResource implements IBaseResource {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(json: any) {
    this.id = json.id || '';
    this.createdAt = this.timestampToDate(json.createdAt);
    this.updatedAt = this.timestampToDate(json.updatedAt);
  }

  protected timestampToDate(param: any): Date {
    if (param instanceof firebase.default.firestore.Timestamp) {
      return param.toDate();
    } else if (param instanceof Date) {
      return param;
    } else if (typeof param === 'string') {
      return new Date(param);
    } else {
      return new Date();
    }
  }

  protected timestampToDateUndefinedable(param: any): Date | undefined {
    if (param instanceof firebase.default.firestore.Timestamp) {
      return param.toDate();
    } else if (param instanceof Date) {
      return param;
    } else if (typeof param === 'string') {
      return new Date(param);
    } else {
      return undefined;
    }
  }

  get createdAtTimeAgo(): string {
    if (this.createdAt) {
      return moment(this.createdAt).fromNow();
    }
    return '';
  }
}

export abstract class BaseSerializer<T extends BaseResource> implements ISerializer {
  toJson(resource: T): any {
    return {
      ...resource,
      id: resource.id || '',
      createdAt: firebase.default.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.default.firestore.FieldValue.serverTimestamp(),
    };
  }
  fromJson(json: any): T {
    return new BaseResource({
      ...json,
      id: json.id,
      createdAt: json.createdAt ? new Date(json.createdAt) : new Date(),
      updatedAt: json.updatedAt ? new Date(json.updatedAt) : new Date(),
    }) as T;
  }
}

export abstract class BaseService<T extends BaseResource> {
  protected collection: AngularFirestoreCollection<T>;

  private itemsBehavior = new BehaviorSubject<T[]>([]);
  public item$ = this.itemsBehavior.asObservable();

  get items(): T[] {
    return this.itemsBehavior.getValue();
  }

  set items(items: T[]) {
    this.itemsBehavior.next(items);
  }

  private resourceBehavior = new BehaviorSubject<T | null>(null);
  public resource$ = this.resourceBehavior.asObservable();

  get resource(): T {
    return this.resourceBehavior.getValue() as T;
  }

  set resource(resource: T) {
    this.resourceBehavior.next(resource);
  }

  constructor(path: string, protected afs: AngularFirestore, protected serializer: ISerializer) {
    this.collection = this.afs.collection(path);
  }

  get(id: string): Observable<T> {
    return this.collection.doc<T>(id).snapshotChanges().pipe(
      map((action) => {
        if (!action.payload.exists) {
          return {} as T;
        }
        const data = action.payload.data() as T;
        const docId = action.payload.id;
        return this.serializer.fromJson({ ...data, id: docId }) as T;
      }),
      tap((resource) => {
        this.resource = resource;
      }),
    );
  }

  list(): Observable<T[]> {
    return this.collection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as T;
          const id = a.payload.doc.id;
          return this.serializer.fromJson({ ...data, id }) as T;
        }),
      ),
      tap((items) => {
        this.items = items;
      }),
    );
  }
  add(item: T): Promise<any> {
    const id = item.id || this.afs.createId();
    const data = {
      ...this.serializer.toJson(item),
      id,
    };

    return this.collection.doc(data.id).set(data, { merge: true });
  }
  update(item: T): Promise<any> {
    const data = this.serializer.toJson(item);

    return this.collection.doc(data.id).set(data, { merge: true });
  }
  delete(id: string): Promise<void> {
    return this.collection.doc(id).delete();
  }
}
