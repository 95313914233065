import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbInputModule,
  NbCardModule,
  NbDialogModule,
  NbDatepickerModule,
  NbPopoverModule,
  NbListModule,
  NbTagModule,
  NbSpinnerModule,
  NbAutocompleteModule,
  NbCheckboxModule,
  NbAccordionModule,
  NbRadioModule,
  NbTooltipModule,
  NbButtonGroupModule,
  NbFormFieldModule,
  NbToggleModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DEFAULT_THEME } from './styles/theme.default';

import { HeaderCenterComponent, HeaderComponent, HeaderLeftComponent, HeaderRightComponent, HeaderSubTitleComponent } from './components';
import { FavoriteButtonComponent } from './components/favorite-button/favorite-button.component';
import { WallComponent } from './components/wall/wall.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MosaicComponent } from './components/mosaic/mosaic.component';
import { UploaderModule } from '../uploader/uploader.module';
import { ProfileTitleComponent } from './components/profile-title/profile-title.component';
import { ProfilePhotoDialogComponent } from './components/profile-photo-dialog/profile-photo-dialog.component';
import { ProfileSubmitDialogComponent } from './components/profile-submit-dialog/profile-submit-dialog.component';
import { YearbookCreateDialogComponent } from './components/yearbook-create-dialog/yearbook-create-dialog.component';
import { UserApprovalButtonComponent } from './components/user-approval-button/user-approval-button.component';
import { UserGroupRequestsComponent } from './components/user-group-requests/user-group-requests.component';
import { YearbookEditComponent } from './components/yearbook-edit/yearbook-edit.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ArticlesCardComponent } from './components/articles-card/articles-card.component';
import { ArticleDataCreateDialogComponent } from './components/article-data-create-dialog/article-data-create-dialog.component';
import { WelcomeDialogComponent } from './components/welcome-dialog/welcome-dialog.component';
import { CopiedLinkDialogComponent } from './components/copied-link-dialog/copied-link-dialog.component';
import { AppsRouterPageComponent } from './components/apps-router-page/apps-router-page.component';
import { RouterModule } from '@angular/router';
import { LinkCardComponent } from './components/link-card/link-card.component';
import { TinyMCEComponent } from './components/tiny-mce/tiny-mce.component';
import { ArticleVisualizationComponent } from './components/article-visualization/article-visualization.component';
import { PageTemplateCoverComponent } from './components/page-template-cover/page-template-cover.component';
import { PageTemplateProfilesComponent } from './components/page-template-profiles/page-template-profiles.component';
import { PageTemplateSelectorComponent } from './components/page-template-selector/page-template-selector.component';
import { PageTemplateExternalDataComponent } from './components/page-template-external-data/page-template-external-data.component';
import { PageTemplateDefaultComponent } from './components/page-template-default/page-template-default.component';

import { LinksHashtagFilterComponent } from './components/links-hashtag-filter/links-hashtag-filter.component';
import { LinksCategoryTabComponent } from './components/links-category-tab/links-category-tab.component';
import { LinkReorderButtonsComponent } from './components/link-reorder-buttons/link-reorder-buttons.component';
import { LinksDownloadCsvButtonComponent } from './components/links-download-csv-button/links-download-csv-button.component';

import { PageTemplateLeftImagesComponent } from './components/page-template-left-images/page-template-left-images.component';
import { PageTemplateMosaicsComponent } from './components/page-template-mosaics/page-template-mosaics.component';
import { ExternalContentTagsSelectComponent } from './components/external-content-tags-select/external-content-tags-select.component';
import { EditLinkComponent } from './components/link-card/edit-link/edit-link.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MosaicManageComponent } from './components/mosaic-manage/mosaic-manage.component';
import { MosaicItemComponent } from './components/mosaic-item/mosaic-item.component';
import { ArticleDataComponent } from './components/article-data/article-data.component';
import { ArticlesDataManagerComponent } from './components/articles-data-manager/articles-data-manager.component';
import { ArticleCreateDialogComponent } from './components/article-create-dialog/article-create-dialog.component';
import { CommentWallComponent } from './components/comment-wall/comment-wall.component';
import { CommentWallItemComponent } from './components/comment-wall-item/comment-wall-item.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { TextBoxComponent } from './components/form/text-box/text-box.component';
import { DatePickerComponent } from './components/form/date-picker/date-picker.component';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { ImageUploadComponent } from './components/form/image-upload/image-upload.component';
import { SelectComponent } from './components/form/select/select.component';
import { DemoComponent } from './components/form/demo/demo.component';
import { DateNoYearPickerComponent } from './components/form/date-no-year-picker/date-no-year-picker.component';
import { TagComponent } from './components/form/tag/tag.component';
import { ToggleComponent } from './components/form/toggle/toggle.component';
import { TextAreaComponent } from './components/form/text-area/text-area.component';
import { TextRichComponent } from './components/form/text-rich/text-rich.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { LikeWallComponent } from './components/like-wall/like-wall.component';
import { PipesModule } from '../pipes/pipes.module';
import { CountryCodePickerComponent } from './components/form/country-code-picker/country-code-picker.component';
import { PhoneNumberComponent } from './components/form/phone-number/phone-number.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbInputModule,
  NbCardModule,
  NbListModule,
  NbPopoverModule,
  NbTagModule,
  NbDialogModule.forChild(),
  NbDatepickerModule,
  UploaderModule,
  DragDropModule,
  NbSpinnerModule,
  NbAutocompleteModule,
  NbCheckboxModule,
  NbAccordionModule,
  NbRadioModule,
  NbTooltipModule,
  ScrollingModule,
  NbButtonGroupModule,
  NbUserModule,
  NbFormFieldModule,
  NbDateFnsDateModule.forRoot({ format: 'dd/MM' }),
  NbToggleModule,
  HttpClientModule,
  AngularEditorModule,
];
const OTHER_MODULES = [FormsModule, ReactiveFormsModule, RouterModule, PipesModule];
const COMPONENTS = [
  HeaderComponent,
  HeaderLeftComponent,
  HeaderCenterComponent,
  HeaderRightComponent,
  HeaderSubTitleComponent,
  FavoriteButtonComponent,
  WallComponent,
  MosaicComponent,
  ProfileTitleComponent,
  ProfilePhotoDialogComponent,
  ProfileSubmitDialogComponent,
  YearbookCreateDialogComponent,
  UserApprovalButtonComponent,
  UserGroupRequestsComponent,
  YearbookEditComponent,
  ConfirmDialogComponent,
  ArticlesCardComponent,
  ArticlesDataManagerComponent,
  ArticleCreateDialogComponent,
  ArticleDataComponent,
  LinkCardComponent,
  ArticleDataCreateDialogComponent,
  WelcomeDialogComponent,
  CopiedLinkDialogComponent,
  AppsRouterPageComponent,
  ArticleVisualizationComponent,
  PageTemplateCoverComponent,
  PageTemplateExternalDataComponent,
  PageTemplateProfilesComponent,
  PageTemplateSelectorComponent,
  PageTemplateDefaultComponent,
  LinksHashtagFilterComponent,
  LinksCategoryTabComponent,
  LinkReorderButtonsComponent,
  LinksDownloadCsvButtonComponent,
  PageTemplateLeftImagesComponent,
  PageTemplateMosaicsComponent,
  ExternalContentTagsSelectComponent,
  TinyMCEComponent,
  EditLinkComponent,
  MosaicManageComponent,
  MosaicItemComponent,
  CommentWallComponent,
  CommentWallItemComponent,
  UserAvatarComponent,
  UserNameComponent,
  TextBoxComponent,
  DatePickerComponent,
  ImageUploadComponent,
  SelectComponent,
  DemoComponent,
  DateNoYearPickerComponent,
  TagComponent,
  ToggleComponent,
  TextAreaComponent,
  TextRichComponent,
  NotFoundPageComponent,
  LikeWallComponent,
  CountryCodePickerComponent,
  PhoneNumberComponent,
];

const THEMES = [DEFAULT_THEME];

@NgModule({
  imports: [CommonModule, ...NB_MODULES, ...OTHER_MODULES],
  exports: [CommonModule, ...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [...(NbThemeModule.forRoot({ name: 'tumla' }, THEMES).providers as any)],
    };
  }
}
