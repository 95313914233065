<nb-accordion *ngIf="pageLayouts$ | async as pageLayouts">
	<nb-accordion-item>
		<nb-accordion-item-header>Current layout: {{selectedLayout && selectedLayout.name}}</nb-accordion-item-header>
		<nb-accordion-item-body>
			<nb-radio-group [(ngModel)]="selectedLayout" (valueChange)="emit($event)" class="row">
				<nb-radio *ngFor="let layout of pageLayouts" [value]="layout" class="col">
					<img [src]="'assets/page-template-' + layout.id + '-placeholder.svg'">
				</nb-radio>
			</nb-radio-group>
		</nb-accordion-item-body>
	</nb-accordion-item>
</nb-accordion>