import { BaseResource, BaseSerializer, IBaseResource } from '../services/base.service';
import { ITripLocation, TripLocation } from './trips';

export interface IUserContact extends IBaseResource {
  type: string;
  value: string;
  label?: string;
  userId: string;
}

export class UserContact extends BaseResource {
  type: string;
  value: string;
  label?: string;
  userId: string;

  constructor(obj: IUserContact) {
    super(obj);
    this.type = obj.type;
    this.value = obj.value;
    this.label = obj.label;
    this.userId = obj.userId;
  }
}

export class UserContactSerializer extends BaseSerializer<UserContact> {
  fromJson(json: any): UserContact {
    return new UserContact(json);
  }

  toJson(resource: UserContact): any {
    return {
      ...resource,
    } as IUserContact;
  }
}