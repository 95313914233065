<nb-card>
  <nb-card-body class="dialog-card">
		<nb-icon class="warning-icon" icon="alert-circle-outline" status="primary"></nb-icon>
		<section>
			<p>Content cannot be edited again after submission</p>
			<p>Are you sure you wish to submit now?</p>
		</section>
		<section>
			<button nbButton ghost class="cancel-button" (click)="this.ref.close()">Cancel</button>
			<button nbButton status="primary" (click)="this.ref.close(true)">Submit</button>
		</section>
  </nb-card-body>
</nb-card>