export interface IDYExternalData {
  title?: string;
  subtitle?: string;
  description?: string;
  photoUrl?: string;
  tags?: string[];
}

export class DYExternalData implements IDYExternalData {
  title?: string;
  subtitle?: string;
  description?: string;
  photoUrl?: string;
  tags?: string[];

  constructor(obj: IDYExternalData) {
    this.title = obj.title;
    this.subtitle = obj.subtitle;
    this.description = obj.description;
    this.photoUrl = obj.photoUrl;
    this.tags = obj.tags;
  }
}

export class DYExternalDataSerializer {
  static fromJson(json: any): DYExternalData {
    return new DYExternalData(json);
  }

  static toJson(resource: DYExternalData): any {
    return {
      ...resource,
    };
  }
}
