
<div *ngIf="title">
	<h5 style="margin: 0; text-align: center;">{{title}}</h5>
</div>
<div *ngIf="!title && (profileUser | async) as profileUser" class="profile-title">
	<div (click)="maybeOpenProfileImagePopup(profileUser)">
		<nb-user
			[name]="profileUser.name"
			[picture]="profileUser.photo?.thumbUrl || ''">
		</nb-user>
	</div>
	<app-favorite-button [user]="profileUser"></app-favorite-button>
</div>