import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralStorageService } from 'src/app/services/general-storage.service';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent implements OnInit {
  tgWebAppVersion?: string;
  get isFromTgWebApp(): boolean { return (this.tgWebAppVersion ?? '') !== '' };

  isScrollToTopEnabled = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private generalStorageService: GeneralStorageService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.tgWebAppVersion = params['tgWebAppVersion'] ?? undefined;
        if (this.tgWebAppVersion) {
          sessionStorage.setItem('tgWebAppVersion', this.tgWebAppVersion);
        } else {
          this.tgWebAppVersion = sessionStorage.getItem('tgWebAppVersion') ?? undefined;
        }
    
        // console.log(this.tgWebAppVersion);
      }
    );

    this.generalStorageService.showScrollToTopButton$.subscribe((showScrollToTopButton) => {
      this.isScrollToTopEnabled = showScrollToTopButton;
    });
  }

  async openInNewTab(): Promise<void> {
    // console.log(window.location.href);
    const url = window.location.href.split('mailto:')[1]
    window.open(url, "_blank");
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}
