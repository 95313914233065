<div class="button-group">
	<button nbButton status="primary" size="tiny" (click)="approve(roles.student)" class="approve-button" [disabled]="isLoading">
		Approve as student
	</button>
	<button nbButton size="tiny" status="primary" class="popover-button" [nbPopover]="templateRef" nbPopoverPlacement="bottom" [disabled]="isLoading">
		<nb-icon icon="chevron-down" status="control"></nb-icon>
	</button>
</div>

<ng-template #templateRef>
	<nb-list>
		<nb-list-item (click)="approve(roles.student)">Approve as contributor</nb-list-item>
		<nb-list-item (click)="approve(roles.classOrganizer)">Approve as admin</nb-list-item>
		<nb-list-item (click)="reject()">Reject</nb-list-item>
	</nb-list>
</ng-template>