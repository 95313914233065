import { Component, Input, OnInit } from '@angular/core';
import { ArticleData } from '../../../models/article-data';

@Component({
  selector: 'app-page-template-default',
  templateUrl: './page-template-default.component.html',
  styleUrls: ['./page-template-default.component.scss'],
})
export class PageTemplateDefaultComponent implements OnInit {
  @Input() content: ArticleData[];
  articleData?: ArticleData;

  constructor() {}

  ngOnInit(): void {
    this.articleData = this.content?.[0];
  }
}
