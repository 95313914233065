import { BaseResource, BaseSerializer } from '../services/base.service';

interface IComment {
  userId: string;
  userName: string;
  userThumb: string;
  content: string;
}

export class Comment extends BaseResource implements IComment {
  userId: string;
  userName: string;
  userThumb: string;
  content: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(obj: IComment) {
    super(obj);
    this.userId = obj.userId || '';
    this.userName = obj.userName || '';
    this.userThumb = obj.userThumb || '';
    this.content = obj.content || '';
  }
}

export class CommentSerializer extends BaseSerializer<Comment> {
  fromJson(json: any): Comment {
    return new Comment(json);
  }

  toJson(resource: Comment): any {
    return {
      ...resource,
    };
  }
}
