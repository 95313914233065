import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { User } from '../models/user';
import { AxiosNestService } from './axios-nest.service';
import { environment } from '../../environments/environment';
import { HttpParams } from '@angular/common/http';

export const CLASS_ORGANIZER = 'Class Organiser';

const appleClientId = 'com.tumla.webapp';
const appleSignInUrl = 'https://appleid.apple.com/auth/authorize';
const appleSignInRedirectUrl = 'https://europe-west3-tumla2020.cloudfunctions.net/api/oauth/appleSignIn/webapp';

const googleClientId = environment.googleOAuthClientId;
const googleSignInUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user$: Observable<User | null>;
  user: User | null;
  isAdmin: Observable<boolean>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private axiosNest: AxiosNestService,
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs
            .doc<User>(`users/${user.uid}`)
            .valueChanges()
            .pipe(map((firebaseUser) => new User(firebaseUser as any)));
        } else {
          return of(null);
        }
      }),
    );
    this.isAdmin = this.user$.pipe(
      map((user) => !!user && !!Object.values(user.classStudentPermission).find((v) => v === CLASS_ORGANIZER)),
    );
  }

  async register(email: string, password: string): Promise<void> {
    await this.afAuth.createUserWithEmailAndPassword(email, password);
    // this.updateUserData(credential.user);
  }

  signInWithEmail(email: string, password: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async getMagicLink(email: string, queryParamsAsString?: string): Promise<string> {
    return (await this.axiosNest.post('magicLink',
    { email: email, telegramId: 1, queryParamsAsString },
    { headers: { 'x-api-key': environment.xApiKey } }))['data'];
  }

  async requestMagicLink(email: string, queryParamsAsString?: string): Promise<void> {
    await this.axiosNest.post('signUpUser', { email, operationMode: 'magicLink', queryParamsAsString });
    window.localStorage.setItem('emailForSignIn', email);
  }

  async confirmSignIn(email?: string|null): Promise<boolean> {
    if (await this.afAuth.isSignInWithEmailLink(window.location.href)) {
      if (!email) {
        email = window.localStorage.getItem('emailForSignIn');
      }

      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }

      await this.afAuth.signInWithEmailLink(email as string, window.location.href);
      window.localStorage.removeItem('emailForSignIn');
      return true;
    }
    return false;
  }

  async signOut(): Promise<void> {
    await this.afAuth.signOut();
    this.router.navigate(['/auth/login']);
  }

  requestPass(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return this.afAuth.confirmPasswordReset(code, newPassword);
  }

  async verifyEncryptedCredential(iv: string, content: string): Promise<void> {
    return this.axiosNest.post('verifyEncryptedCredential', {
      encryptedCredentialString: JSON.stringify({ iv, content }),
    });
  }

  getSuperUsers() {
    return this.afs.collection('/user_info').doc('form_builder').valueChanges();
  }

  async loginWithOAuth(scopes: string[]) {
    // Send token to server and get oauthUrl
    const response = await this.axiosNest.get(`oauth/generateAuthUrl`);
    const oauthUrl = response.data;
    open(oauthUrl);
  }

  async saveOAuthRefreshTokenToFirebase(code: string) {
    return this.axiosNest.post('oauth/saveOAuthRefreshTokenToFirebase', { code: code });
  }

  /*verifyPasswordResetCode(code){
    return this.afAuth.verifyPasswordResetCode(code);
  }*/

  getSignInAppleURL(): string {
    const params = {
      client_id: appleClientId,
      redirect_uri: appleSignInRedirectUrl,
      response_mode: 'form_post',
      response_type: 'code id_token',
      scope: 'name email',
    };

    const httpParams = new HttpParams({ fromObject: params });

    return `${appleSignInUrl}?${httpParams.toString()}`;
  }

  getSignInGoogleURL(googleSignInRedirectUrl: string): string {
    const params = {
      client_id: googleClientId,
      redirect_uri: googleSignInRedirectUrl,
      response_type: 'token',
      scope: 'profile email',
      ux_mode: 'redirect',
    };

    const httpParams = new HttpParams({ fromObject: params });

    return `${googleSignInUrl}?${httpParams.toString()}`;
  }
}
