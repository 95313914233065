<nb-card>
    <nb-card-body>
        <app-text-box [name]="textName" [value$]="textValue$" [placeholder]="textPlaceholder"></app-text-box>
        <app-text-area [name]="textAreaName" [value$]="textAreaValue$" [placeholder]="textAreaPlaceholder" [rows]="textAreaRows"></app-text-area>
        <app-date-picker [name]="dateName" [value$]="dateValue$" [placeholder]="datePlaceholder"></app-date-picker>
        <app-date-no-year-picker [name]="dateNoYearName" [value$]="dateNoYearValue$"></app-date-no-year-picker>
        <app-image-upload [name]="imageUploadName" [initialValue]="imageInitialValue" [value$]="imageUploadValue$"></app-image-upload>
        <app-select [name]="selectName" [value$]="selectValue$" [options]="selectOptions" [placeholder]="selectPlaceholder"></app-select>
        <app-tag [name]="tagName" [value$]="tagValue$"></app-tag>
        <app-toggle [leftLabel]="toggleLeftLabel" [rightLabel]="toggleRightLabel" [value$]="toggleValue$"></app-toggle>
        <app-text-rich [name]="textRichName" [value$]="textRichValue$" [placeholder]="textRichPlaceholder" [showPreview]="true"></app-text-rich>
    </nb-card-body>
</nb-card>