import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: [ './confirm-dialog.component.scss' ],
})
export class ConfirmDialogComponent {
  title: string;
  description: string;
  confirmStatus: string;
  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>) {}

  cancel(): void {
    this.ref.close();
  }
  confirm(): void {
    this.ref.close(true);
  }
}
