// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { sharedVariables } from './environment.shared';

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyA4NeHEc3DwVc3frAvugP2qof9UolkiDk0',
    authDomain: 'tumla2020.firebaseapp.com',
    databaseURL: 'https://tumla2020.firebaseio.com',
    projectId: 'tumla2020',
    storageBucket: 'tumla2020.appspot.com',
    messagingSenderId: '272818466053',
    appId: '1:272818466053:web:6260303e6a07d04b3e1b6c',
    measurementId: 'G-B2LWDWDTEM',
  },
  googleOAuthClientId: '272818466053-2eif9ihmh1gel296jprb1jnuolkcd91o.apps.googleusercontent.com',
  ...sharedVariables,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
