import { Component, Input, OnInit } from '@angular/core';
import { ArticleData } from '../../../models/article-data';

@Component({
  selector: 'app-page-template-left-images',
  templateUrl: './page-template-left-images.component.html',
  styleUrls: ['./page-template-left-images.component.scss'],
})
export class PageTemplateLeftImagesComponent implements OnInit {
  @Input() content: ArticleData[];
  images: ArticleData['images'];
  articleData?: ArticleData;

  constructor() {}

  ngOnInit(): void {
    this.images = this.content?.map((e) => e.images).reduce((acc, cur) => [...acc, ...cur], []) ?? [];
  }
}
