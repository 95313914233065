import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { take } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { WallComment } from 'src/app/models/wall-comment';
import { AuthService } from 'src/app/services/auth.service';
import { WallService } from 'src/app/services/wall.service';

@Component({
  selector: 'app-comment-wall-item',
  templateUrl: './comment-wall-item.component.html',
  styleUrls: ['./comment-wall-item.component.scss'],
})
export class CommentWallItemComponent implements OnInit {
  @Input() comment: WallComment;
  @Input() collectionName: string;
  @Input() collectionId: string;
  
  authUser: User | null;
  isMyComment = false;
  isDeletingComment = false;

  dialogRef: NbDialogRef<any>;

  constructor(
    private wallService: WallService,
    private auth: AuthService,
    private dialogService: NbDialogService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.authUser = await this.auth.user$.pipe(take(1)).toPromise();
    if (this.authUser) {
      this.isMyComment = this.authUser.id === this.comment.userId;
    }
  }
  
  onDeleteConfirmationButtonClicked(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog);
  }

  async onDeleteButtonClicked() {
    if (this.authUser && this.isMyComment) {
      this.isDeletingComment = true;
      this.dialogRef.close();
      await this.wallService.deleteComment(this.collectionName, this.collectionId, this.comment.id);
      this.isDeletingComment = false;
    }
  }
}
