<div class="page-box">
	<div class="content-box">
		<h2 class="title">{{articleData.title}}</h2>
		<div class="description" [innerHTML]="articleData.description"></div>
		<div *ngFor="let profile of profileArticles" class="profile-box">
			<div class="title">{{profile.title}}</div>
			<div>Written by: <span class="author">{{profile.userName}}</span></div>
			<div class="description" [innerHTML]="profile.description" *ngIf="profile.description"></div>
			<app-mosaic [images]="profile.images"></app-mosaic>
			<div class="comments-box">
				<p *ngFor="let comment of profile.lastComments || []" class="wall-comment">
					<span class="username">{{comment.userName}}: </span>
					{{comment.content}}
				</p>
			</div>
		</div>
	</div>
</div>