import { BaseResource, BaseSerializer } from '../services/base.service';
import { Article } from './article';
import { User } from './user';

export interface IYearbook {
  name: string;
  dueDate: Date;
  isFinished: boolean;
  userGroupId: string;
  photo: {
    imageUrl: string;
    thumbUrl: string;
  };
  articles: Article[];
  ownerId: string;
}

export class Yearbook extends BaseResource implements IYearbook {
  name: string;
  dueDate: Date;
  isFinished: boolean;
  userGroupId: string;
  photo: {
    imageUrl: string;
    thumbUrl: string;
  };
  articles: Article[];
  ownerId: string;
  users: User[];
  unapprovedUsers: User[];
  id: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(obj: IYearbook) {
    super(obj);
    this.name = obj.name;
    this.userGroupId = obj.userGroupId;
    this.dueDate = this.timestampToDate(obj.dueDate);
    this.isFinished = obj.isFinished || this.dueDate <= new Date();
    this.photo = obj.photo != null ? obj.photo : { imageUrl: '', thumbUrl: '' };
    this.articles = [];
    this.ownerId = obj.ownerId || '';
  }
}

export class YearbookSerializer extends BaseSerializer<Yearbook> {
  fromJson(json: any): Yearbook {
    return new Yearbook(json);
  }

  toJson(resource: Yearbook): any {
    return {
      id: resource.id,
      createdAt: resource.createdAt,
      updatedAt: resource.updatedAt,
      name: resource.name,
      dueDate: resource.dueDate,
      isFinished: resource.isFinished,
      userGroupId: resource.userGroupId,
      photo: resource.photo,
      ownerId: resource.ownerId,
    };
  }
}
