export interface ILinkCategory {
  name: string;
  id: string;
  admins?: string[];
  empty?: boolean;
  notEmptySubCats?: string[];
  subCategories?: string[];
  description?: string;

  // approved: boolean;
  // /**
  //  * The categories that a link belongs to.
  //  */
  // categories: string[];
  // subCategories: any;
  // deleted: boolean;
  // is_info: boolean;
  // owner: string;
  // tags: string[];
  // source?: Object;
  // title: string;
  // type?: ChatGroupType;
  // url: string;

  // // Chat bot stuff
  // groupChatId?: number;
  // botSettings?: any;
}

export class LinkCategory implements ILinkCategory {
  name: string;
  id: string;
  admins: string[];
  empty: boolean;
  notEmptySubCats: string[];
  subCategories: string[];
  description: string;

  constructor(obj: ILinkCategory) {
    this.name = obj.name;
    this.id = obj.id;
    this.admins = obj.admins || [];
    this.empty = !!obj.empty;
    this.notEmptySubCats = obj.notEmptySubCats || [];
    this.subCategories = obj.subCategories || [];
    this.description = obj.description || '';
  }
}

export class LinkCategorySerializer {
  static fromJson(json: any): LinkCategory {
    return new LinkCategory(json);
  }

  static toJson(resource: LinkCategory): any {
    return {
      name: resource.name,
      id: resource.id,
      subCategories: resource.subCategories,
      description: resource.description,
    } as ILinkCategory;
  }
}
