import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-profile-submit-dialog',
  templateUrl: './profile-submit-dialog.component.html',
  styleUrls: [ './profile-submit-dialog.component.scss' ],
})
export class ProfileSubmitDialogComponent {
  constructor(public ref: NbDialogRef<ProfileSubmitDialogComponent>) {}
}
