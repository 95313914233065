import { Component, Input, OnInit } from '@angular/core';
import { ArticleData } from '../../../models/article-data';

@Component({
  selector: 'app-page-template-cover',
  templateUrl: './page-template-cover.component.html',
  styleUrls: ['./page-template-cover.component.scss'],
})
export class PageTemplateCoverComponent implements OnInit {
  @Input() content: ArticleData[];
  articleData?: ArticleData;
  imageUrl?: string;

  constructor() {}

  ngOnInit(): void {
    this.articleData = this.content?.[0];
    this.imageUrl = this.articleData?.images?.[0]?.downloadURL;
  }
}
