<nb-card *ngIf="this.authService.user$ | async as user">
  <nb-card-body>
		<app-profile-photo-uploader></app-profile-photo-uploader>
		<section>
			<p>Welcome, {{user.firstName}}!</p>
			<p>Let's get started with your yearbook entry by uploading your profile photo</p>
		</section>
		<section class="another-action text-primary" (click)="this.ref.close()">
			Upload photo later
		</section>
  </nb-card-body>
</nb-card>