<div *ngIf="category.id === 'All'; else regularTab">
  <div *ngIf="false; else filters"></div>
  <div *ngIf="filteredCategoryLinks$ | async as links; else loading">
    <div *ngFor="let link of links">
      <app-link-card *ngIf="link.approved" [link]="link"  [category]="category" [hashtagFiltering]="hashtagFilter$.value" [trigger]="trigger" (hashtagFilteringChange)="handleOnTagChanged($event)"></app-link-card>
    </div>
  </div>

  <div *ngIf="filteredCategoryLinks$ | async as links; else loading">
    <div *ngFor="let link of links">
      <app-link-card *ngIf="!link.approved" [link]="link"  [category]="category" [hashtagFiltering]="hashtagFilter$.value" [trigger]="trigger" (hashtagFilteringChange)="handleOnTagChanged($event)"></app-link-card>
    </div>
  </div>

  <app-link-reorder-buttons *ngIf="categoryLinks$ | async as links" [category]="category" [size]="size" [trigger]="trigger" [links]="links"></app-link-reorder-buttons>
</div>

<ng-template #regularTab>

  <div id="scrollTo" class="scrollButtons">
    <button class="scrollButton" id="main" *ngIf="showSubCats" (click)="showSubCats = false" [size]="size" nbButton nbTooltip="Hide Sub Cats" [nbTooltipTrigger]="trigger">
      {{ category.name }} Sub Cats
    </button>
    <button class="opaqueIcon" *ngIf="!showSubCats" (click)="showSubCats = true" [size]="size" nbButton style="width: fit-content" status="danger" nbTooltip="Show Sub Cats" [nbTooltipTrigger]="trigger">
      <nb-icon icon="arrowhead-down-outline"></nb-icon>
    </button>
    <div *ngIf="showSubCats">
      <div *ngIf="subCategories$ | async as subCats">
        <div *ngFor="let subCat of subCats">
          <button class="scrollButton" nbButton [size]="size" (click)="scrollToSubCat(subCat)">
            {{ subCat }}
          </button>
        </div>
      </div>
      <button class="scrollButton" nbButton [size]="size" *ngIf="displayOthers$ | async" (click)="scrollToSubCat('others')">
        Other Links
      </button>
      <button *ngIf="hasUnapprovedLinks$ | async" class="scrollButton" nbButton [size]="size" (click)="scrollToSubCat('pending')">
        Pending Approval
      </button>
    </div>
  </div>

  <div *ngIf="false; else filters"></div>

  <div *ngIf="subCategories$ | async as subCats">
    <p *ngIf="category.description" class="descriptionTitle">
      {{ category.description }}
    </p>
    <div *ngFor="let subCat of subCats">
      <div class="title orangeText" [id]="subCat">
        {{ subCat }}
      </div>
      <div *ngIf="linksWithSubcategory$ | async as links; else loading">
        <div *ngFor="let link of links">
          <app-link-card *ngIf="link.subCategories[category.id] === subCat && link.approved" [link]="link" [category]="category" [hashtagFiltering]="hashtagFilter$.value" [trigger]="trigger" (hashtagFilteringChange)="handleOnTagChanged($event)"></app-link-card>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="displayOthers$ | async" class="title" id="others">
    Other Links
  </div>

  <div *ngIf="linksWithoutSubcategory$ | async as links; else loading">
    <div *ngFor="let link of links">
        <app-link-card *ngIf="link.approved" [link]="link" [category]="category" [trigger]="trigger" [hashtagFiltering]="hashtagFilter$.value" (hashtagFilteringChange)="handleOnTagChanged($event)"></app-link-card>
    </div>
  </div>

  <div *ngIf="hasUnapprovedLinks$ | async" id="pending" class="title">Links Pending Approval</div>
  
  <div *ngIf="unapprovedLinks$ | async as links; else loading">
    <div *ngFor="let link of links">
      <app-link-card [link]="link" [trigger]="trigger" [category]="category" [hashtagFiltering]="hashtagFilter$.value" (hashtagFilteringChange)="handleOnTagChanged($event)"></app-link-card>
    </div>
  </div>

  <app-link-reorder-buttons *ngIf="categoryLinks$ | async as links" [category]="category" [size]="size" [trigger]="trigger" [links]="links"></app-link-reorder-buttons>
</ng-template>

<ng-template #filters>
  <app-links-hashtag-filter
    [links$]="filteredCategoryLinks$"
    [maximumLinkCount$]="maximumLinkCount$"
    [size]="size"
    [hashtagFiltering]="hashtagFilter$.value"
    (onTagChanged)="handleOnTagChanged($event)">
  </app-links-hashtag-filter>
</ng-template>

<ng-template #loading>
  <nb-spinner status="danger" size="giant"></nb-spinner>
</ng-template>