import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { YearbooksService } from 'src/app/services/yearbook.service';
import { Article } from '../../../models/article';

@Component({
  selector: 'app-external-content-tags-select',
  templateUrl: './external-content-tags-select.component.html',
  styleUrls: ['./external-content-tags-select.component.scss'],
})
export class ExternalContentTagsSelectComponent implements OnInit {
  @Input() article: Article;
  @Output() onSelect = new EventEmitter<string>();
  tags$: Observable<string[]>;
  selectedItem: string = '';

  constructor(private yearbooksService: YearbooksService) {}

  ngOnInit(): void {
    if (!this.article) {
      throw Error('Article must not be null');
    }
    // this.selectedItem = this.article.externalContentTag;
    this.tags$ = this.yearbooksService.getExternalDataTags(this.yearbooksService.resource.id);
  }

  emit(value: string) {
    this.onSelect.emit(value);
  }
}
