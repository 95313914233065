import { Component } from '@angular/core';
import { Yearbook } from 'src/app/models/yearbook';
import { YearbooksService } from 'src/app/services/yearbook.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NbDialogService } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { Article } from '../../../models/article';
import { ArticleCreateDialogComponent } from '../article-create-dialog/article-create-dialog.component';
@Component({
  selector: 'app-articles-card',
  templateUrl: './articles-card.component.html',
  styleUrls: ['./articles-card.component.scss'],
})
export class ArticlesCardComponent {
  yearbook: Yearbook;

  constructor(
    private yearbooksService: YearbooksService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
  ) {
    this.yearbook = this.yearbooksService.resource;
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.yearbook.articles, event.previousIndex, event.currentIndex);
    this.yearbooksService.saveArticlesOrder();
  }

  openCreateModal(): void {
    this.dialogService.open(ArticleCreateDialogComponent);
  }

  edit(articleId: string): void {
    this.router.navigate(['articles', articleId], { relativeTo: this.route });
  }

  identify(index: number, article: Article) {
    return article.id;
  }
}
