import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-country-code-picker',
  templateUrl: './country-code-picker.component.html',
  styleUrls: ['./country-code-picker.component.scss']
})
export class CountryCodePickerComponent implements OnInit {
  @Input() value$: BehaviorSubject<string>;
  @Input() fieldSize: NbComponentSize = 'medium';
  @Input() disabled = false;

  @Input() prioritizedCountryCodes?: string[];
  @Input() placeholder?: string;
  @Input() mandatory?: boolean = false;

  options: {
    value: string,
    key: string,
  }[] = [];
  filteredOptions$: Observable<{
    value: string,
    key: string,
  }[]>;

  constructor(private dataService: DataService) {
    if (!this.value$) {
      this.value$ = new BehaviorSubject<string>('');
    }
    
    this.dataService.getPhoneCountryCodes().subscribe(phoneCountryCodes => {
      const sortedPhoneCountryCodes = (this.prioritizedCountryCodes ?? []).length === 0 ? phoneCountryCodes : phoneCountryCodes
        .sort((a, b) => (this.prioritizedCountryCodes?.some((prioritizedCountryCode) => a['code'].toLowerCase().includes(prioritizedCountryCode.toLowerCase())) ? -1 : 0));
      this.options = sortedPhoneCountryCodes
        .map((phoneCountryCode) => {
          return {
            value: phoneCountryCode['dial_code'],
            key: `${phoneCountryCode['emoji']} ${phoneCountryCode['name']} ${phoneCountryCode['dial_code']}`
          }
        });
      this.filteredOptions$ = of(this.options);
    });
  }

  ngOnInit(): void {
  }

  private filter(value: string): {
    value: string,
    key: string,
  }[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.key.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<{
    value: string,
    key: string,
  }[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange(event: any) {
    this.filteredOptions$ = this.getFilteredOptions(event.value);
  }

  onSelectionChange(event: any) {
    this.filteredOptions$ = this.getFilteredOptions(event);
    // console.log(event);
    this.value$.next(event);
  }

}
