import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import 'firebase/firestore';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Input() paramName: string;
  @Input() paramDir: string;
  @Input() rounded: boolean;
  @Output() finished: EventEmitter<File> = new EventEmitter();

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  previewUrl: string | ArrayBuffer;

  filename: string;
  path: string;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) {}

  ngOnInit(): void {
    // Storage path
    this.filename = this.paramName || this.file.name;
    this.path = `${this.paramDir}/${this.filename}`;
    this.preview(this.file);

    this.startUpload();
  }

  preview(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.previewUrl = reader.result as string | ArrayBuffer;
    };
  }

  startUpload(): void {
    // Reference to storage bucket
    const ref = this.storage.ref(this.path);

    // The main task
    this.task = this.storage.upload(this.path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges() as Observable<number>;

    this.snapshot = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        if (this.paramDir === 'Users' && this.paramName.includes('PreCropped')) {
          // Save on users collection
          const uid = this.paramName.split('PreCropped')[0];
          const user = {
            photo: {
              imageUrl: this.downloadURL,
            },
          } as User;

          this.db.collection('users').doc(uid).set(user, { merge: true });
        } else if (this.paramDir.split('/').length === 2 && this.paramName.includes('coverImage')) {
          // Save on users collection
          this.db.doc(this.paramDir).set({ photo: { imageUrl: this.downloadURL } }, { merge: true });
        } else {
          // Save on articleData
          this.db.doc(this.paramDir).set(
            {
              images: {
                [this.file.name]: {
                  downloadURL: this.downloadURL,
                  path: this.path,
                  filename: this.filename,
                  position: 1000,
                },
              },
            },
            { merge: true },
          );
        }
        this.finished.emit(this.file);
      }),
    );
  }

  isActive(snapshot: any): boolean {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
