import { BaseResource, BaseSerializer, IBaseResource } from '../services/base.service';
import { ITripLocation, TripLocation } from './trips';

export interface IUserConnectOn extends IBaseResource {
  title: string;
  description?: string;
  userId: string;
  locationRadiusInKm?: number;
  location?: ITripLocation;
}

export class UserConnectOn extends BaseResource {
  title: string;
  description?: string;
  userId: string;
  locationRadiusInKm?: number;
  location?: TripLocation;

  constructor(obj: IUserConnectOn) {
    super(obj);
    this.title = obj.title;
    this.description = obj.description ?? '';
    this.userId = obj.userId;
    this.locationRadiusInKm = obj.locationRadiusInKm ?? 0;
    this.location = obj.location;
  }
}

export class UserConnectOnSerializer extends BaseSerializer<UserConnectOn> {
  fromJson(json: any): UserConnectOn {
    return new UserConnectOn(json);
  }

  toJson(resource: UserConnectOn): any {
    return {
      ...resource,
    } as IUserConnectOn;
  }
}