<form [formGroup]="form">
	<p>Document ID: {{articleData?.id}}</p>
	<div class="form-group row">
		<div class="col-sm-12">
			<input formControlName="title" type="text" nbInput fullWidth id="inputTitle" placeholder="Title">
		</div>
	</div>
	<div class="form-group row">
		<div class="col-sm-12">
			<label>Description</label>
			<ngx-tiny-mce #mceDescription></ngx-tiny-mce>
		</div>
	</div>
	<nb-tag-list (tagRemove)="onTagRemove($event)">
		<nb-tag *ngFor="let tag of tags" [text]="tag" removable></nb-tag>
		<input #tagInput nbTagInput type="text" (tagAdd)="onTagAdd($event)" [separatorKeys]=[13,32] placeholder="Target tags">
	</nb-tag-list>
	<app-mosaic-manage *ngIf="uploadFolder" [images]="articleData?.images || []" [uploadFolder]="uploadFolder" (onDelete)="delete($event)" (onDrop)="handleReorder($event)"></app-mosaic-manage>
	<div class="button-actions col-sm-12">
		<button nbButton type="button" (click)="saveChanges()" [disabled]="!form.valid" status="success" style="margin-right: 1rem">Save Changes</button>
		<button nbButton type="button" (click)="deleteArticleData()" status="danger" [disabled]="isLoading">
			<nb-icon *ngIf="isLoading" icon="clock"></nb-icon>{{isLoading ? "Deleting..." : "Delete Data"}}
		</button>
	</div>
	<hr />
</form>