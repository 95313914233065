<nb-card>
	<nb-card-header>
		Article Data Manager
		<div>
			<button nbButton ghost>
				<nb-icon icon="plus-outline" status="primary" (click)="openCreateModal()"></nb-icon>
			</button>
		</div>
	</nb-card-header>
	<nb-card-body>
		<label class="label" for="searchInput">Search:</label>
		<nb-tag-list (tagRemove)="onTagRemove($event)">
			<nb-tag *ngFor="let tag of tags" [text]="tag" removable></nb-tag>
			<input name="searchInput" #tagInput nbTagInput fullWidth type="text" (tagAdd)="onTagAdd($event)" [separatorKeys]=[13,32] placeholder="Tags to search">
		</nb-tag-list>
		<hr />
		<app-article-data *ngFor="let articleData of articleDataResults$ | async" [articleData]="articleData"></app-article-data>
	</nb-card-body>
</nb-card>