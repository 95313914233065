<div class="root">
    <div>
        <div class="name" *ngIf="name">{{name}}</div>
        <div *ngIf="!mandatory" class="optional">(optional)</div>
    </div>
    <nb-select [disabled]="disabled" [(selected)]="selectedDay" (selectedChange)="onDayChange($event)">
        <nb-option value="">Day</nb-option>
        <nb-option *ngFor="let a of createArrays(totalDay); let index = index" [value]="(index + 1).toString().padStart(2, '0')">
            {{(index + 1).toString().padStart(2, '0')}}
        </nb-option>
    </nb-select>
    <nb-select [disabled]="disabled" [(selected)]="selectedMonth" (selectedChange)="onMonthChange($event)">
        <nb-option value="">Month</nb-option>
        <nb-option *ngFor="let a of createArrays(12); let index = index" [value]="(index + 1).toString().padStart(2, '0')">
            {{moment().month(index).format("MMM")}}
        </nb-option>
    </nb-select>
</div>