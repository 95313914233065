<nb-card size="tiny">
	<nb-card-header>
		{{title || 'Title'}}
	</nb-card-header>
  <nb-card-body>
		<div [innerHTML]="(description || 'Description') | safeHtml"></div>
  </nb-card-body>
	<nb-card-footer>
		<button nbButton status="basic" (click)="cancel()">Cancel</button>
		<button nbButton [status]="confirmStatus || 'success'" (click)="confirm()">Confirm</button>
	</nb-card-footer>
</nb-card>