<nb-card>
	<nb-card-header>New Article Data</nb-card-header>
	<nb-card-body class="dialog-card">
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<div class="form-group row">
				<div class="col-12">
					<input formControlName="title" type="text" nbInput fullWidth id="inputTitle" placeholder="Title">
				</div>
			</div>
			<div class="form-group row">
				<div class="col-12">
					<textarea rows="5" formControlName="description" type="text" nbInput fullWidth id="inputDescription" placeholder="Description"></textarea>
				</div>
			</div>
			<div class="form-group row">
				<div class="col-12">
					<nb-tag-list (tagRemove)="onTagRemove($event)">
						<nb-tag *ngFor="let tag of tags" [text]="tag" removable></nb-tag>
						<input #tagInput nbTagInput type="text" (tagAdd)="onTagAdd($event)" [separatorKeys]=[13,32] placeholder="Tags">
					</nb-tag-list>
				</div>
			</div>
			<button nbButton type="submit" status="primary" [disabled]="form.invalid">Create</button>
		</form>
	</nb-card-body>
</nb-card>