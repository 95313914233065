import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  @Input() name?: string;
  @Input() initialValue?: string;
  @Input() value$: BehaviorSubject<string | null>;

  filePreview?: {
    src: SafeResourceUrl;
    filename: string;
  };

  constructor(
    private sanitizer: DomSanitizer,
  ) {
    if (!this.value$) {
      this.value$ = new BehaviorSubject<string | null>(null);
    }
  }

  ngOnInit(): void {
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    // console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // console.log(reader.result);

        if (reader.result && typeof reader.result === 'string') {
          this.filePreview = {
            src : this.sanitizer.bypassSecurityTrustResourceUrl(reader.result),
            filename: file.name
          };

          this.value$.next(reader.result);
        }
      };
    }
  }

  removeFile() {
    this.value$.next(null);
    this.filePreview = undefined;
  }

}
