<div class="root">
    <input
      (input)="onChange($event.target)"
      nbInput
      type="text"
      placeholder="+XX"
      [disabled]="disabled"
      [nbAutocomplete]="auto" />

      <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
        <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.value">
          {{ option.key }}
        </nb-option>
      </nb-autocomplete>
</div>