import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: [ './welcome-dialog.component.scss' ],
})
export class WelcomeDialogComponent {
  constructor(private router: Router) {}

  goToProfile(): Promise<boolean> {
    return this.router.navigate([ 'tumla-profile' ]);
  }
}
