import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { Yearbook } from 'src/app/models/yearbook';
import { YearbooksService } from 'src/app/services/yearbook.service';
import { CustomValidators } from 'src/app/validators/validators';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-yearbook-edit',
  templateUrl: './yearbook-edit.component.html',
  styleUrls: [ './yearbook-edit.component.scss' ],
})
export class YearbookEditComponent {
  yearbook$: Observable<Yearbook>;
  min: Date = new Date();
  isEditing = false;
  isSubmitted = false;

  form = this.fb.group({
    id: [ '', Validators.required ],
    name: [ '', Validators.required ],
    dueDate: [ '', [ Validators.required, CustomValidators.validateAfterToday ] ],
    userGroupId: [ '', Validators.required ],
  });

  constructor(
    private fb: FormBuilder,
    private yearbooksService: YearbooksService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
  ) {
    this.yearbook$ = this.yearbooksService.resource$ as Observable<Yearbook>;
    const yearbook = this.yearbooksService.resource;
    this.form.patchValue({ ...yearbook });
  }

  toggleEdit(): void {
    this.isEditing = !this.isEditing;
  }

  openLockYearbookDialog(): void {
    this.dialogService
      .open(ConfirmDialogComponent, {
        context: {
          title: 'Lock Yearbook?',
          description:
            'By locking the yearbook, no one else will be able to change their profile. Are you sure you want to proceed?',
          confirmStatus: 'danger',
        },
      })
      .onClose.subscribe((hasConfirmed) => {
        if (hasConfirmed) {
          this.lockYearbook();
        }
      });
  }

  async onSubmit(): Promise<void> {
    try {
      this.isSubmitted = true;
      if (this.form.valid) {
        const yearbook = new Yearbook({ ...this.form.value });
        await this.yearbooksService.update(yearbook);
        this.toastrService.success('Your yearbook was successfully saved!', 'Saved!');
      }
    } catch (e) {
      this.toastrService.danger(e.toString(), 'Error');
    } finally {
      this.isSubmitted = false;
      this.toggleEdit();
    }
  }

  async lockYearbook(): Promise<any> {
    try {
      this.isSubmitted = true;
      await this.yearbooksService.lockYearbook();
      this.toastrService.success('Your yearbook was successfully locked!', 'Locked!');
    } catch (e) {
      this.toastrService.danger(e.toString(), 'Error');
    } finally {
      this.isSubmitted = false;
      this.toggleEdit();
    }
  }
}
