import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/user.service';

@Component({
  selector: 'app-favorite-button',
  templateUrl: './favorite-button.component.html',
  styleUrls: [ './favorite-button.component.scss' ],
})
export class FavoriteButtonComponent {
  @Input() user: User;

  setFavorite(loggedUser: User, targetId: string, value: boolean): Promise<void> {
    if (value) {
      loggedUser.favorites = [ ...loggedUser.favorites, targetId ];
    } else {
      const index = loggedUser.favorites.indexOf(targetId);
      loggedUser.favorites = [
        ...loggedUser.favorites.slice(0, index),
        ...loggedUser.favorites.slice(index + 1, loggedUser.favorites.length),
      ];
    }
    return this.userService.setFavorites(loggedUser);
  }

  constructor(public authService: AuthService, private userService: UsersService) {}
}
