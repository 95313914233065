<nb-accordion id="hashtagsCard" *ngIf="hashtagsWithCount$ | async as hashtagsWithCount">
  <nb-accordion-item>
    <nb-accordion-item-header>
      <div>Tags</div>
    </nb-accordion-item-header>

    <nb-accordion-item-body id="hashtagsHeader">
      <div id="hashtagsButtons">
        <div *ngFor="let tagWithCount of hashtagsWithCount">
          <div *ngIf="tagWithCount.count > 0" [ngClass]="tagWithCount.count > 0 ? 'hashtagsButtons' : 'null'">
            <button *ngIf="!tagWithCount.tag.includes(' ') && tagWithCount.tag.length > 0" [size]="size" (click)="filterTag(tagWithCount.tag)"
              [ngClass]="isSelected(tagWithCount.tag) ? 'selectedTag' : 'null'" [disabled]="tagWithCount.count === 0"
              nbButton size="small">
              {{ tagWithCount.tag + " (" + tagWithCount.count + ")" }}
            </button>
          </div>
        </div>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>