<nb-card *ngIf="groupsStatus$ | async as groupsStatus" class="list-card">
	<nb-card-header>
		Class Requests
	</nb-card-header>
	<nb-card-body>
		<nb-list>
			<nb-list-item *ngFor="let groupStatus of groupsStatus">
				<p>{{groupStatus.name}}</p>
				<p [ngClass]="getCssClassName(groupStatus.status)">{{groupStatus.status}}</p>
			</nb-list-item>
		</nb-list>
	</nb-card-body>
	<nb-card-footer>
		Please wait for the class organizer to approve your request
	</nb-card-footer>
</nb-card>