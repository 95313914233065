import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { take } from 'rxjs/operators';
import { ArticleData } from 'src/app/models/article-data';
import { Comment } from 'src/app/models/comment';
import { User } from 'src/app/models/user';
import { AuthService, CLASS_ORGANIZER } from 'src/app/services/auth.service';
import { YearbooksService } from 'src/app/services/yearbook.service';
import * as moment from 'moment';

@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss'],
})
export class WallComponent implements OnInit {
  private USER_UID_LENGTH = 28;
  @Input() articleData: ArticleData;
  @Input() comments: Comment[] = [];
  @Input() showSeeAllButton = true;
  @Input() placeholderText: string;
  currentUser: User;
  moment = moment;

  form = this.fb.group({
    content: ['', Validators.required],
  });

  constructor(
    private authService: AuthService,
    private yearbooksService: YearbooksService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentUser = (await this.authService.user$.pipe(take(1)).toPromise()) as User;
    if (this.articleData) {
      this.comments = this.articleData.lastComments || [];
    }
  }

  seeAll(): Promise<boolean> {
    // TODO: Remove this hack
    const articleDataId =
      (this.articleData && this.articleData.id) || this.router.url.split('profile/')[1].split('/').shift() || '';
    if (articleDataId.length === this.USER_UID_LENGTH) {
      return this.router.navigate(['wall'], { relativeTo: this.route });
    } else {
      return this.router.navigate(['profile', articleDataId, 'wall'], {
        relativeTo: this.route,
      });
    }
  }

  showDeleteButton(comment: Comment): boolean {
    const userGroupId = this.yearbooksService.resource.userGroupId;
    return (
      this.currentUser &&
      (this.currentUser.id === comment.userId ||
        this.currentUser.classStudentPermission[userGroupId] === CLASS_ORGANIZER)
    );
  }

  async onSubmit(): Promise<void> {
    try {
      const user = (await this.authService.user$.pipe(take(1)).toPromise()) as User;
      const yearbookId = this.router.url.split('yearbooks/')[1].split('/').shift() || '';
      const articleDataId =
        (this.articleData && this.articleData.id) || this.router.url.split('profile/')[1].split('/').shift() || '';

      const comment = new Comment({
        userId: user.id,
        userName: user.name,
        userThumb: user.photo.thumbUrl,
        content: this.form.value.content,
      });

      if (this.form.valid) {
        this.form.patchValue({ content: '' });
        await this.yearbooksService.createComment(yearbookId, articleDataId, comment);
        this.toastrService.success('Your message was posted on the wall', 'Saved!');
        this.comments.unshift(comment);
      }
    } catch (e) {
      this.toastrService.danger((e as any).toString(), 'Error');
    }
  }

  async onDelete(comment: Comment): Promise<void> {
    try {
      const yearbookId = this.router.url.split('yearbooks/')[1].split('/').shift() || '';
      const articleDataId =
        (this.articleData && this.articleData.id) || this.router.url.split('profile/')[1].split('/').shift() || '';
      await this.yearbooksService.deleteComment(yearbookId, articleDataId, comment);
      this.toastrService.success('Your message was deleted from the wall', 'Saved!');
      const removedIndex = this.comments.findIndex((c) => c.id === comment.id);
      this.comments = [...this.comments.slice(0, removedIndex), ...this.comments.slice(removedIndex + 1)];
    } catch (e) {
      this.toastrService.danger((e as any).toString(), 'Error');
    }
  }

  goToProfile(userId: string): Promise<boolean> {
    return this.router.navigate(['../../', userId], { relativeTo: this.route });
  }
}
