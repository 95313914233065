<nb-card>
  <nb-card-body class="dialog-card">
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<div class="form-group row">
				<div class="col-12">
					<input formControlName="name" type="text" nbInput fullWidth id="inputTitle" placeholder="Name">
				</div>
			</div>
			<div class="form-group row">
				<div class="col-12">
					<input formControlName="dueDate" nbInput fullWidth placeholder="Due Date" [nbDatepicker]="picker">
					<nb-datepicker [min]="min" #picker></nb-datepicker>
				</div>
			</div>
			<div class="form-group row">
				<div class="col-12">
					<nb-select *ngIf="userGroups$ | async as userGroups" placeholder="Class" fullWidth formControlName="userGroupId">
						<nb-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">{{userGroup.name}}</nb-option>
					</nb-select>
				</div>
			</div>
			<button nbButton type="submit" status="primary" [disabled]="form.invalid || this.isLoading">{{this.isLoading ? 'Creating...' : 'Submit'}}</button>
		</form>
  </nb-card-body>
</nb-card>