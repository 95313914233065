import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NbComponentSize, NbTrigger } from '@nebular/theme';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Link } from 'src/app/models/link';
import { LinkCategory } from 'src/app/models/link-category';
import { LinkStoreService } from 'src/app/services/link-store.service';

@Component({
  selector: 'app-links-category-tab',
  templateUrl: './links-category-tab.component.html',
  styleUrls: [ './links-category-tab.component.scss' ],
  animations: [
    trigger('popOverFilters', [
      state('showFilters', style({ transform: 'scale(1)' })),
      state('hideFilters', style({ transform: 'scale(0)', height: 0, border: 0, margin: 0, padding: 0 })),
      state('showSearch', style({ position: 'fixed', top: '77px', right: '0px', opacity: 0.75 })),
      state('hideSearch', style({ position: 'fixed', top: '-20px', right: '0px', opacity: 0 })),
      transition('showFilters => hideFilters', animate('600ms ease-out')),
      transition('hideFilters => showFilters', animate('500ms ease-in')),
      transition('showSearch => hideSearch', animate('600ms ease-out')),
      transition('hideSearch => showSearch', animate('500ms ease-in')),
    ]),
  ],
})
export class LinksCategoryTabComponent implements OnInit {
  @Input() allFilteredLinks$: Observable<Link[]>;
  @Input() category: LinkCategory;
  @Input() filterDropdown = false;
  @Input() size: NbComponentSize;
  @Input() trigger: NbTrigger;

  categoryLinks$: Observable<Link[]>;
  displayOthers$: Observable<boolean>;
  filteredCategoryLinks$: Observable<Link[]>;
  hashtagFilter$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  hasUnapprovedLinks$: Observable<boolean>;
  linksWithoutSubcategory$: Observable<Link[]>;
  linksWithSubcategory$: Observable<Link[]>;
  maximumLinkCount$: Observable<number>;
  showSubCats = true;
  subCategories$: Observable<string[]>;
  unapprovedLinks$: Observable<Link[]>;

  constructor() {}

  ngOnInit(): void {
    if (!this.category) {
      throw new Error('category must not be null');
    }

    if (!this.allFilteredLinks$) {
      throw new Error('category must not be null');
    }

    this.categoryLinks$ = this.allFilteredLinks$.pipe(
      map((links) => {
        // Get all links for the category
        if (this.category.id === 'All') {
          return links;
        } else {
          return links.filter((link) => link.categories.includes(this.category.id));
        }
      }),
    );

    this.filteredCategoryLinks$ = combineLatest([ this.categoryLinks$, this.hashtagFilter$ ]).pipe(
      map(([ links, hashtagFilter ]) => {
        // Filter tags
        if (hashtagFilter.length === 0) {
          return links;
        } else {
          return links.filter((link) => hashtagFilter.every((filter) => link.tags.includes(filter)));
        }
      }),
    );

    this.maximumLinkCount$ = this.categoryLinks$.pipe(map((links) => links.length));

    this.unapprovedLinks$ = this.filteredCategoryLinks$.pipe(map((links) => links.filter((link) => !link.approved)));

    this.hasUnapprovedLinks$ = this.unapprovedLinks$.pipe(map((links) => links.length > 0));

    this.linksWithoutSubcategory$ = this.filteredCategoryLinks$.pipe(
      map((links) => {
        let availableSubCats = this.category.subCategories;

        return links.filter((link) => {
          const subCatObj = link.subCategories || {};

          if (Object.keys(subCatObj).length === 0) {
            return true;
          } else {
            return !(subCatObj[this.category.id] && availableSubCats.includes(subCatObj[this.category.id]));
          }
        });
      }),
    );

    this.linksWithSubcategory$ = this.filteredCategoryLinks$.pipe(
      map((links) => {
        let availableSubCats = this.category.subCategories;

        return links.filter((link) => {
          const subCatObj = link.subCategories || {};

          if (Object.keys(subCatObj).length === 0) {
            return false;
          } else {
            return subCatObj[this.category.id] && availableSubCats.includes(subCatObj[this.category.id]);
          }
        });
      }),
    );

    this.subCategories$ = this.filteredCategoryLinks$.pipe(
      map((links) => {
        let availableSubCats = this.category.subCategories;

        return availableSubCats.filter((subCat) =>
          links.some((link) => link.subCategories && link.subCategories[this.category.id] === subCat),
        );
      }),
    );

    this.displayOthers$ = this.linksWithoutSubcategory$.pipe(map((links) => links.length > 0));
  }

  scrollToSubCat(target: any) {
    target = document.getElementById(target);
    var scrollContainer = target;
    do {
      scrollContainer = scrollContainer.parentNode;
      if (!scrollContainer) return;
      scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    var targetY = 0;
    do {
      if (target == scrollContainer) break;
      targetY += target.offsetTop;
    } while ((target = target.offsetParent));

    function scroll(c: any, a: number, b: number, i: number) {
      i++;
      if (i > 30) return;
      c.scrollTop = a + (b - a) / 30 * i;
      scroll(c, a, b, i);
    }

    const headerHeight = 76;
    const filtersHeight = this.filterDropdown ? 90 : 0;
    const divHeight = 50;
    const takeOff = headerHeight + filtersHeight + divHeight;
    scroll(scrollContainer, scrollContainer.scrollTop, targetY - takeOff, 0);
  }

  handleOnTagChanged(event: string[]) {
    this.hashtagFilter$.next(event);
  }
}
