import { Component, Input, OnInit } from '@angular/core';
import { NbComponentSize, NbDialogService, NbTrigger } from '@nebular/theme';
import { AddLinkDialogComponent } from 'src/app/links/add-link-dialog/add-link-dialog.component';
import { ReorderSubCatsComponent } from 'src/app/links/reorder-sub-cats/reorder-sub-cats.component';
import { Link } from 'src/app/models/link';
import { LinkCategory } from 'src/app/models/link-category';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-link-reorder-buttons',
  templateUrl: './link-reorder-buttons.component.html',
  styleUrls: [ './link-reorder-buttons.component.scss' ],
})
export class LinkReorderButtonsComponent implements OnInit {
  @Input() trigger: NbTrigger;
  @Input() size: NbComponentSize;
  @Input() category: LinkCategory;
  @Input() links: Link[];

  hasSubCats = false;

  constructor(private dialogService: NbDialogService, private authService: AuthService) {}

  ngOnInit(): void {
    if (!this.category) {
      throw new Error('category must not be null');
    }
    if (!this.trigger) {
      throw new Error('trigger must not be null');
    }
    if (!this.links) {
      throw new Error('links must not be null');
    }

    this.hasSubCats = this.category.subCategories.length > 0;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  reorderSubCats(): void {
    this.dialogService.open(ReorderSubCatsComponent, {
      context: {
        category: this.category,
        links: this.links,
        trigger: this.trigger,
      },
      closeOnBackdropClick: false,
    });
  }

  addLink(): void {
    this.dialogService.open(AddLinkDialogComponent, {
      context: {
        trigger: this.trigger,
      },
      closeOnBackdropClick: false,
    });
  }

  get isAdmin(): boolean {
    if (!this.authService.user) {
      return false;
    }

    return this.category.admins.includes((this.authService.user as User).id);
  }
}
