import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, mergeAll, switchMap } from 'rxjs/operators';
import { IUser, User, UserSerializer } from '../models/user';
import { IUserGroup } from '../models/user-group';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';
import * as firebase from 'firebase/app';
import { AxiosNestService } from './axios-nest.service';
import * as _ from 'lodash';
import { capitalizeFirstLetter } from '../common/helper';
import { IUserConnectOn, UserConnectOn } from '../models/user-connect-on';
import { IUserContact, UserContact } from '../models/user-contact';

export enum ContactInfoTypes {
  email = 'Email',
  custom = 'Custom',
  insead = 'Insead',
  instagram = 'Instagram',
  linkedIn = 'Linked In',
  mobile = 'Mobile',
  skype = 'Skype',
  telegram = 'Telegram',
  twitter = 'Twitter',
  whatsApp = 'WhatsApp',
  facebook = 'Facebook',
  facetime = 'Facetime',
  gmail = 'Gmail',
}

export interface BookmarksNotesTypes {
  content?: string;
  created?: any;
  updated?: any;
  id?: string;
  ownerId?: string;
  userId?: string;
  deleted?: boolean;
}

export enum UserRole {
  student = 'student',
  classOrganizer = 'classOrganizer',
}

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService<User> {
  constructor(client: AngularFirestore, private authService: AuthService, private axiosNest: AxiosNestService) {
    super('/users', client, new UserSerializer());
  }

  getByEmail(email: string): Observable<User> {
    return this.afs
      .collection<IUser>('users', (ref) => ref.where('email', '==', email))
      .valueChanges()
      .pipe(
        map((user) => {
          return new User(user[0]);
        })
      );
  }

  getByTelegramId(telegramId: number): Observable<User|undefined> {
    return this.afs
      .collection<IUser>('users', (ref) => ref.where('telegramId', '==', telegramId))
      .valueChanges()
      .pipe(
        map((user) => {
          if (user.length == 0) {
            return undefined;
          }
          return new User(user[0]);
        })
      );
  }

  getApprovedUserGroups(userId: string): Observable<IUserGroup[]> {
    if (!userId) {
      return of([]);
    }
    return this.afs
      .collection('/user_groups', (ref) => ref.where('users', 'array-contains', userId))
      .get()
      .pipe(map((snap) => snap.docs.map((doc) => doc.data() as IUserGroup)));
  }

  getUserGroups(): Observable<{ name: string; status: string }[]> {
    return this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return [];
        }
        const groupIds = Object.keys(user.userGroupsStatus);
        if (groupIds.length === 0) {
          return [];
        }
        return this.afs
          .collection('/user_groups', (ref) =>
            ref.where(firebase.default.firestore.FieldPath.documentId(), 'in', groupIds),
          )
          .get()
          .pipe(
            map((snap) => snap.docs.map((doc) => doc.data() as IUserGroup)),
            map((userGroups) =>
              userGroups.map((group) => ({
                name: group.name,
                status: user.userGroupsStatus[group.id],
              })),
            ),
          );
      }),
    );
  }

  getContactInfo(userId: string): Observable<{ [key in ContactInfoTypes]?: string[] }> {
    return this.afs
      .collection('/users')
      .doc(userId)
      .collection('contacts')
      .valueChanges()
      .pipe(map((contacts) => {
        var result: {[key in ContactInfoTypes]?: string[]} = {};

        // Grouping by 'type'
        contacts.forEach((contact) => {
          const typeString = contact['type'] as string;
          const type = ContactInfoTypes[typeString as keyof typeof ContactInfoTypes];
          
          if (!result[type])
            result[type] = [];

          result[type]!.push(contact['value'] as string);
        });

        return result;
      }));
  }

  updateContactInfo(userId: string, contactInfo: { [key in ContactInfoTypes]?: string[] }): Promise<void> {
    if (!userId) {
      return Promise.reject('Function called with no userId');
    }

    // Note: outdated
    // return this.afs
    //   .collection('/users')
    //   .doc(userId)
    //   .collection('private')
    //   .doc('contact_info')
    //   .set(contactInfo, { merge: true });

    return Promise.resolve();
  }

  getBookmarks(profileId: string): Observable<BookmarksNotesTypes[]> {
    return this.afs.collection('/users').doc(profileId).collection<BookmarksNotesTypes>('bookmarks').valueChanges();
  }

  upsertBookmark(bookmark: BookmarksNotesTypes) {
    const docId = bookmark.id ? bookmark.id : this.afs.createId();
    bookmark.id = docId;
    return this.afs.collection('/users').doc(bookmark.ownerId).collection('bookmarks').doc(docId).set(bookmark);
  }

  getPersonalNotes(profileId: string): Observable<BookmarksNotesTypes[]> {
    return this.afs
      .collection('/users')
      .doc(profileId)
      .collection<BookmarksNotesTypes>('personal_notes')
      .valueChanges();
  }

  updatePersonalNote(profileId: string, note: BookmarksNotesTypes): Promise<void> {
    if (!profileId) {
      return Promise.reject('Function called with no userId');
    }
    return this.afs
      .collection('/users')
      .doc(profileId)
      .collection<BookmarksNotesTypes>('personal_notes')
      .doc(note.id)
      .set(note, { merge: true });
  }

  newPersonalNote(note: BookmarksNotesTypes) {
    const docId = this.afs.createId();
    note.id = docId;

    return this.afs.collection('/users').doc(note.ownerId).collection('personal_notes').doc(docId).set(note);
  }

  setFavorites(user: User): Promise<void> {
    if (!user) {
      return Promise.reject('Function called with no user');
    }
    return this.afs
      .collection('/users')
      .doc(user.id)
      .set(this.serializer.toJson(user), { mergeFields: ['dyFavorites'] });
  }

  approveUser(user: User, role: UserRole, userGroup: string): Promise<any> {
    return this.axiosNest.post('shouldApproveUser', {
      userId: user.id,
      role,
      groupId: userGroup,
      shouldApprove: true,
    });
  }

  rejectUser(user: User, userGroup: string): Promise<void> {
    return this.axiosNest.post('shouldApproveUser', {
      userId: user.id,
      groupId: userGroup,
      shouldApprove: false,
    });
  }

  profileUpdate(user: User): Promise<any> {
    return this.axiosNest.post('profileUpdate', new UserSerializer().toJson(user));
  }

  profileDisplay(email: string, slug: string): Observable<any> {
    return this.axiosNest.postObservable('profileDisplay', { targetUserEmail: email, slug: slug });
  }

  profileList(slug: string): Observable<any[]> {
    return this.axiosNest.postObservable('profileList', { slug });
  }

  getUsersFromUserGroup(userGroupId: string): Observable<User[]> {
    if (!userGroupId) {
      return of([]);
    }

    return this.afs.collection('users', (ref) => ref.where(`userGroupsStatus.${userGroupId}`, '==', 'approved'))
      .get()
      .pipe(map((snap) => snap.docs.map((doc) => new User(doc.data() as IUser))));
  }

  getVisibleUsers(userId: string): Observable<User[]> {
    if (!userId) {
      return of([]);
    }

    return this.getApprovedUserGroups(userId).pipe(map((approvedUserGroups) => {
      const usersObservables = approvedUserGroups.map((approvedUserGroup) => this.getUsersFromUserGroup(approvedUserGroup.id));
      const flattendUsersObsvs = combineLatest(usersObservables).pipe(
        map((users) => {
          console.log('Getting visible users...');
          return _.uniqBy(users.flat(), (t) => t.id);
        })
      );
      return flattendUsersObsvs;
    }), mergeAll());
  }
  
  async quickUpdate(userId: string, data: {[key: string]: any}): Promise<void> {
    const userDocQuery = this.afs.collection(`users`).doc(userId);

    await userDocQuery.update(data);
  }

  getContacts(userId: string): Observable<UserContact[]> {
    return this.afs
      .collection<IUserContact>(`users/${userId}/contacts`)
      .valueChanges()
      .pipe(
        map((items) => {
          if (items.length == 0) {
            return [];
          }
          return items.map((item) => new UserContact(item));
        })
      );
  }

  getConnectOns(userId: string): Observable<UserConnectOn[]> {
    return this.afs
      .collection<IUserConnectOn>(`users/${userId}/connect_on`)
      .valueChanges()
      .pipe(
        map((items) => {
          if (items.length == 0) {
            return [];
          }
          return items.map((item) => new UserConnectOn(item));
        })
      );
  }

}
