<div class="addReorderLink">
	<div id="bottomRightButton" class="opaqueIcon">
		<button nbTooltip="Scroll to the top" [nbTooltipTrigger]="trigger" nbButton [size]="size" status="danger" style="margin-bottom: 2px" (click)="scrollToTop()">
			<nb-icon status="control" icon="arrow-upward-outline"></nb-icon>
		</button>
		<button nbTooltip="Manage Sub Categories" [nbTooltipTrigger]="trigger" [size]="size" *ngIf="hasSubCats && isAdmin" (click)="reorderSubCats()" nbButton status="danger">
			<nb-icon status="control" icon="edit-2-outline"></nb-icon>
		</button>
	</div>
	<button id="bottomLeftButton" [nbTooltip]="category.id === 'All' ? 'Add/Suggest Link': isAdmin ? 'Add Link' : 'Suggest Link'" [nbTooltipTrigger]="trigger" [size]="size" id="bottomLeftButton" class="opaqueIcon" nbButton status="danger" (click)="addLink()">
		<nb-icon status="control" icon="plus-outline"></nb-icon>
	</button>
</div>