import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropzoneDirective } from './dropzone.directive';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { NbButtonModule, NbIconModule } from '@nebular/theme';
import { ProfilePhotoUploaderComponent } from './profile-photo-uploader/profile-photo-uploader.component';
import { YearbookPhotoUploaderComponent } from './yearbook-photo-uploader/yearbook-photo-uploader.component';

@NgModule({
  declarations: [
    DropzoneDirective,
    FileManagerComponent,
    UploadTaskComponent,
    ProfilePhotoUploaderComponent,
    YearbookPhotoUploaderComponent,
  ],
  imports: [CommonModule, NbButtonModule, NbIconModule],
  exports: [
    FileManagerComponent,
    ProfilePhotoUploaderComponent,
    YearbookPhotoUploaderComponent,
  ],
})
export class UploaderModule {}
