import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
  Params,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map, tap } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private toastrService: NbToastrService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.user$.pipe(
      take(1),
      tap(async (user) => {
        this.auth.user = user;
      }),
      map((user) => !!user),
      tap(async (loggedIn) => {
        if (!loggedIn) {
          // Check if is trying to access yearbooks page
          let yearbookId = '';
          const parts = window.location.toString().split('/yearbooks/');
          if (parts.length === 2) {
            const lastPart = parts.pop() || '';
            yearbookId = lastPart.split('/')[0];
          }

          const previousUrl = window.location.pathname + window.location.search;

          const queryParams: Params = {
            yearbookId: yearbookId,
            previousUrl: previousUrl
          };

          var currentParams = new URLSearchParams(window.location.search);
          currentParams.forEach((value, key) => {
            queryParams[key] = value;
          });
          
          // console.log(queryParams);

          // Route back to login
          await this.router.navigate(['/auth/login'], { queryParams: queryParams, queryParamsHandling: 'merge' });
          if (queryParams['key'] && queryParams['email']) {
            this.toastrService.info('You are being auto logged-in', 'Please wait');
          } else {
            this.toastrService.danger('You must be logged in to see this page', 'Sorry');
          }
        }
      }),
    );
  }
}
