export interface IDYAsset {
  description: 'photo' | 'title' | 'description' | 'subtitle';
  minHeight: number;
  minWidth: number;
  name: string;
  submittedContent?: any;
  type: 'image' | 'text' | 'mosaic';
}

export class DYAsset implements IDYAsset {
  description: 'photo' | 'title' | 'description' | 'subtitle';
  minHeight: number;
  minWidth: number;
  name: string;
  submittedContent: any;
  type: 'image' | 'text' | 'mosaic';

  constructor(obj: IDYAsset) {
    this.type = obj.type;
    this.name = obj.name;
    this.description = obj.description;
    this.minWidth = obj.minWidth;
    this.minHeight = obj.minHeight;
    this.submittedContent = obj.submittedContent || null;
  }
}

export class DYAssetSerializer {
  static fromJson(json: any): DYAsset {
    return new DYAsset(json);
  }

  static toJson(resource: DYAsset): any {
    return {
      ...resource,
    };
  }
}
