import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  @Input() name?: string;
  @Input() value$: BehaviorSubject<string>;
  @Input() placeholder: string;
  @Input() rows: string = '6';
  @Input() disabled = false;

  constructor() {
    if (!this.value$) {
      this.value$ = new BehaviorSubject('');
    }
  }

  ngOnInit(): void {
    this.placeholder = this.placeholder ?? '';
  }

  onValueChange(event: any): void {
    this.value$.next(event.value);
  }

}
