import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeAll, switchMap, take } from 'rxjs/operators';
import { ILink, Link, LinkSerializer } from '../models/link';
import { ILinkCategory, LinkCategory, LinkCategorySerializer } from '../models/link-category';
import { AxiosNestService } from './axios-nest.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor(protected afs: AngularFirestore, private axiosNest: AxiosNestService, private authService: AuthService) {}

  getLinkCategories(params: object = {}): Observable<LinkCategory[]> {
    return this.axiosNest
      .postObservable('getChatGroupCategories', { params })
      .pipe(map((response) => {
        console.log('Getting chat group categories...')
        return response.map((linkCategory: any) => LinkCategorySerializer.fromJson(linkCategory));
      }));
  }

  getLinks(params: object = {}): Observable<Link[]> {
    return this.axiosNest.postObservable('getChatGroups', params).pipe(
      map((links) => {
        console.log('Getting chat groups...');
        return links.map((link: Link) => {
          return new LinkSerializer().fromJson(link);
        });
      }),
    );
  }

  getLinksDirectly(): Observable<Link[]> {
    return this.getLinkCategoriesDirectly().pipe(take(1), switchMap((linkCategories) => {
      const obsves = linkCategories.map((linkCategory) => {
        return this.afs
          .collection<ILink>('chat_groups', ref =>
            ref.where('categories', 'array-contains', linkCategory.id).where('deleted', '==', false))
          .valueChanges()
          .pipe(
            map((links) => {
              console.log('Getting chat groups...');
              return links.map((link) => new Link(link));
            })
          );
      });


      const combined = combineLatest(obsves).pipe(mergeAll());
      return combined;
    }));
  }

  getAllLinks(): Observable<Link[]> {
    return this.afs
      .collection<ILink>('chat_groups', ref =>
        ref.where('deleted', '==', false)
          .where('is_info', '==', false))
      .valueChanges()
      .pipe(
        map((links) => {
          console.log('Getting all chat groups...');
          return links.map((link) => new Link(link));
        })
      );
  }

  getLinksByCategory(linkCategoryId: string, startAt: string | undefined = undefined, size: number = 20): Observable<Link[]> {
    const params = {
      chatGroupCategoryId: linkCategoryId,
      startAt: startAt,
      size: size,
    }
    return this.axiosNest.postObservable('getChatGroupsByCategory', params).pipe(
      map((links) => {
        console.log('Getting chat groups...');
        return links.map((link: Link) => {
          return new LinkSerializer().fromJson(link);
        });
      }),
    );
  }

  getLinksByCategoryDirectly(linkCategoryId: string, startAt?: string, size?: number): Observable<Link[]> {
    let query = this.afs
      .collection<ILink>('chat_groups', ref =>
        ref.where('categories', 'array-contains', linkCategoryId).where('deleted', '==', false)
          .orderBy('title', 'asc'));

    if (size) {
      query = this.afs
        .collection<ILink>('chat_groups', ref =>
          ref.where('categories', 'array-contains', linkCategoryId).where('deleted', '==', false)
            .orderBy('title', 'asc')
            .startAfter(startAt ?? 0)
            .limit(size));
    }
    
    return query
    .valueChanges()
    .pipe(
      map((links) => {
        console.log('Getting chat groups...');
        return links.map((link) => new Link(link));
      })
    );
  }

  getLinkCategoriesDirectly(): Observable<LinkCategory[]> {
    return this.authService.user$.pipe(switchMap((user) => {
      if (user) {
        return this.afs
          .collection<ILinkCategory>('chat_group_categories', ref => ref
            .where("classes", "array-contains-any", user.approvedUserGroupIds)
          )
          .valueChanges()
          .pipe(
            map((categories) => {
              console.log('Getting chat group categories...')
              return categories.map((category) => new LinkCategory(category));
            })
          );
      } else {
        return of([]);
      }
    }));
  }

  upsertLink(params: object = {}): Observable<Link> {
    return this.axiosNest
      .postObservable('upsertChatGroup', params)
      .pipe(map((link) => new LinkSerializer().fromJson(link)));
  }

  shouldApproveLink(params: object = {}): Observable<Link> {
    return this.axiosNest
      .postObservable('shouldApproveLink', params)
      .pipe(map((link) => new LinkSerializer().fromJson(link)));
  }

  updateCategory(category: object, catId: string): Promise<void> {
    return this.afs.collection('chat_group_categories').doc(catId).set(category, { merge: true });
  }

  updateSubCategory(subCategories: string[], catId: string): Promise<void> {
    return this.afs.collection('chat_group_categories').doc(catId).set({ subCategories }, { merge: true });
  }

  getChatGroupCategoryAdmins(params: object): Observable<any> {
    return this.axiosNest.postObservable('getChatGroupCategoryAdmins', params).pipe(map((admins) => {
      console.log('Getting chat group category admin...');
      return admins;
    }));
  }

  getChatGroupCategoryAdminsDirectly(chatGroupCategoryId: string): Observable<string[]> {
    return this.afs
      .collection<any>(`chat_group_categories/${chatGroupCategoryId}/permissions`)
      .doc('admins')
      .snapshotChanges()
      .pipe(
        map((snap) => {
          console.log('Getting chat group category admin...');
          const data = snap.payload.data();
          if (data) {
            return data['ids'] ?? [];
          }
          return [];
        })
      );
  }

  get(id: string): Observable<Link|undefined> {
    return this.afs
      .doc<ILink>(`chat_groups/${id}`)
      .valueChanges()
      .pipe(
        map((chat_group) => {
          if (!chat_group) {
            return undefined;
          }
          return new Link(chat_group);
        })
      );
  }

  getByGroupChatId(groupChatId: number): Observable<Link|undefined> {
    return this.afs
      .collection<ILink>('chat_groups', (ref) => ref.where('groupChatId', '==', groupChatId))
      .valueChanges()
      .pipe(
        map((chat_groups) => {
          if (chat_groups.length == 0) {
            return undefined;
          }
          return new Link(chat_groups[0]);
        })
      );
  }

  telegramCreateGroup(data: object = {}): Observable<any> {
    return this.axiosNest
      .postObservable('telegram/createGroup', data)
      .pipe(map((response) => {
        return response;
      }));
  }

  telegramUpdateGroup(data: object = {}): Observable<any> {
    return this.axiosNest
      .postObservable('telegram/updateGroup', data)
      .pipe(map((response) => {
        return response;
      }));
  }
}
