<nb-card>
  <nb-card-header>
    <div>Reorder Sub Categories of {{ category.name }}</div>

    <button nbTooltip="Close Dialog" [nbTooltipTrigger]="trigger" (click)="close()" ghost status="danger" nbButton>
      <nb-icon icon="close-circle-outline"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body>
    <nb-card *ngFor="let subCat of category.subCategories">
      <nb-card-body class="subCat">
        <button (click)="reorder(subCat, 'up')" ghost nbButton>
          <nb-icon icon="arrowhead-up-outline"></nb-icon>
        </button>
        <div>{{ subCat }} ({{ linksInSubCat(subCat, category.id) }})</div>
        <div>
          <button (click)="reorder(subCat, 'down')" ghost nbButton>
            <nb-icon icon="arrowhead-down-outline"></nb-icon>
          </button>
          <button (click)="deleteSubCat(subCat)" nbTooltip="Delete Sub Category" [nbTooltipTrigger]="trigger" status="danger" nbButton>
            <nb-icon icon="trash-outline"></nb-icon>
          </button>
        </div>
      </nb-card-body>
    </nb-card>
    <div *ngIf="newSubCat" id="newSubCat">
      <input (input)="newSubCatChange($event)" nbInput />
      <button [disabled]="newSubCatValue === ''" nbTooltip="Save new Sub Category" [nbTooltipTrigger]="trigger" status="danger" nbButton (click)="saveNewSubCat()">
        <nb-icon icon="checkmark-outline"></nb-icon>
      </button>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button [nbSpinner]="isLoading" (click)="submitReorder()" nbButton status="danger" [disabled]="isSameSubCategories">
      Save
    </button>
    <button (click)="newSubCat = true" nbTooltip="Add new Sub Category" [nbTooltipTrigger]="trigger" status="danger" nbButton>
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </nb-card-footer>
</nb-card>