<div class="root">
    <div class="name" *ngIf="name">{{name}}</div>
    <nb-form-field style="width: 100%;">
        <input nbInput fullWidth [disabled]="disabled" [value]="value$.value" (input)="onValueChange($event.target)" [placeholder]="placeholder" [fieldSize]="fieldSize"
            [required]="required" [pattern]="pattern" />
        <nb-icon *ngIf="icon" nbPrefix [icon]="icon"></nb-icon>

        <button *ngIf="!disabled && value$.value.length > 0" nbSuffix nbButton ghost (click)="clearText()">
            <nb-icon icon="close-outline"></nb-icon>
        </button>
    </nb-form-field>
</div>