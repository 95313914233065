import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Trip } from '../models/trips';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class VisibleDataService {
  // set on AuthGuard
  // if value == null meaning it's not initialized yet
  visibleUsers$: BehaviorSubject<User[]|null>;
  visibleTrips$: BehaviorSubject<Trip[]|null>;

  constructor() {
    this.visibleUsers$ = new BehaviorSubject<User[]|null>(null);
    this.visibleTrips$ = new BehaviorSubject<Trip[]|null>(null);
  }

  clearAllData() {
    this.visibleUsers$.next(null);
    this.visibleTrips$.next(null);
  }
}
