<nb-card>
  <nb-card-header>
    <div>Add/Suggest a new link</div>
    <button nbTooltip="Close Dialog" [nbTooltipTrigger]="trigger" (click)="close()" ghost status="danger" nbButton>
      <nb-icon icon="close-circle-outline"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body>
    <form style="max-width: 450px" class="form" [formGroup]="form">
      <input style="max-width: 450px" formControlName="title" type="text" placeholder="Title" nbInput />
      <input style="max-width: 450px" formControlName="description" type="text" placeholder="Description" nbInput />
      <nb-select style="max-width: 450px" (selectedChange)="onCategoryChange($event)" formControlName="category" *ngIf="categories" placeholder="Category">
        <nb-option *ngFor="let cat of categories" [value]="cat.id">{{
          cat.name
          }}</nb-option>
      </nb-select>

      <nb-select style="max-width: 450px" formControlName="subCategory" *ngIf="availableSubCats" [selected]="this.form.value.subCategory" placeholder="Sub Category">
        <nb-option value="">None</nb-option>
        <nb-option *ngFor="let subCat of availableSubCats" [value]="subCat">{{
          subCat
          }}</nb-option>
      </nb-select>

      <nb-select style="max-width: 450px" formControlName="type" *ngIf="filteredTypes" placeholder="Type">
        >
        <nb-option *ngFor="let type of filteredTypes" [value]="type">{{
          type
          }}</nb-option>
      </nb-select>

      <nb-tag-list (tagRemove)="onTagRemove($event)">
        <nb-tag *ngFor="let tag of tags" [text]="tag" removable></nb-tag>
        <input #tagInput nbTagInput type="text" (tagAdd)="onTagAdd($event)" (input)="onTagType()" [separatorKeys]=[13,32] placeholder="Hashtags" [nbAutocomplete]="autoTag">
        <nb-autocomplete #autoTag (selectedChange)="onSelectionChange($event)">
          <nb-option *ngFor="let tag of filteredOptions$ | async" [value]="tag">{{ tag }}</nb-option>
        </nb-autocomplete>
      </nb-tag-list>

      <input style="max-width: 450px" formControlName="url" type="text" placeholder="Url" nbInput />
      <button [disabled]="!form.valid" [nbSpinner]="isLoading" type="button" (click)="onLinkSubmit(form)" nbButton status="danger">
        Save
      </button>
    </form>
  </nb-card-body>
</nb-card>