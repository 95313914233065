import { Component, Input, OnInit } from '@angular/core';
import { DYPageLayoutType } from 'src/app/models/dy-page-layout';
import { Yearbook } from 'src/app/models/yearbook';
import { Article } from '../../../models/article';

@Component({
  selector: 'app-article-visualization',
  templateUrl: './article-visualization.component.html',
  styleUrls: ['./article-visualization.component.scss'],
})
export class ArticleVisualizationComponent implements OnInit {
  @Input() yearbook: Yearbook;
  @Input() article: Article;

  pageLayoutType = DYPageLayoutType;

  constructor() {}

  ngOnInit(): void {
    if (!this.yearbook) {
      throw Error('Yearbook must not be null');
    }
    if (!this.article) {
      throw Error('Article must not be null');
    }
  }
}
