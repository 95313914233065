import { Injectable } from '@angular/core';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AxiosNestService {
  currentUserToken$: Observable<String | null>;
  private axiosInstance: AxiosInstance = axios.create({
    baseURL: `https://europe-west3-${environment.firebaseConfig.projectId}.cloudfunctions.net/api/`,
  });

  constructor(private afAuth: AngularFireAuth) {
    this.currentUserToken$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return user.getIdToken();
        } else {
          return of(null);
        }
      }),
    );
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        // Do something before request is sent
        const userToken = await this.currentUserToken$.pipe(take(1)).toPromise();
        config.headers = { ...config.headers, Authorization: `Bearer ${userToken}` };
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );
  }

  get = this.axiosInstance.get;
  post = this.axiosInstance.post;
  put = this.axiosInstance.put;
  delete = this.axiosInstance.delete;

  postObservable(url: string, data: any, config?: AxiosRequestConfig | undefined): Observable<any> {
    return Observable.create((observer: any) => {
      this.axiosInstance
        .post(url, data, config)
        .then((response) => {
          observer.next(response.data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
}
