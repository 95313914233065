import { BaseResource, BaseSerializer } from '../services/base.service';
import { ArticleData } from './article-data';
import { DYPageLayoutType } from './dy-page-layout';

interface IArticle {
  title: string;
  order: number;
  pageLayout?: DYPageLayoutType;
  targetTags?: string[];
}

export class Article extends BaseResource {
  title: string;
  order: number;
  pageLayout: DYPageLayoutType;
  targetTags?: string[];
  content: ArticleData[];

  constructor(obj: IArticle) {
    super(obj);
    this.title = obj.title ?? 'Title';
    this.order = obj.order ?? 100;
    this.pageLayout = obj.pageLayout ?? DYPageLayoutType.basic;
    this.targetTags = obj.targetTags ?? [];
    this.content = [];
  }
}

export class ArticleSerializer extends BaseSerializer<Article> {
  fromJson(json: any): Article {
    return new Article(json);
  }

  toJson(resource: Article): any {
    return {
      title: resource.title,
      order: resource.order,
      pageLayout: resource.pageLayout,
      targetTags: resource.targetTags,
    };
  }
}
