import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbMenuService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

const TUMLA_APPS = 'Tumla Apps';
const TUMLA_PROFILE = 'Tumla profile';
const YEARBOOK_PROFILE = 'Yearbook profile';
const YEARBOOKS = 'Yearbooks';
const LOGOUT = 'Logout';

@Component({
  selector: 'app-header-left',
  template: `<ng-content></ng-content>`,
})
export class HeaderLeftComponent {}

@Component({
  selector: 'app-header-right',
  template: `<ng-content></ng-content>`,
})
export class HeaderRightComponent {}

@Component({
  selector: 'app-header-center',
  template: `<ng-content></ng-content>`,
})
export class HeaderCenterComponent {}

@Component({
  selector: 'app-header-sub-title',
  template: `<ng-content></ng-content>`,
})
export class HeaderSubTitleComponent {}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: User;
  user$: Observable<User>;
  term: string;

  menuItems = <any>[];

  @Input() backButton: () => Promise<boolean>;
  @Input() saveButton: () => Promise<void>;
  @Input() profileUser: Observable<User>;
  @Input() title: string;
  @Input() showSearchBar = false;
  @Input() showSignOut = false;
  @Input() rightPadding = false;
  @Input() showRight = true
  @Input() showLeft = true

  @Output() searched = new EventEmitter<string>();

  constructor(
    private authService: AuthService,
    private nbMenuService: NbMenuService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.user = this.authService.user as User;
    this.user$ = this.authService.user$ as Observable<User>;
    this.buildProfileItems();
  }

  ngOnInit(): void {
    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe((title) => {
        switch (title) {
          case TUMLA_APPS:
            this.goToTumlaApps();
            break;
          case TUMLA_PROFILE:
            this.goToTumlaProfile();
            break;
          case YEARBOOK_PROFILE:
            this.profileButton(this.user);
            break;
          case YEARBOOKS:
            this.goToYearbooks();
            break;
          case LOGOUT:
            this.signOut();
            break;
        }
      });
  }

  buildProfileItems(): void {
    this.menuItems.push({ title: TUMLA_APPS });
    const linksRoute = window.location.pathname.startsWith('/links');
    const profileRoute = window.location.pathname.startsWith('/profile');
    const oauth2 = window.location.pathname.startsWith('/oauth2');
    if (!(linksRoute || profileRoute || oauth2)) {
      this.menuItems.push({ title: TUMLA_PROFILE });
      const component = this.route.component;
      if (component && !component.toString().includes('YearbookEmptyComponent')) {
        this.menuItems.push({ title: YEARBOOK_PROFILE });
        this.menuItems.push({ title: YEARBOOKS });
      }
    }
    this.menuItems.push({ title: LOGOUT });
  }

  goToTumlaApps(): Promise<boolean> {
    return this.router.navigate(['apps']);
  }

  goToTumlaProfile(): Promise<boolean> {
    return this.router.navigate(['tumla-profile']);
  }

  goToYearbooks(): Promise<boolean> {
    return this.router.navigate(['yearbooks']);
  }

  async profileButton(user: User): Promise<void> {
    const params = await this.route.paramMap.pipe(take(1)).toPromise();
    const id = params.get('id');
    if (id) {
      this.router.navigate(['profile', user.id], {
        relativeTo: this.route,
        state: { user },
      });
    }
  }

  search($event: any): void {
    this.searched.emit($event);
  }

  signOut(): void {
    this.authService.signOut();
  }
}
