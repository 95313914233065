import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, Validators } from '@angular/forms';
import { NbTagComponent, NbTagInputAddEvent, NbToastrService } from '@nebular/theme';
import { ArticleData, IArticleDataImage } from '../../../models/article-data';
import { YearbooksService } from '../../../services/yearbook.service';
import * as firebase from 'firebase/app';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TinyMCEComponent } from '../tiny-mce/tiny-mce.component';

@Component({
  selector: 'app-article-data',
  templateUrl: './article-data.component.html',
  styleUrls: ['./article-data.component.scss'],
})
export class ArticleDataComponent implements AfterViewInit, AfterContentChecked {
  @ViewChild('mceDescription') mceDescription: TinyMCEComponent;
  @Input() articleData?: ArticleData;
  tags: Set<string> = new Set();
  articleDataBehavior: BehaviorSubject<ArticleData | null> = new BehaviorSubject<ArticleData | null>(null);
  sub: Subscription;
  isLoading = false;

  form = this.fb.group({
    title: ['', Validators.required],
    description: [''],
    tags: [, Validators.required],
  });

  uploadFolder: string;

  constructor(
    private yearbooksService: YearbooksService,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef,
    private db: AngularFirestore,
    private toastrService: NbToastrService,
  ) {}

  ngAfterViewInit(): void {
    this.tags = new Set(this.articleData?.tags);
    this.articleDataBehavior.next(this.articleData ?? null);
    this.mceDescription.editor.setContent(this.articleData?.description ?? '');
    this.form.patchValue({ ...(this.articleData ?? {}), tags: this.tags }, { onlySelf: false });
    this.cdr.detectChanges();

    this.uploadFolder = `DY_yearbooks/${this.yearbooksService.resource.id}/articlesData/${
      this.articleData?.id ?? 'invalid-id'
    }`;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    if (value) {
      this.tags.add(value);
    }
    input.nativeElement.value = '';
    this.form.value.tags = this.tags;
  }

  onTagRemove(tagToRemove: NbTagComponent): void {
    this.tags.delete(tagToRemove.text);
    this.form.value.tags = this.tags;
  }

  handleReorder(articleDataImages: IArticleDataImage[]): void {
    (this.articleDataBehavior.value as ArticleData).images = articleDataImages;
    if (!articleDataImages.some((img) => !img.downloadURL)) {
      this.saveImages();
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    const articleData = this.articleDataBehavior.value as ArticleData;
    moveItemInArray(articleData.images, event.previousIndex, event.currentIndex);
    this.saveImages();
  }

  saveImages(): void {
    const articleData = this.articleDataBehavior.value as ArticleData;
    this.yearbooksService.saveArticleData(articleData);
  }

  async delete(image: IArticleDataImage): Promise<void> {
    const paramDir = this.uploadFolder;
    const filename = image.filename;
    const path = `${paramDir}/${filename}`;
    await this.storage.ref(path).delete().toPromise();
    await this.db
      .doc(paramDir)
      .set({ images: { [image.filename]: firebase.default.firestore.FieldValue.delete() } }, { merge: true });
    this.toastrService.default('The image was removed from the storage', 'Deleted!');
  }

  async saveChanges(): Promise<void> {
    try {
      this.form.patchValue({
        description: this.mceDescription.editor.getContent(),
      });

      this.yearbooksService.saveArticleData(
        new ArticleData({ ...this.articleDataBehavior.value, ...this.form.value, tags: [...this.tags] }),
      );
      this.toastrService.success('Article data updated successfully', 'Updated!');
    } catch (e: any) {
      console.error(e);
      this.toastrService.danger(e.toString(), 'Error');
    }
  }

  async deleteArticleData(): Promise<void> {
    try {
      this.isLoading = true;
      const articleData = this.articleDataBehavior.value as ArticleData;
      await this.yearbooksService.deleteArticleData(articleData);
      this.toastrService.success('Article data deleted successfully', 'Deleted!');
    } catch (e: any) {
      console.error(e);
      this.toastrService.danger(e.toString(), 'Error');
    } finally {
      this.isLoading = false;
    }
  }
}
