import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { WallService } from 'src/app/services/wall.service';

@Component({
  selector: 'app-like-wall',
  templateUrl: './like-wall.component.html',
  styleUrls: ['./like-wall.component.scss']
})
export class LikeWallComponent implements OnInit {
  @Input() collectionName: string;
  @Input() collectionId: string;

  authUser: User | null;

  likedUserIds: string[];
  didILike: boolean;
  
  isProcessing = false;

  constructor(
    private wallService: WallService,
    private auth: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.auth.user$.subscribe((user) => {
      this.authUser = user;
    })

    this.wallService.getLikes(this.collectionName, this.collectionId).subscribe((likedUserIds) => {
      this.likedUserIds = likedUserIds;
      if (this.authUser)
        this.didILike = likedUserIds.some((likeUserId) => likeUserId === this.authUser!.id);
    });
  }

  async toggleLike(): Promise<void> {
    if (this.authUser) {
      this.isProcessing = true;

      // If I liked then filter out my user id, if not then add my user id to it
      if (this.didILike) {
        this.likedUserIds = this.likedUserIds.filter((likeUserId) => likeUserId !== this.authUser!.id);
      } else {
        this.likedUserIds.push(this.authUser.id);
      }

      await this.wallService.upsertLikes(this.collectionName, this.collectionId, this.likedUserIds);

      this.isProcessing = false;
    }
  }
}
