<div *ngIf="yearbook$ | async as yearbook">
	<nb-card *ngIf="!isEditing">
		<nb-card-header>
			Yearbook Details
			<button nbButton ghost>
				<nb-icon icon="edit-outline" status="primary" (click)="toggleEdit()"></nb-icon>
			</button>
		</nb-card-header>
		<nb-card-body class="row">
			<div class="col-sm-12 col-md-3">
				<app-yearbook-photo-uploader></app-yearbook-photo-uploader>
			</div>
			<div class="col-sm-12 col-md-9">
				<h6>ID</h6>
				<p>{{yearbook.id}}</p>
				<h6>Locked</h6>
				<p>{{yearbook.isFinished}}</p>
				<h6>Name</h6>
				<p>{{yearbook.name}}</p>
				<h6>Due Date</h6>
				<p>{{yearbook.dueDate}}</p>
			</div>
		</nb-card-body>
	</nb-card>
	
	<nb-card *ngIf="isEditing">
		<nb-card-header>
			Yearbook Details
			<div>
				<button nbButton ghost>
					<nb-icon icon="close-outline" status="basic" (click)="toggleEdit()"></nb-icon>
				</button>
				<button nbButton ghost [disabled]="form.invalid">
					<nb-icon icon="checkmark-outline" status="success" (click)="onSubmit()"></nb-icon>
				</button>
			</div>
		</nb-card-header>
		<nb-card-body class="row">
			<div class="col-sm-12 col-md-3">
				<app-yearbook-photo-uploader></app-yearbook-photo-uploader>
			</div>
			<div class="col-sm-12 col-md-9">
				<form [formGroup]="form">
					<h6>ID</h6>
					<p>{{yearbook.id}}</p>
					<div class="form-group row">
						<label class="col-12 label" for="inputTitle">Title:</label>
						<div class="col-12">
							<input formControlName="name" type="text" nbInput fullWidth id="inputTitle" placeholder="Awesome Yearbook">
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 label" for="inputDueDate">Due Date:</label>
						<div class="col-12">
							<input formControlName="dueDate" nbInput fullWidth id="inputDueDate" placeholder="dd/mm" [nbDatepicker]="picker">
							<nb-datepicker [min]="min" #picker></nb-datepicker>
						</div>
					</div>
				</form>
			</div>
		</nb-card-body>
		<nb-card-footer>
			<button nbButton status="info" (click)="openLockYearbookDialog()" [disabled]="isSubmitted || yearbook.isFinished">Lock yearbook</button>
			<button nbButton status="danger" [disabled]="isSubmitted">Delete yearbook</button>
		</nb-card-footer>
	</nb-card>
</div>