import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() name?: string;
  @Input() value$: BehaviorSubject<string>;
  @Input() options: {
    value: string,
    key: string,
  }[];
  @Input() placeholder?: string;
  @Input() mandatory?: boolean = false;

  initialValue: string = '';

  constructor() {
    if (!this.value$) {
      this.value$ = new BehaviorSubject<string>('');
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value$) {
      const value$ = changes.value$.currentValue;
      if (value$) {
        this.initialValue = value$.value;
      }
    }
  }

  onValueChange(event: string): void {
    this.value$.next(event);
  }
  
}
