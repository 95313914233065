import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Link, LinkType } from '../models/link';
import { LinkCategory } from '../models/link-category';
import { LinkService } from './link.service';

@Injectable({
  providedIn: 'root',
})
export class LinkStoreService {
  links$: BehaviorSubject<Link[]> = new BehaviorSubject<Link[]>([]);
  categories$: BehaviorSubject<LinkCategory[]> = new BehaviorSubject<LinkCategory[]>([]);
  countCats$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  availableTypes$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  hashtags$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public get links() {
    return this.links$.value;
  }

  public get categories() {
    return this.categories$.value;
  }

  public get countCats() {
    return [ this.links.length, ...this.countCats$.value ];
  }

  public get availableTypes() {
    return this.availableTypes$.value;
  }

  public get hashtags() {
    return this.hashtags$.value;
  }

  constructor(private linkService: LinkService) {
    // Links
    this.linkService.getLinks().subscribe((links) => this.links$.next(links));

    const rawCategoriesWithAdmins$: Observable<LinkCategory[]> = this.linkService.getLinkCategories().pipe(
      switchMap((categories) => {
        const adminObservables: Observable<any>[] = categories.map((category) =>
          this.linkService.getChatGroupCategoryAdmins({ chatGroupCategoryId: category.id }),
        );
        return forkJoin(adminObservables).pipe(
          map((adminsArrays) => {
            const cats = [ ...categories ];
            for (const [ i, category ] of cats.entries()) {
              category.notEmptySubCats = [];
              category.admins = adminsArrays[i];
              category.empty = this.countCats$.value[i] > 0 ? false : true;
            }
            return cats;
          }),
        );
      }),
    );

    // Categories
    combineLatest([ rawCategoriesWithAdmins$, this.links$ ])
      .pipe(
        map(([ cats, links ]) =>
          cats.filter((cat: any) => links.filter((link) => link.categories.includes(cat.id)).length > 0),
        ),
      )
      .subscribe((categories) => {
        this.categories$.next(categories);
      });

    // availableTypes
    this.links$.subscribe((links) => {
      const availableTypes: LinkType[] = [];

      links.forEach((link) => {
        if (!availableTypes.includes(link.type)) {
          availableTypes.push(link.type);
        }
      });

      this.availableTypes$.next(availableTypes);
    });

    // Hashtags
    this.links$.subscribe((links) => {
      let hashtags: string[] = [];

      links.forEach((link) => {
        link.tags.map((tag) => {
          if (!hashtags.includes(tag) && tag.length > 0 && !tag.includes(' ')) {
            hashtags.push(tag);
          }
        });
      });
      hashtags.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));

      this.hashtags$.next(hashtags);
    });

    this.categories$.subscribe((categories) => {
      const counts: number[] = [];
      categories.forEach((cat: any) => {
        const catFilter = this.links.filter((link) => link.categories.includes(cat.id));
        counts.push(catFilter && catFilter.length);
      });
      this.countCats$.next(counts);
    });
  }

  upsertLink(params: object = {}): Observable<Link> {
    return this.linkService.upsertLink(params).pipe(
      tap((response: Link) => {
        const currentLink = this.links.find((link) => link.id === response.id);
        if (currentLink) {
          const currentLinkIndex = this.links.indexOf(currentLink);
          this.links$.next([
            ...this.links.slice(0, currentLinkIndex),
            new Link(response as any),
            ...this.links.slice(currentLinkIndex + 1),
          ]);
        } else {
          this.links$.next([ ...this.links, new Link(response as any) ]);
        }
      }),
    );
  }

  async updateSubCategory(subCategories: string[], catId: string): Promise<void> {
    await this.linkService.updateSubCategory(subCategories, catId);
    const currentCategory = this.categories.find((cat) => cat.id === catId);
    const currentCategoryIndex = this.categories.findIndex((cat) => cat.id === catId);
    if (currentCategory && currentCategoryIndex >= 0) {
      currentCategory.subCategories = subCategories;
      this.categories$.next([
        ...this.categories.slice(0, currentCategoryIndex),
        Object.assign({}, currentCategory),
        ...this.categories.slice(currentCategoryIndex + 1),
      ]);
    }
  }
}
