import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AppsRouterPageComponent } from './@theme/components/apps-router-page/apps-router-page.component';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: 'apps',
    canActivate: [AuthGuard],
    component: AppsRouterPageComponent,
  },
  {
    path: 'developer',
    loadChildren: () => import('./developer/developer.module').then((m) => m.DeveloperModule),
  },
  {
    path: 'links',
    loadChildren: () => import('./links/links.module').then((m) => m.LinksModule),
  },
  {
    path: 'yearbooks',
    loadChildren: () => import('./yearbooks/yearbooks.module').then((m) => m.YearbooksModule),
  },
  {
    path: 'tumla-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'groups',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'oauth2',
    canActivate: [AuthGuard],
    loadChildren: () => import('./oauth2/oauth2.module').then((m) => m.OAuth2Module),
  },
  {
    path: 'form-builder',
    canActivate: [AuthGuard],
    loadChildren: () => import('./form-builder/form-builder.module').then((m) => m.FormBuilderModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.NgxAuthModule),
  },
  {
    path: 'tg-guests',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tg-guests/tg-guests.module').then((m) => m.TgGuestsModule),
  },
  {
    path: 'trips',
    canActivate: [AuthGuard],
    loadChildren: () => import('./trips/trips.module').then((m) => m.TripsModule),
  },
  {
    path: 'birthday',
    loadChildren: () => import('./birthday/birthday.module').then((m) => m.BirthdayModule),
  },
  {
    path: 'connect-email',
    canActivate: [AuthGuard],
    loadChildren: () => import('./connect-email/connect-email.module').then((m) => m.ConnectEmailModule),
  },
  {
    path: 'future-letter',
    canActivate: [AuthGuard],
    loadChildren: () => import('./future-letter/future-letter.module').then((m) => m.FutureLetterModule),
  },
  {
    path: '',
    loadChildren: () => import('./user-persona/user-persona.module').then((m) => m.UserPersonaModule),
  },
  // { path: '', redirectTo: 'auth/login', pathMatch: 'full' }, // Note: moved to user-persona-routing.module.ts
  { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
