import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: [ './file-manager.component.scss' ],
})
export class FileManagerComponent {
  @Input() canUpload: boolean;
  @Input() paramName: string;
  @Input() paramDir: string;
  @Input() transparent: boolean;
  @Input() rounded: boolean;
  @Output() finished: EventEmitter<boolean> = new EventEmitter();

  isHovering: boolean;

  files: File[] = [];

  constructor(private toastrService: NbToastrService) {}

  toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  onDrop(event: any): void {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i) as File);
    }
  }

  onFinish(file: File): void {
    const index = this.files.findIndex((f) => f === file);
    this.files = [ ...this.files.slice(0, index), ...this.files.slice(index + 1) ];
    this.finished.emit(true);
    this.toastrService.success('Image uploaded successfully', 'Uploaded!');
  }
}
