<div class="header-left">
  <div *ngIf="showLeft">
    <button nbButton ghost *ngIf="backButton" (click)="backButton()">
      <nb-icon icon="arrow-back-outline"></nb-icon>
    </button>
    <div *ngIf="!backButton" class="user-image">
      <a *ngIf="user$ | async as us">
        <nb-user
          [name]="us.name"
          [picture]="us.photo.thumbUrl || 'assets/profile-placeholder.svg'"
          onlyPicture
          [nbContextMenu]="menuItems"
          nbContextMenuTag="user-context-menu"
        >
        </nb-user>
      </a>
    </div>
    <ng-content select="app-header-left"></ng-content>
  </div>
</div>
<div class="header-center">
  <div>
    <app-profile-title [profileUser]="profileUser" [title]="title"></app-profile-title>
    <ng-content select="app-header-sub-title"></ng-content>
  </div>
  <input
    *ngIf="showSearchBar"
    type="text"
    [(ngModel)]="term"
    (ngModelChange)="search($event)"
    nbInput
    fullWidth
    placeholder="Search"
  />
  <ng-content select="app-header-center"></ng-content>
</div>
<div class="header-right">
  <div *ngIf="showRight">
    <button nbButton *ngIf="saveButton" (click)="saveButton()">Save</button>
    <button nbButton ghost *ngIf="showSignOut" status="danger" (click)="signOut()">
      <nb-icon icon="log-out-outline"></nb-icon>
    </button>
    <div *ngIf="rightPadding"></div>
    <ng-content select="app-header-right"></ng-content>
  </div>
</div>
