<div class="root">
    <div class="name" *ngIf="name">{{name}}</div>
    <nb-button-group status="primary">
        <button nbButton (click)="fileInput.click()">
            <span>Choose image</span>
            <input #fileInput type="file" accept="image/jpeg" (change)="onFileChange($event)" style="display:none;" />
        </button>
        <button *ngIf="filePreview" nbButton (click)="removeFile()">
            <span>{{filePreview.filename}}</span>
            <nb-icon icon="close-circle-outline"></nb-icon>
        </button>
    </nb-button-group>  
    <img *ngIf="filePreview" class="image-preview" [src]="filePreview.src" alt="Preview Upload Image">
    <img *ngIf="!filePreview && initialValue" class="image-preview" [src]="initialValue" alt="Preview Initial Image">
</div>