<div class="wrapper">
  <img *ngIf="previewUrl" [src]="previewUrl" class="thumbnail" [class.rounded]="rounded" />

  <div *ngIf="percentage | async as pct" class="percentage">
    <progress [value]="pct" max="100" class="percentage"></progress>
  </div>

  <div *ngIf="snapshot | async as snap" class="buttons">
  
    <button nbButton class="small-circular-button" (click)="task.pause()" [disabled]="!isActive(snap)">
      <nb-icon icon="pause-circle-outline" [status]="isActive(snap) ? 'primary' : 'basic'"></nb-icon>
    </button>
    <button nbButton class="small-circular-button" (click)="task.cancel()" [disabled]="!isActive(snap)">
      <nb-icon icon="stop-circle-outline" [status]="isActive(snap) ? 'primary' : 'basic'"></nb-icon>
    </button>
    <button nbButton class="small-circular-button" (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">
      <nb-icon icon="play-circle-outline" [status]="isActive(snap) ? 'primary' : 'basic'"></nb-icon>
    </button>
    <!-- <button class="small-circular-button" (click)="delete()" [disabled]="!downloadURL">Delete</button> -->
  </div>
</div>