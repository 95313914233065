import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Yearbook } from 'src/app/models/yearbook';
import { YearbooksService } from 'src/app/services/yearbook.service';

@Component({
  selector: 'app-yearbook-photo-uploader',
  templateUrl: './yearbook-photo-uploader.component.html',
  styleUrls: [ './yearbook-photo-uploader.component.scss' ],
})
export class YearbookPhotoUploaderComponent {
  yearbook$: Observable<Yearbook>;
  uploadFolder: string;
  filename = 'coverImage.jpg';

  constructor(private yearbooksService: YearbooksService) {
    this.yearbook$ = this.yearbooksService.resource$ as Observable<Yearbook>;
    this.uploadFolder = `DY_yearbooks/${this.yearbooksService.resource.id}`;
  }
}
