import * as moment from 'moment';
import { BaseResource, BaseSerializer } from '../services/base.service';

export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  photo: {
    imageUrl: string;
    thumbUrl: string;
    originalUrl: string;
    cropSettings?: {
      cropOriginPoint: string;
      cropWidth: number;
      cropHeight: number;
      cropMargin: number;
    };
  };
  dyFavorites: { [key: string]: boolean };
  userGroupsStatus: any;
  class_student_permission: any;
  class_connector_interest: boolean;
  birthday: any;
  gender: string;
  location: string;
  otherLocation: string;
  profession: string;
  phoneNumber: string;
  linkedin: string;
  instagram: string;
  hobbies: string[];
  other: string;
  campus: string;
  schoolId: string;
  superUser: boolean;
  telegramId?: number | undefined;
  isBirthdayAnnouncementDisabled?: boolean;
  connectEmailEnabledUserGroupIds?: string[];
  birthdayHistory?: {
    newValue: string,
    updatedAt: firebase.default.firestore.Timestamp,
  }[] | undefined;
}

export class User extends BaseResource {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  photo: {
    imageUrl: string;
    thumbUrl: string;
    originalUrl: string;
    cropSettings?: {
      cropOriginPoint: string;
      cropWidth: number;
      cropHeight: number;
      cropMargin: number;
    };
  };
  favorites: string[];
  userGroupsStatus: any;
  classStudentPermission: any;
  classConnectorInterest: boolean;
  birthday: any;
  gender: string;
  location: string;
  otherLocation: string;
  profession: string;
  phoneNumber: string;
  linkedin: string;
  instagram: string;
  hobbies: string[];
  other: string;
  campus: string;
  schoolId: string;
  superUser: boolean;
  telegramId?: number;
  isBirthdayAnnouncementDisabled: boolean;
  connectEmailEnabledUserGroupIds: string[];
  birthdayHistory: {
    newValue: string,
    updatedAt: Date,
  }[];

  get approvedUserGroupIds () {
    const approvedUserGroupIds: string[] = [];
    if (this.userGroupsStatus) {
      for (const userGroupId in this.userGroupsStatus) {
        if (this.userGroupsStatus[userGroupId] === 'approved') {
          approvedUserGroupIds.push(userGroupId);
        }
      }
    }
    return approvedUserGroupIds;
  }

  get fullName() {
    return `${this.firstName ?? ''} ${this.lastName ? ' ' + this.lastName : ''}`;
  }

  get birthdayObj(): {
    day: number,
    month: number,
  } | undefined {
    if (this.birthday) {
      const splittedValue = this.birthday.match(/.{1,2}/g);
      if (splittedValue) {
        return {
          day: splittedValue[0],
          month: splittedValue[1]
        }
      }
    }
    return undefined;
  }

  get birthdayThisYear(): moment.Moment | undefined {
    if (!this.birthdayObj)
      return undefined;

    return moment().date(this.birthdayObj.day).month(this.birthdayObj.month - 1);  //month starts with 0
  }

  get birthdayLastTime(): moment.Moment | undefined {
    if (!this.birthdayObj || !this.birthdayThisYear)
      return undefined;

    return moment().isBefore(this.birthdayThisYear) ? this.birthdayThisYear.clone().subtract(1, 'year') : this.birthdayThisYear.clone()
  }

  get birthdayUpcoming(): moment.Moment | undefined {
    if (!this.birthdayObj || !this.birthdayLastTime)
      return undefined;

    return this.birthdayLastTime.clone().add(1, 'year');
  }

  constructor(obj: IUser) {
    super(obj);
    this.firstName = obj.firstName || '';
    this.lastName = obj.lastName || '';
    this.name = `${this.firstName} ${this.lastName}`;
    this.email = obj.email;
    this.classStudentPermission = obj.class_student_permission || {};
    this.userGroupsStatus = obj.userGroupsStatus || {};
    this.photo =
      obj.photo != null
        ? obj.photo
        : {
            imageUrl: '',
            thumbUrl: '',
            originalUrl: '',
          };
    this.favorites = (obj.dyFavorites && Object.keys(obj.dyFavorites)) || [];
    this.classConnectorInterest = obj.class_connector_interest || false;
    this.birthday = obj.birthday || null;
    this.gender = obj.gender;
    this.location = obj.location;
    this.otherLocation = obj.otherLocation;
    this.profession = obj.profession;
    this.phoneNumber = obj.phoneNumber;
    this.linkedin = obj.linkedin;
    this.instagram = obj.instagram;
    this.hobbies = obj.hobbies;
    this.other = obj.other;
    this.campus = obj.campus;
    this.schoolId = obj.schoolId;
    this.superUser = obj.superUser;
    this.telegramId = obj.telegramId || undefined;
    this.isBirthdayAnnouncementDisabled = obj.isBirthdayAnnouncementDisabled || false;
    this.connectEmailEnabledUserGroupIds = obj.connectEmailEnabledUserGroupIds || [];
    this.birthdayHistory = obj.birthdayHistory?.map((birthdayHistoryItem) => {
      return {
        newValue: birthdayHistoryItem.newValue,
        updatedAt: this.timestampToDate(birthdayHistoryItem.updatedAt)
      };
    }) ?? [];
  }
}

export class UserSerializer extends BaseSerializer<User> {
  fromJson(json: any): User {
    return new User(json);
  }

  toJson(resource: User): any {
    return {
      id: resource.id,
      email: resource.email,
      gender: resource.gender,
      location: resource.location,
      otherLocation: resource.otherLocation,
      profession: resource.profession,
      phoneNumber: resource.phoneNumber,
      linkedin: resource.linkedin,
      instagram: resource.instagram,
      hobbies: resource.hobbies,
      other: resource.other,
      campus: resource.campus,
      createdAt: resource.createdAt,
      updatedAt: resource.updatedAt,
      firstName: resource.firstName,
      lastName: resource.lastName,
      photo: resource.photo,
      class_student_permission: resource.classStudentPermission,
      userGroupsStatus: resource.userGroupsStatus,
      dyFavorites: resource.favorites.reduce((acc: any, cur) => {
        acc[cur] = true;
        return acc;
      }, {}),
      class_connector_interest: resource.classConnectorInterest,
      birthday: resource.birthday,
      schoolId: resource.schoolId,
      superUser: resource.superUser,
      telegramId: resource.telegramId,
      isBirthdayAnnouncementDisabled: resource.isBirthdayAnnouncementDisabled,
      connectEmailEnabledUserGroupIds: resource.connectEmailEnabledUserGroupIds,
    } as IUser;
  }
}
