<div class="page-box">
	<div class="content-box">
		<h2 class="title">{{articleData?.title}}</h2>
		<div class="description" [innerHTML]="articleData?.description"></div>
		<div class="external-content-box">
			<div *ngIf="externalData$ | async as externalData">

				<!-- Display in half column -->
				<div *ngIf="externalData.length  % 2 == 0" class="row">
					<div *ngFor="let data of externalData;" class="col-md-6 col-sm-12">
						<div class="vertical-profile">
							<img [src]="data.photoUrl">
							<div class="vertical-content">
								<div class="vertical-padding"></div>
								<div class="vertical-padding">
									<p class="title">{{data.title}}</p>
									<p class="subtitle">{{data.subtitle}}</p>
									<div class="underline"></div>
								</div>
							</div>
						</div>
						<div class="bio" [innerHTML]="data.description"></div>
					</div>
				</div>

				<!-- Display 1 big and others in half column -->
				<div *ngIf="externalData.length % 2 == 1" class="row">
					<div *ngFor="let data of externalData; let i = index;" [ngClass]="i==0 ? 'col-12' : 'col-md-6 col-sm-12'">

						<!-- Big profile -->
						<div *ngIf="i==0" class="big-profile">
							<div class="horizontal-profile">
								<img [src]="data.photoUrl">
								<div class="vertical-content">
									<div class="vertical-padding"></div>
									<div class="vertical-padding">
										<p class="title">{{data.title}}</p>
										<p class="subtitle">{{data.subtitle}}</p>
										<div class="underline"></div>
									</div>
								</div>
							</div>
							<div class="bio" [innerHTML]="data.description"></div>
						</div>

						<!-- Half colum stacked profiles -->
						<div *ngIf="i!=0">
							<div class="vertical-profile">
								<img [src]="data.photoUrl">
								<div class="vertical-content">
									<div class="vertical-padding"></div>
									<div class="vertical-padding">
										<p class="title">{{data.title}}</p>
										<p class="subtitle">{{data.subtitle}}</p>
										<div class="underline"></div>
									</div>
								</div>
							</div>
							<div class="bio" [innerHTML]="data.description"></div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>