import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { IUserGroup } from 'src/app/models/user-group';
import { Yearbook } from 'src/app/models/yearbook';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/user.service';
import { YearbooksService } from 'src/app/services/yearbook.service';
import { CustomValidators } from 'src/app/validators/validators';

@Component({
  selector: 'app-yearbook-create-dialog',
  templateUrl: './yearbook-create-dialog.component.html',
  styleUrls: [ './yearbook-create-dialog.component.scss' ],
})
export class YearbookCreateDialogComponent {
  userGroups$: Observable<IUserGroup[]>;
  min: Date = new Date();
  isLoading = false;

  form = this.fb.group({
    name: [ '', Validators.required ],
    dueDate: [ '', [ Validators.required, CustomValidators.validateAfterToday ] ],
    userGroupId: [ '', Validators.required ],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private usersService: UsersService,
    private toastrService: NbToastrService,
    private yearbooksService: YearbooksService,
    protected ref: NbDialogRef<YearbookCreateDialogComponent>,
  ) {
    this.userGroups$ = this.authService.user$.pipe(
      take(1),
      switchMap((user) => this.usersService.getApprovedUserGroups((user && user.id) || '')),
    );
  }

  async onSubmit(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.form.valid) {
        const yearbook = new Yearbook({ ...this.form.value });
        await this.yearbooksService.update(yearbook);
        this.toastrService.success('Your yearbook was successfully created!', 'Created!');
        this.ref.close();
      }
    } catch (e) {
      this.toastrService.danger(e.toString(), 'Error');
    } finally {
      this.isLoading = false;
    }
  }
}
