import { Component } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { NbToastrService, NbDialogRef, NbTagInputAddEvent, NbTagComponent } from '@nebular/theme';
import { YearbooksService } from 'src/app/services/yearbook.service';
import { ArticleData } from '../../../models/article-data';

@Component({
  selector: 'app-article-data-create-dialog',
  templateUrl: './article-data-create-dialog.component.html',
  styleUrls: ['./article-data-create-dialog.component.scss'],
})
export class ArticleDataCreateDialogComponent {
  form = this.fb.group({
    title: ['', Validators.required],
    description: [''],
    tags: [, Validators.required],
  });

  tags: Set<string> = new Set();

  constructor(
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private yearbooksService: YearbooksService,
    protected ref: NbDialogRef<ArticleDataCreateDialogComponent>,
  ) {}

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    if (value) {
      this.tags.add(value);
    }
    this.form.patchValue({ tags: [...this.tags] });
    input.nativeElement.value = '';
  }

  onTagRemove(tagToRemove: NbTagComponent): void {
    this.tags.delete(tagToRemove.text);
    this.form.patchValue({ tags: [...this.tags] });
  }

  async onSubmit(): Promise<void> {
    try {
      if (this.form.valid) {
        const article = new ArticleData({ ...this.form.value });
        await this.yearbooksService.createArticleData(article);
        this.toastrService.success('Your article data was successfully created!', 'Created!');
        this.ref.close();
      }
    } catch (e: any) {
      this.toastrService.danger(e.toString(), 'Error');
    }
  }
}
