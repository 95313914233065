import { regexUrl } from './../../models/link';
import { NbDialogRef, NbToastrService, NbTagComponent, NbTrigger, NbTagInputAddEvent } from '@nebular/theme';
import { LinkService } from './../../services/link.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, ChangeDetectorRef, ViewChild, ElementRef, OnInit, AfterContentChecked } from '@angular/core';
import { LinkStoreService } from 'src/app/services/link-store.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-add-link-dialog',
  templateUrl: './add-link-dialog.component.html',
  styleUrls: ['./add-link-dialog.component.scss'],
})
export class AddLinkDialogComponent implements OnInit, AfterContentChecked {
  @Input() trigger: NbTrigger;
  @ViewChild('typeInput') inputType: ElementRef;
  @ViewChild('subCatInput') inputSubCat: ElementRef;
  @ViewChild('tagInput') tagInput: ElementRef;
  categories: any[];
  types: string[];
  hashtags: string[];
  isLoading: boolean;
  form: FormGroup;
  filteredTypes: string[] = [];
  availableSubCats: string[];
  filteredSubCats: string[];
  selectedCategory: any[];

  hashtagAutocomplete$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  filteredOptions$: Observable<string[]>;

  constructor(
    private linkService: LinkService,
    private linkStoreService: LinkStoreService,
    private changeDetector: ChangeDetectorRef,
    protected dialogRef: NbDialogRef<any>,
    private toastrService: NbToastrService,
  ) {
    this.form = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      type: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required, Validators.pattern(regexUrl)]),
      category: new FormControl('', [Validators.required]),
      subCategory: new FormControl(''),
    });

    this.categories = this.linkStoreService.categories;
    this.types = this.linkStoreService.availableTypes;
    this.hashtags = this.linkStoreService.hashtags;
    this.filteredTypes = this.types;
  }

  ngOnInit() {
    // Init autocomplete
    this.getAutocomplete();
    this.filteredOptions$ = of(this.hashtagAutocomplete$.value);
  }

  onTypeChange() {
    const inputValue = this.inputType.nativeElement.value.toLowerCase();
    this.filteredTypes = this.types.filter((type) => type.toLowerCase().indexOf(inputValue) >= 0);
  }

  onCategoryChange(event: string) {
    this.selectedCategory = this.categories.filter((cat) => cat.id === event);
    this.form.value.subCategory = '';
    this.availableSubCats = this.selectedCategory[0].subCategories || [];
  }

  tags: string[] = [];

  onTagRemove(tagToRemove: NbTagComponent): void {
    this.tags = this.tags.filter((tag) => {
      return tag !== tagToRemove.text;
    });
    this.form.value.tags = this.tags;
    this.getAutocomplete();
  }

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    // Lowercase, trim and remove leading "#" characters
    const inputValue = value.toLowerCase().trim().replace(/^#+/, '');

    if (inputValue) {
      if (inputValue.includes(' ')) {
        this.toastrService.warning("Tag can't have spaces", 'Invalid tag');
        return;
      }

      if (this.tags.includes(inputValue)) {
        this.toastrService.warning('Tag already exists', 'Duplicated tag');
        return;
      }

      this.tags.push(inputValue);
      this.form.value.tags = this.tags;
      input.nativeElement.value = '';
      this.hashtagAutocomplete$.next(
        this.hashtags.filter((tag) => tag.toLowerCase().indexOf(inputValue) === 0 && !this.tags.includes(tag)),
      );
    }
  }

  onSelectionChange(value: string) {
    this.tags.push(value);
    this.form.value.tags = this.tags;
    this.tagInput.nativeElement.value = '';
    this.getAutocomplete();
  }

  async onLinkSubmit(form: FormGroup) {
    this.isLoading = true;
    const newSubCat = form.value.subCategory;
    form.value.subCategory = { [this.selectedCategory[0].id]: newSubCat };
    form.value.subCategories = form.value.subCategory;
    delete form.value.subCategory;
    this.form.value.tags = this.tags;
    try {
      await this.linkService.upsertLink(form.value).toPromise();
      this.toastrService.success('Data sent!', 'Success');
    } catch (err: any) {
      this.toastrService.danger(err.message, 'Error');
    } finally {
      this.isLoading = false;
      this.changeDetector.detectChanges();
      this.dialogRef.close();
      location.reload();
    }
  }

  close(): void {
    let values: any[] = Object.values(this.form.value);
    let someFull = false;
    for (let i = 0; i < values.length; i++) {
      if (values[i].length > 0) {
        someFull = true;
        if (confirm('Are you sure? You will not keep your changes.') === true) {
          this.dialogRef.close(AddLinkDialogComponent);
          return;
        } else {
          return;
        }
      }
    }
    if (!someFull) {
      this.dialogRef.close(AddLinkDialogComponent);
    }
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.hashtagAutocomplete$.value.filter((optionValue) => optionValue.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  getAutocomplete() {
    this.hashtagAutocomplete$.next(this.hashtags.filter((tag) => !this.tags.includes(tag)));
  }

  onTagType() {
    this.filteredOptions$ = this.getFilteredOptions(this.tagInput.nativeElement.value);
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }
}
